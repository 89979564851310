import { createReducer, on } from '@ngrx/store';
import { filtersActions } from '../actions/filters.actions';
import { FilterModel } from '../models';

export interface FiltersState {
    filters: FilterModel[] | null;
    total: number | null;
    loading: boolean | null;
    loaded: boolean | null;
}

export const initialState: FiltersState = {
    filters: null,
    total: null,
    loading: null,
    loaded: null,
};

export const filtersReducer = createReducer(
    initialState,
    on(
        filtersActions.getFilters,
        (state): FiltersState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        filtersActions.getFiltersSuccess,
        (state, action): FiltersState => ({
            ...state,
            filters: action.payload,
            loaded: true,
            loading: false,
        }),
    ),
    on(
        filtersActions.getFiltersError,
        (state): FiltersState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),
    on(
        filtersActions.clear,
        (state): FiltersState => ({
            ...state,
            ...initialState,
        }),
    ),
);
