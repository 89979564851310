import { createReducer, on } from '@ngrx/store';
import { transferPointsPresetActions } from '../actions/transfer-points-preset.actions';
import { TransferPointModel } from '../models';

export interface TransferPointsPresetState {
    transferPoints: TransferPointModel[] | null;
    total: number | null;
    loading: boolean | null;
    loaded: boolean | null;
}

export const initialState: TransferPointsPresetState = {
    transferPoints: null,
    total: null,
    loading: null,
    loaded: null,
};

export const transferPointsPresetReducer = createReducer(
    initialState,
    on(
        transferPointsPresetActions.getTransferPoints,
        (state): TransferPointsPresetState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        transferPointsPresetActions.getTransferPointsSuccess,
        (state, action): TransferPointsPresetState => ({
            ...state,
            transferPoints: action.payload,
            loaded: true,
            loading: false,
        }),
    ),
    on(
        transferPointsPresetActions.getTransferPointsError,
        (state): TransferPointsPresetState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),
    on(
        transferPointsPresetActions.clear,
        (state): TransferPointsPresetState => ({
            ...state,
            ...initialState,
        }),
    ),
);
