import { createReducer, on } from '@ngrx/store';
import { taskPointsActions } from '../actions/task-points.actions';
import { TaskPointModel } from '../models';

export interface TaskPointsState {
    taskPoints: TaskPointModel[] | null;
    total: number | null;
    loading: boolean | null;
    loaded: boolean | null;
}

export const initialState: TaskPointsState = {
    taskPoints: null,
    total: null,
    loading: null,
    loaded: null,
};

export const taskPointsReducer = createReducer(
    initialState,
    on(
        taskPointsActions.getTaskPoints,
        (state): TaskPointsState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        taskPointsActions.getTaskPointsSuccess,
        (state, action): TaskPointsState => ({
            ...state,
            taskPoints: action.payload,
            loaded: true,
            loading: false,
        }),
    ),
    on(
        taskPointsActions.getTaskPointsError,
        (state): TaskPointsState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),
    on(
        taskPointsActions.clear,
        (state): TaskPointsState => ({
            ...state,
            ...initialState,
        }),
    ),
);
