import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { TransferPointModel, TransferPointValue, TransferPointDto } from '../models';
import { PointError } from '../../models/point.error';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getTransferPoint(id: string): Observable<TransferPointModel> {
        return this.httpClient
            .skipErrorHandler()
            .get<ApiResponse<TransferPointDto>>(`transfer-point/${id}`)
            .pipe(
                map((response) => new TransferPointModel(response.data)),
                catchError((response: HttpErrorResponse) => {
                    throw new PointError(response);
                }),
            );
    }

    addTransferPoint(value: TransferPointValue): Observable<null> {
        return this.httpClient
            .skipErrorHandler()
            .post<null>('transfer-point', new TransferPointValue(value))
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    throw new PointError(response);
                }),
            );
    }

    updateTransferPoint(id: string, value: TransferPointValue): Observable<null> {
        return this.httpClient
            .skipErrorHandler()
            .put<null>(`transfer-point/${id}`, new TransferPointValue(value))
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    throw new PointError(response);
                }),
            );
    }

    deleteTransferPoint(id: string): Observable<null> {
        return this.httpClient
            .skipErrorHandler()
            .delete<null>(`transfer-point/${id}`)
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    throw new PointError(response);
                }),
            );
    }
}
