import { BOOKING_TYPES, WAYPOINT_TYPES } from '../../enums';
import { WaypointModel, WaypointTransferModel } from '../../models';
import { RouteDraft, Route } from '../models';

export const PassingWaypoints = (route: Route | RouteDraft, pointId: string): WaypointTransferModel[] => {
    const waypointIndex = route.waypoints.findIndex((waypoint) => waypoint.pointId === pointId);
    const waypointsSlice = route.waypoints.slice(0, waypointIndex);
    const currentTransfersOut = route.waypoints.find((waypoint) => waypoint.pointId === pointId)?.transfersOut || [];

    const waypointPassings =
        waypointsSlice.reduce(
            (prev, waypoint) => {
                switch (waypoint.type) {
                    case WAYPOINT_TYPES.PASSENGER_START:
                        prev.push({ bookingId: waypoint.pointId, bookingType: BOOKING_TYPES.PASSENGER });
                        break;
                    case WAYPOINT_TYPES.PARCEL_START:
                        prev.push({ bookingId: waypoint.pointId, bookingType: BOOKING_TYPES.PARCEL });
                        break;
                    case WAYPOINT_TYPES.PASSENGER_STOP:
                    case WAYPOINT_TYPES.PARCEL_STOP: {
                        const bookingIndex = prev.findIndex((transfer) => transfer.bookingId === waypoint.pointId);
                        if (bookingIndex !== -1) {
                            prev.splice(bookingIndex, 1);
                        }
                        break;
                    }
                    case WAYPOINT_TYPES.TRANSFER:
                        waypoint.transfersIn?.forEach((transferIn) => {
                            prev.push(transferIn);
                        });
                        waypoint.transfersOut?.forEach((transferIn) => {
                            const transferIndex = prev.findIndex((transfer) => transfer.bookingId === transferIn.bookingId);
                            if (transferIndex !== -1) {
                                prev.splice(transferIndex, 1);
                            }
                        });
                        break;
                }
                return prev;
            },
            <WaypointTransferModel[]>[],
        ) || [];

    currentTransfersOut.forEach((currentTransferOut) => {
        const transferIndex = waypointPassings.findIndex((transfer) => transfer.bookingId === currentTransferOut.bookingId);
        if (transferIndex !== -1) {
            waypointPassings.splice(transferIndex, 1);
        }
    });

    return waypointPassings;
};

export const PassingRouteWaypoints = (waypoints: WaypointModel[], waypointPointId: string): WaypointTransferModel[] => {
    const waypointIndex = waypoints.findIndex((waypoint) => waypoint.pointId === waypointPointId);
    const waypointsSlice = waypoints.slice(0, waypointIndex);
    const currentTransfersOut = waypoints.find((waypoint) => waypoint.pointId === waypointPointId)?.transfersOut || [];

    const waypointPassings =
        waypointsSlice.reduce(
            (prev, waypoint) => {
                switch (waypoint.type) {
                    case WAYPOINT_TYPES.PASSENGER_START:
                        prev.push({ bookingId: waypoint.pointId, bookingType: BOOKING_TYPES.PASSENGER });
                        break;
                    case WAYPOINT_TYPES.PARCEL_START:
                        prev.push({ bookingId: waypoint.pointId, bookingType: BOOKING_TYPES.PARCEL });
                        break;
                    case WAYPOINT_TYPES.PASSENGER_STOP:
                    case WAYPOINT_TYPES.PARCEL_STOP: {
                        const bookingIndex = prev.findIndex((transfer) => transfer.bookingId === waypoint.pointId);
                        if (bookingIndex !== -1) {
                            prev.splice(bookingIndex, 1);
                        }
                        break;
                    }
                    case WAYPOINT_TYPES.TRANSFER:
                        waypoint.transfersIn?.forEach((transferIn) => {
                            prev.push(transferIn);
                        });
                        waypoint.transfersOut?.forEach((transferIn) => {
                            const transferIndex = prev.findIndex((transfer) => transfer.bookingId === transferIn.bookingId);
                            if (transferIndex !== -1) {
                                prev.splice(transferIndex, 1);
                            }
                        });
                        break;
                }
                return prev;
            },
            <WaypointTransferModel[]>[],
        ) || [];

    currentTransfersOut.forEach((currentTransferOut) => {
        const transferIndex = waypointPassings.findIndex((transfer) => transfer.bookingId === currentTransferOut.bookingId);
        if (transferIndex !== -1) {
            waypointPassings.splice(transferIndex, 1);
        }
    });

    return waypointPassings;
};
