import { GeoAddressModel } from '../../models';
import { ParcelDto } from './parcel.dto';
import { BOOKING_TYPES, PLANNING_TYPE } from '../../enums';
import { Time } from '@app/shared/classes';
import { PersonalDetailsModel } from '../../../models';

export interface BookingParcelModel {
    item: ParcelModel;
    kind: typeof BOOKING_TYPES.PARCEL;
}

export class ParcelModel {
    id: string;
    date: Date;
    planningType: PLANNING_TYPE;
    uniqueNumber: number;
    didntGo: boolean;
    paymentsResultStatus: string;
    teamNumber: string | null;
    rideResultStatus: string;
    isResigned: boolean;
    sender: PersonalDetailsModel;
    senderAddress: GeoAddressModel;
    recipient: PersonalDetailsModel;
    recipientAddress: GeoAddressModel;
    partnerId: string | null;
    filter: {
        id: string;
        color: string;
        name: string;
    } | null;

    constructor(data: ParcelDto) {
        this.id = data.id;
        this.date = Time.stringToDate(data.date);
        this.paymentsResultStatus = data.paymentsResultStatus;
        this.planningType = data.planningType;
        this.rideResultStatus = data.rideResultStatus;
        this.teamNumber = data.teamNumber;
        this.uniqueNumber = data.uniqueNumber;
        this.didntGo = data.didntGo;
        this.partnerId = data.partnerId;
        this.filter =
            data.filterId !== null
                ? {
                      id: data.filterId,
                      name: data.filterName,
                      color: data.filterColor,
                  }
                : null;
        this.isResigned = data.rideResultStatus === 'resigned';
        this.sender = new PersonalDetailsModel({
            firstname: data.senderFirstname,
            surname: data.senderSurname,
            email: data.senderEmail,
            phone: data.senderPhone,
        });
        this.senderAddress = new GeoAddressModel({
            id: data.senderAddressId,
            city: data.senderAddressCity,
            street: data.senderAddressStreet,
            houseNumber: data.senderAddressHouseNumber,
            flatNumber: '', // data.senderAddressFlatNumber
            postalCode: data.senderAddressPostalCode,
            voivodeship: '', // data.senderAddressVoivodeshop
            country: data.senderAddressCountry,
            geoPosition: {
                latitude: data.senderAddressLatitude !== undefined && data.senderAddressLatitude !== null ? parseFloat(data.senderAddressLatitude) : null,
                longitude: data.senderAddressLongitude !== undefined && data.senderAddressLongitude !== null ? parseFloat(data.senderAddressLongitude) : null,
            },
        });
        this.recipient = new PersonalDetailsModel({
            firstname: data.recipientFirstname,
            surname: data.recipientSurname,
            email: data.recipientEmail,
            phone: data.recipientPhone,
        });
        this.recipientAddress = new GeoAddressModel({
            id: data.recipientAddressId,
            city: data.recipientAddressCity,
            street: data.recipientAddressStreet,
            houseNumber: data.recipientAddressHouseNumber,
            flatNumber: '', // data.recipientAddressFlatNumber
            postalCode: data.recipientAddressPostalCode,
            voivodeship: '', // data.recipientAddressVoivodeshop
            country: data.recipientAddressCountry,
            geoPosition: {
                latitude:
                    data.recipientAddressLatitude !== undefined && data.recipientAddressLatitude !== null ? parseFloat(data.recipientAddressLatitude) : null,
                longitude:
                    data.recipientAddressLongitude !== undefined && data.recipientAddressLatitude !== null ? parseFloat(data.recipientAddressLongitude) : null,
            },
        });
    }
}
