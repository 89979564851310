import { WaypointModel, WaypointTransferModel } from '../../models';
import { RouteDraft, Route } from '../models';
import { PassingWaypoints } from './passing-transfers.util';
import { PendingWaypoints } from './pending-transfers.util';

type POSITION = 'in' | 'out' | 'passing' | 'pending';
type UPDATE = 'move-in' | 'move-out' | 'both';

export class WaypointsTransitsUtils {
    private readonly route: Route | RouteDraft;
    private readonly routes: (Route | RouteDraft)[];
    private readonly waypoint: WaypointModel;
    private waypoints: WaypointModel[];

    constructor(routes: (Route | RouteDraft)[], routeId: string | null, waypointPointId: string) {
        this.routes = routes;
        this.route = <Route | RouteDraft>this.routes.find((route) => route.id === routeId);
        this.waypoint = <WaypointModel>this.route.waypoints.find((waypoint) => waypoint.pointId === waypointPointId);
        this.waypoints = [...this.route.waypoints];
    }

    get() {
        return this.waypoints;
    }

    moveToggle(pointId: string) {
        const item = this.getPointPositionWithTransfer(pointId);
        if (item?.position === 'out' || item?.position === 'passing') {
            this.waypoints = this.updateTransfersOut(item.transfer);
        }
        if (item?.position === 'in' || item?.position === 'pending') {
            this.waypoints = this.updateTransfersIn(item.transfer);
        }
    }

    moveOut(pointId: string) {
        const item = this.getPointPositionWithTransfer(pointId);
        if (item?.position === 'out' || item?.position === 'passing') {
            this.waypoints = this.updateTransfersOut(item.transfer, 'move-out');
        }
        if (item?.position === 'in' || item?.position === 'pending') {
            this.waypoints = this.updateTransfersIn(item.transfer, 'move-out');
        }
    }

    moveIn(pointId: string) {
        const item = this.getPointPositionWithTransfer(pointId);
        if (item?.position === 'out' || item?.position === 'passing') {
            this.waypoints = this.updateTransfersOut(item.transfer, 'move-in');
        }
        if (item?.position === 'in' || item?.position === 'pending') {
            this.waypoints = this.updateTransfersIn(item.transfer, 'move-in');
        }
    }

    private getPointPositionWithTransfer(pointId: string): { position: POSITION; transfer: WaypointTransferModel } | null {
        const transfersOut = this.waypoint.transfersOut || [];
        const transfersOutIndex = transfersOut.findIndex((transfer) => transfer.bookingId === pointId);
        if (transfersOutIndex !== -1) {
            return {
                position: 'out',
                transfer: transfersOut[transfersOutIndex],
            };
        }

        const transfersIn = this.waypoint.transfersIn || [];
        const transfersInIndex = transfersIn.findIndex((transfer) => transfer.bookingId === pointId);
        if (transfersInIndex !== -1) {
            return {
                position: 'in',
                transfer: transfersIn[transfersInIndex],
            };
        }

        const transfersPassings = PassingWaypoints(this.route, this.waypoint.pointId);
        const transfersPassingsIndex = transfersPassings.findIndex((transfer) => transfer.bookingId === pointId);
        if (transfersPassingsIndex !== -1) {
            return {
                position: 'passing',
                transfer: transfersPassings[transfersPassingsIndex],
            };
        }

        const transfersPending = PendingWaypoints(this.routes, this.route.id, this.waypoint.pointId);
        const transfersPendingsIndex = transfersPending.findIndex((transfer) => transfer.bookingId === pointId);
        if (transfersPendingsIndex !== -1) {
            return {
                position: 'pending',
                transfer: transfersPending[transfersPendingsIndex],
            };
        }

        return null;
    }

    private updateTransfersIn(transfer: WaypointTransferModel, type: UPDATE = 'both') {
        const waypoint = { ...this.waypoint };
        const transfersIn = [...(waypoint.transfersIn || [])];
        const waypointIndex = this.waypoints.findIndex((waypoint) => waypoint.pointId === this.waypoint.pointId);
        const pointIndex = transfersIn.findIndex((transferIn) => transferIn.bookingId === transfer.bookingId);

        if (pointIndex === -1) {
            if (type === 'both' || type === 'move-in') {
                transfersIn.push({ ...transfer });
            }
        } else {
            if (type === 'both' || type === 'move-out') {
                transfersIn.splice(pointIndex, 1);
            }
        }

        const waypoints = this.waypoints;

        waypoints.splice(waypointIndex, 1, {
            ...waypoint,
            transfersIn,
        });

        return waypoints;
    }

    private updateTransfersOut(transfer: WaypointTransferModel, type: UPDATE = 'both') {
        const waypoint = { ...this.waypoint };
        const transfersOut = [...(waypoint.transfersOut || [])];
        const waypointIndex = this.waypoints.findIndex((waypoint) => waypoint.pointId === this.waypoint.pointId);
        const pointIndex = transfersOut.findIndex((transferOut) => transferOut.bookingId === transfer.bookingId);

        if (pointIndex === -1) {
            if (type === 'both' || type === 'move-in') {
                transfersOut.push({ ...transfer });
            }
        } else {
            if (type === 'both' || type === 'move-out') {
                transfersOut.splice(pointIndex, 1);
            }
        }

        const waypoints = this.waypoints;

        waypoints.splice(waypointIndex, 1, {
            ...waypoint,
            transfersOut,
        });

        return waypoints;
    }
}
