import { Time } from '@app/shared/classes';
import { BOOKING_TYPES, WAYPOINT_TYPES } from '../../enums';
import { RouteAddValue, RouteAddWaypointValue, RouteUpdateValue, RouteUpdateWaypointValue } from './route.value';
import { WAYPOINT_TYPES_DTO } from '../../models';

export class RouteAddValueDto {
    date: string;
    planningType: number;
    vehicle: string;
    mainDriver: string;
    drivers: string[];
    waypoints: RouteAddWaypointValueDto[];
    tags: string[];
    constructor(params: RouteAddValue) {
        this.date = Time.dateToString(params.date);
        this.planningType = params.planningType;
        this.vehicle = params.vehicleId === null ? '' : params.vehicleId;
        this.mainDriver = params.driverId === null ? '' : params.driverId;
        this.drivers = params.additionalDriversIds;
        this.waypoints = params.waypoints.map((waypoint, index) => new RouteAddWaypointValueDto(waypoint, index));
        this.tags = params.tags.map((tag) => tag.code);
    }
}

export class RouteAddWaypointValueDto {
    type: WAYPOINT_TYPES_DTO;
    order: number;
    time: Date | null;
    bookingType?: BOOKING_TYPES;
    bookingId?: string;
    transferPointId?: string;
    taskId?: string;
    draftPointId?: string;
    transfersIn?: {
        bookingId: string;
        order: number;
        type: string;
    }[];

    transfersOut?: {
        bookingId: string;
        order: number;
        type: string;
    }[];

    constructor(params: RouteAddWaypointValue, index: number) {
        this.order = index;
        this.time = null;
        if (params.time) {
            const localDate = new Date(params.time);
            const localDateTimezoneShift = new Date(new Date(params.time).toLocaleString('en-US', { timeZone: 'Europe/Warsaw' }));

            const shift = localDate.getTime() - localDateTimezoneShift.getTime();

            this.time = new Date(localDate.getTime() + shift);
        }

        switch (params.type) {
            case WAYPOINT_TYPES.PASSENGER_START:
                this.type = WAYPOINT_TYPES_DTO.BOOKING_START;
                this.bookingId = params.pointId;
                this.bookingType = BOOKING_TYPES.PASSENGER;
                break;
            case WAYPOINT_TYPES.PASSENGER_STOP:
                this.type = WAYPOINT_TYPES_DTO.BOOKING_STOP;
                this.bookingId = params.pointId;
                this.bookingType = BOOKING_TYPES.PASSENGER;
                break;
            case WAYPOINT_TYPES.PARCEL_START:
                this.type = WAYPOINT_TYPES_DTO.BOOKING_START;
                this.bookingId = params.pointId;
                this.bookingType = BOOKING_TYPES.PARCEL;
                break;
            case WAYPOINT_TYPES.PARCEL_STOP:
                this.type = WAYPOINT_TYPES_DTO.BOOKING_STOP;
                this.bookingId = params.pointId;
                this.bookingType = BOOKING_TYPES.PARCEL;
                break;
            case WAYPOINT_TYPES.TRANSFER:
                this.type = WAYPOINT_TYPES_DTO.TRANSFER;
                this.transferPointId = params.pointId;
                this.transfersIn = params.transfersIn?.map((transfer, index) => ({
                    bookingId: transfer.bookingId,
                    order: index,
                    type: '1',
                }));
                this.transfersOut = params.transfersOut?.map((transfer, index) => ({
                    bookingId: transfer.bookingId,
                    order: index,
                    type: '1',
                }));
                break;
            case WAYPOINT_TYPES.TASK:
                this.type = WAYPOINT_TYPES_DTO.TASK;
                this.taskId = params.pointId;
                break;
            case WAYPOINT_TYPES.START:
                this.type = WAYPOINT_TYPES_DTO.START;
                this.taskId = params.pointId;
                break;
            case WAYPOINT_TYPES.DRAFT:
                this.type = WAYPOINT_TYPES_DTO.DRAFT;
                this.draftPointId = params.pointId;
                break;
            default:
                this.type = params.type;
        }
    }
}

export class RouteUpdateValueDto {
    date: string;
    planningType: number;
    vehicle: string;
    mainDriver: string;
    drivers: string[];
    controlTimestamp: string;
    waypoints: RouteUpdateWaypointValueDto[];
    tags: string[];
    hereMapsRoute?: unknown;
    constructor(params: RouteUpdateValue) {
        this.date = Time.dateToString(params.date);
        this.planningType = params.planningType;
        this.vehicle = params.vehicleId === null ? '' : params.vehicleId;
        this.mainDriver = params.driverId || '';
        this.drivers = params.additionalDriversIds;
        this.controlTimestamp = params.controlTimestamp.toString();
        this.waypoints = params.waypoints.map((waypoint, index) => new RouteUpdateWaypointValueDto(waypoint, index));
        this.tags = params.tags.map((tag) => tag.code);
        if (params.hereMapsRoute) {
            this.hereMapsRoute = params.hereMapsRoute;
        } else {
            delete this.hereMapsRoute;
        }
    }
}

export class RouteUpdateWaypointValueDto {
    id?: string;
    type: WAYPOINT_TYPES;
    order: number;
    time: Date | null;
    length?: number | null;
    bookingType?: BOOKING_TYPES;
    bookingId?: string;
    transferPointId?: string;
    taskId?: string;
    draftPointId?: string;
    transfersIn?: {
        bookingId: string;
        order: number;
        type: string;
    }[];

    transfersOut?: {
        bookingId: string;
        order: number;
        type: string;
    }[];

    constructor(params: RouteUpdateWaypointValue, index: number) {
        if (params.id) {
            this.id = params.id;
        }
        this.order = index;
        if (params.length !== null) {
            this.length = params.length;
        }
        this.time = null;
        if (params.time) {
            const localDate = new Date(params.time);
            const localDateTimezoneShift = new Date(new Date(params.time).toLocaleString('en-US', { timeZone: 'Europe/Warsaw' }));

            const shift = localDate.getTime() - localDateTimezoneShift.getTime();

            this.time = new Date(localDate.getTime() + shift);
        }
        switch (params.type) {
            case WAYPOINT_TYPES.PASSENGER_START:
                this.type = WAYPOINT_TYPES_DTO.BOOKING_START;
                this.bookingId = params.pointId;
                this.bookingType = BOOKING_TYPES.PASSENGER;
                break;
            case WAYPOINT_TYPES.PASSENGER_STOP:
                this.type = WAYPOINT_TYPES_DTO.BOOKING_STOP;
                this.bookingId = params.pointId;
                this.bookingType = BOOKING_TYPES.PASSENGER;
                break;
            case WAYPOINT_TYPES.PARCEL_START:
                this.type = WAYPOINT_TYPES_DTO.BOOKING_START;
                this.bookingId = params.pointId;
                this.bookingType = BOOKING_TYPES.PARCEL;
                break;
            case WAYPOINT_TYPES.PARCEL_STOP:
                this.type = WAYPOINT_TYPES_DTO.BOOKING_STOP;
                this.bookingId = params.pointId;
                this.bookingType = BOOKING_TYPES.PARCEL;
                break;
            case WAYPOINT_TYPES.TRANSFER:
                this.type = WAYPOINT_TYPES_DTO.TRANSFER;
                this.transferPointId = params.pointId;
                this.transfersIn = params.transfersIn?.map((transfer, index) => ({
                    bookingId: transfer.bookingId,
                    order: index,
                    type: '1',
                }));
                this.transfersOut = params.transfersOut?.map((transfer, index) => ({
                    bookingId: transfer.bookingId,
                    order: index,
                    type: '1',
                }));
                break;
            case WAYPOINT_TYPES.TASK:
                this.type = WAYPOINT_TYPES_DTO.TASK;
                this.taskId = params.pointId;
                break;
            case WAYPOINT_TYPES.START:
                this.type = WAYPOINT_TYPES_DTO.START;
                this.taskId = params.pointId;
                break;
            case WAYPOINT_TYPES.DRAFT:
                this.type = WAYPOINT_TYPES_DTO.DRAFT;
                this.draftPointId = params.pointId;
                break;
            default:
                this.type = params.type;
        }
    }
}
