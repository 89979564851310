import { createReducer, on } from '@ngrx/store';
import { processingActions } from '../actions/processing.actions';

export interface ProcessingState {
    processing: string[];
    date: Date | null;
}

export const initialState: ProcessingState = {
    processing: [],
    date: null,
};

export const processingReducer = createReducer(
    initialState,
    on(processingActions.add, (state, action): ProcessingState => {
        const processing = [...state.processing, action.key];
        return {
            ...state,
            processing,
            date: new Date(),
        };
    }),
    on(processingActions.del, (state, action): ProcessingState => {
        const index = state.processing.indexOf(action.key);
        const processing = [...state.processing];
        processing.splice(index, 1);
        return {
            ...state,
            processing,
            date: new Date(),
        };
    }),
    on(
        processingActions.clear,
        (): ProcessingState => ({
            ...initialState,
        }),
    ),
);
