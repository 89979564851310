import { createAction, props } from '@ngrx/store';
import { PartnerModel } from '../models';

enum PartnersActionTypes {
    GET_PARTNERS_ACTION = '[Planning - Partners] Get partners',
    GET_PARTNERS_SUCCESS_ACTION = '[Planning - Partners] Get partners success',
    GET_PARTNERS_ERROR_ACTION = '[Planning - Partners] Get partners error',
    CLEAR_ACTION = '[Planning - Partners] Clear partners',
}

const getPartnersAction = createAction(PartnersActionTypes.GET_PARTNERS_ACTION);
const getPartnersSuccessAction = createAction(PartnersActionTypes.GET_PARTNERS_SUCCESS_ACTION, props<{ payload: PartnerModel[] }>());
const getPartnersErrorAction = createAction(PartnersActionTypes.GET_PARTNERS_ERROR_ACTION);
const clearAction = createAction(PartnersActionTypes.CLEAR_ACTION);

export const partnersActions = {
    getPartnersAction,
    getPartnersSuccessAction,
    getPartnersErrorAction,
    clearAction,
};
