import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FilterState } from '../reducers/filter.reducer';
import { FILTER_KEY } from '../keys';

const selectState = createFeatureSelector<FilterState>(FILTER_KEY);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);
export const selectSaving = createSelector(selectState, (state) => state.saving);
export const selectSaved = createSelector(selectState, (state) => state.saved);
export const selectDeleting = createSelector(selectState, (state) => state.deleting);
export const selectDeleted = createSelector(selectState, (state) => state.deleted);
export const selectAttaching = createSelector(selectState, (state) => state.attaching);
export const selectAttached = createSelector(selectState, (state) => state.attached);
export const selectDetaching = createSelector(selectState, (state) => state.detaching);
export const selectDetached = createSelector(selectState, (state) => state.detached);
export const selectError = createSelector(selectState, (state) => state.error);
