import { PLANNING_TYPE, TASK_POINT_TYPES } from '../../enums';
import { GeoAddressModel } from '../../models';

export class TaskPointValue {
    planningType: PLANNING_TYPE;
    date: string;
    name: string;
    type: TASK_POINT_TYPES;
    address: GeoAddressModel;

    constructor(data: TaskPointValue) {
        const { latitude, longitude } = data.address.geoPosition;

        this.planningType = data.planningType;
        this.date = data.date;
        this.name = data.name;
        this.type = data.type;
        this.address = {
            ...data.address,
            geoPosition: {
                latitude: latitude ? parseFloat(latitude.toFixed(6)) : latitude,
                longitude: longitude ? parseFloat(longitude.toFixed(6)) : longitude,
                isPoint: false,
            },
        };
    }
}
