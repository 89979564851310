import { VehicleDto, RegistratioNumberDto } from './vehicle.dto';

export class Vehicle {
    id: string;
    name: string;
    active: boolean;
    sideNumber: string | null;
    brand: string | null;
    model: string | null;
    navigationType: 'Navifleet' | 'gbox' | null;
    navigationId: string | null;
    comments: string;
    registrationNumbers: RegistratioNumberModel[];
    passengersCount: number;

    constructor(data: VehicleDto) {
        this.id = data.id;
        this.name = data.name.trim();
        this.active = data.active;
        this.sideNumber = data.sideNumber || null;
        this.brand = data.brand || null;
        this.model = data.model || null;
        this.navigationType = data.navigationType || null;
        this.navigationId = data.navigationId || null;
        this.comments = data.comments || '';
        this.registrationNumbers = (data.registrationNumbers || []).map((registrationNumber) => new RegistratioNumberModel(registrationNumber));
        this.passengersCount = data.passengersCount;
    }
}

export class RegistratioNumberModel {
    active: boolean;
    registrationNumber: string;
    constructor(data: RegistratioNumberDto) {
        this.active = data.active;
        this.registrationNumber = data.registrationNumber.trim();
    }
}
