import { createReducer, on } from '@ngrx/store';
import { selectRoutesActions } from '../actions/select-routes.actions';

export interface SelectRoutesState {
    selectedIds: string[];
}

export const initialState: SelectRoutesState = {
    selectedIds: [],
};

export const selectRoutesReducer = createReducer(
    initialState,
    on(
        selectRoutesActions.selectIdsAction,
        (state, action): SelectRoutesState => ({
            ...state,
            selectedIds: [...new Set([...state.selectedIds, ...action.ids])],
        }),
    ),
    on(
        selectRoutesActions.unselectIdsAction,
        (state, action): SelectRoutesState => ({
            ...state,
            selectedIds: [...new Set([...Array.from(state.selectedIds).filter((id) => !action.ids.includes(id))])],
        }),
    ),
    on(
        selectRoutesActions.showRoutesAction,
        (state): SelectRoutesState => ({
            ...state,
        }),
    ),
    on(
        selectRoutesActions.hideRoutesAction,
        (state): SelectRoutesState => ({
            ...state,
        }),
    ),
    on(
        selectRoutesActions.clearAction,
        (state): SelectRoutesState => ({
            ...state,
            selectedIds: [],
        }),
    ),
);
