import { Injectable, inject } from '@angular/core';
import { filter, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectDrivers, selectLoaded, selectLoading, selectDriver } from '../selectors/drivers.selector';
import { driversActions } from '../actions/drivers.actions';
import { DriverModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class PlanningDriversFacade {
    private readonly store = inject(Store);

    drivers$ = this.store.select(selectDrivers).pipe(filter((data): data is DriverModel[] => data !== null));
    loaded$ = this.store.select(selectLoaded).pipe(filter((data): data is boolean => data !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((data): data is boolean => data !== null));
    driversSelectOptions$ = this.drivers$.pipe(
        map((drivers) =>
            drivers.map((driver) => ({
                label: `${driver.surname || '-'} ${driver.firstname || '-'}`.trim(),
                value: driver.id,
            })),
        ),
    );

    driver$(id: string) {
        return this.store.select(selectDriver(id)).pipe(filter((data): data is DriverModel => data !== null));
    }

    getDrivers() {
        this.store.dispatch(driversActions.getDrivers());
    }

    clear(): void {
        this.store.dispatch(driversActions.clear());
    }
}
