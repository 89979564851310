import { createReducer, on } from '@ngrx/store';
import { queryActions } from '../actions/query.actions';

export interface QueryState {
    date: string | null;
    type: number | null;
}

export const initialState: QueryState = {
    date: null,
    type: null,
};

export const queryReducer = createReducer(
    initialState,
    on(
        queryActions.set,
        (state, action): QueryState => ({
            ...state,
            date: action.query.date,
            type: action.query.type,
        }),
    ),
    on(
        queryActions.clear,
        (state): QueryState => ({
            ...state,
            ...initialState,
        }),
    ),
);
