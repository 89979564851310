import { GeoAddressModel } from '../../models';
import { PassengerDto } from './passenger.dto';
import { BOOKING_TYPES, PLANNING_TYPE } from '../../enums';
import { Time } from '@app/shared/classes';
import { PersonalDetailsModel } from '../../../models';

export interface BookingPassengerModel {
    item: PassengerModel;
    kind: typeof BOOKING_TYPES.PASSENGER;
}

export class PassengerModel {
    id: string;
    date: Date;
    planningType: PLANNING_TYPE;
    uniqueNumber: number;
    didntGo: boolean;
    orderId: string;
    paymentsResultStatus: string;
    teamNumber: string | null;
    rideResultStatus: string;
    driverNotes: string;
    internalNotes: string;
    personalDetails: PersonalDetailsModel;
    departureAddress: GeoAddressModel;
    destinationAddress: GeoAddressModel;
    isResigned: boolean;
    partnerId: string | null;
    filter: {
        id: string;
        color: string;
        name: string;
    } | null;

    constructor(data: PassengerDto) {
        this.id = data.id;
        this.date = Time.stringToDate(data.date);
        this.paymentsResultStatus = data.paymentsResultStatus;
        this.planningType = data.planningType;
        this.rideResultStatus = data.rideResultStatus;
        this.driverNotes = data.driverNotes;
        this.internalNotes = data.internalNotes;
        this.teamNumber = data.teamNumber;
        this.uniqueNumber = data.uniqueNumber;
        this.didntGo = data.didntGo;
        this.partnerId = data.partnerId;
        this.filter =
            data.filterId !== null
                ? {
                      id: data.filterId,
                      name: data.filterName,
                      color: data.filterColor,
                  }
                : null;
        this.isResigned = data.rideResultStatus === 'resigned';
        this.personalDetails = new PersonalDetailsModel({
            firstname: data.firstname,
            surname: data.surname,
            email: data.email,
            phone: data.phone,
        });
        this.departureAddress = new GeoAddressModel({
            id: data.departureAddressId,
            city: data.departureAddressCity,
            street: data.departureAddressStreet,
            houseNumber: data.departureAddressHouseNumber,
            flatNumber: '', // data.departureAddressFlatNumber
            postalCode: data.departureAddressPostalCode,
            voivodeship: '', // data.departureAddressVoivodeshop
            country: data.departureAddressCountry,
            geoPosition: {
                latitude:
                    data.departureAddressLatitude !== undefined || data.departureAddressLatitude !== null ? parseFloat(data.departureAddressLatitude) : null,
                longitude:
                    data.departureAddressLongitude !== undefined || data.departureAddressLongitude !== null ? parseFloat(data.departureAddressLongitude) : null,
            },
        });
        this.destinationAddress = new GeoAddressModel({
            id: data.destinationAddressId,
            city: data.destinationAddressCity,
            street: data.destinationAddressStreet,
            houseNumber: data.destinationAddressHouseNumber,
            flatNumber: '', // data.departureAddressFlatNumber
            postalCode: data.destinationAddressPostalCode,
            voivodeship: '', // data.departureAddressVoivodeshop
            country: data.destinationAddressCountry,
            geoPosition: {
                latitude:
                    data.destinationAddressLatitude !== undefined || data.destinationAddressLatitude !== null
                        ? parseFloat(data.destinationAddressLatitude)
                        : null,
                longitude:
                    data.destinationAddressLongitude !== undefined || data.destinationAddressLongitude !== null
                        ? parseFloat(data.destinationAddressLongitude)
                        : null,
            },
        });
        this.orderId = data.orderId;
    }
}
