import { HttpErrorResponse } from '@angular/common/http';
import { RouteErrorDto } from './route.error.dto';

export class RouteError {
    code: number;
    type: string | null;
    data: string | null;

    constructor(response: HttpErrorResponse) {
        const error: RouteErrorDto = response.error;

        this.code = response.status;
        this.type = error.exception.message === '' ? (this.code === 404 ? 'not-found' : null) : error.exception.message;
        this.data = error.exception.additionalMessage;
    }
}
