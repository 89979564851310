import { createReducer, on } from '@ngrx/store';
import { transferPointActions } from '../actions/transfer-point.actions';
import { TransferPointModel } from '../models';
import { PointError } from '../../models/point.error';

export interface TransferPointState {
    transferPoint: TransferPointModel | null;
    loading: boolean | null;
    loaded: boolean | null;
    saving: boolean | null;
    saved: boolean | null;
    deleting: boolean | null;
    deleted: boolean | null;
    error: PointError | null;
}

export const initialState: TransferPointState = {
    transferPoint: null,
    loading: null,
    loaded: null,
    saving: null,
    saved: null,
    deleting: null,
    deleted: null,
    error: null,
};

export const transferPointReducer = createReducer(
    initialState,
    on(
        transferPointActions.getTransferPoint,
        (state): TransferPointState => ({
            ...state,
            loading: true,
            error: null,
        }),
    ),
    on(
        transferPointActions.getTransferPointSuccess,
        (state, action): TransferPointState => ({
            ...state,
            loading: false,
            loaded: true,
            transferPoint: action.payload,
        }),
    ),
    on(
        transferPointActions.getTransferPointError,
        (state, action): TransferPointState => ({
            ...state,
            loading: false,
            loaded: false,
            error: action.payload,
        }),
    ),
    on(
        transferPointActions.addTransferPoint,
        (state): TransferPointState => ({
            ...state,
            saving: true,
            error: null,
        }),
    ),
    on(
        transferPointActions.addTransferPointSuccess,
        (state): TransferPointState => ({
            ...state,
            saving: false,
            saved: true,
        }),
    ),
    on(
        transferPointActions.addTransferPointError,
        (state, action): TransferPointState => ({
            ...state,
            saving: false,
            saved: false,
            error: action.payload,
        }),
    ),
    on(
        transferPointActions.updateTransferPoint,
        (state): TransferPointState => ({
            ...state,
            saving: true,
            error: null,
        }),
    ),
    on(
        transferPointActions.updateTransferPointSuccess,
        (state): TransferPointState => ({
            ...state,
            saving: false,
            saved: true,
        }),
    ),
    on(
        transferPointActions.updateTransferPointError,
        (state, action): TransferPointState => ({
            ...state,
            saving: false,
            saved: false,
            error: action.payload,
        }),
    ),
    on(
        transferPointActions.deleteTransferPoint,
        (state): TransferPointState => ({
            ...state,
            deleting: true,
            error: null,
        }),
    ),
    on(
        transferPointActions.deleteTransferPointSuccess,
        (state): TransferPointState => ({
            ...state,
            deleting: false,
            deleted: true,
        }),
    ),
    on(
        transferPointActions.deleteTransferPointError,
        (state, action): TransferPointState => ({
            ...state,
            deleting: false,
            deleted: false,
            error: action.payload,
        }),
    ),
    on(
        transferPointActions.clear,
        (state): TransferPointState => ({
            ...state,
            ...initialState,
        }),
    ),
);
