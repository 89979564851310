import { Injectable } from '@angular/core';
import { filter } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    selectBookings,
    selectBookingsUnassigned,
    selectBookingsResigned,
    selectBookingsResignedIds,
    selectBookingsDidntGo,
    selectBookingsDidntGoIds,
    selectPassengers,
    selectPassengersUnassigned,
    selectParcels,
    selectParcelsUnassigned,
    selectLoaded,
    selectLoading,
    selectPassengersByIds,
    selectPassengerById,
    selectParcelsByIds,
    selectParcelById,
    selectBooking,
    selectParcel,
    selectPassenger,
} from '../selectors/bookings.selector';
import { bookingsActions } from '../actions/bookings.actions';
import { ParcelModel, PassengerModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class PlanningBookingsFacade {
    bookings$ = this.store.select(selectBookings);
    bookingsUnassigned$ = this.store.select(selectBookingsUnassigned);
    bookingsResigned$ = this.store.select(selectBookingsResigned);
    bookingsResignedIds$ = this.store.select(selectBookingsResignedIds);
    bookingsDidntGo$ = this.store.select(selectBookingsDidntGo);
    bookingsDidntGoIds$ = this.store.select(selectBookingsDidntGoIds);

    passengers$ = this.store.select(selectPassengers);
    passengersUnassigned$ = this.store.select(selectPassengersUnassigned);

    parcels$ = this.store.select(selectParcels);
    parcelsUnassigned$ = this.store.select(selectParcelsUnassigned);

    loaded$ = this.store.select(selectLoaded).pipe(filter((data): data is boolean => data !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((data): data is boolean => data !== null));

    booking$(id: string) {
        return this.store.select(selectBooking(id)).pipe(filter((data): data is PassengerModel | ParcelModel => data !== null));
    }

    parcel$(id: string) {
        return this.store.select(selectParcel(id)).pipe(filter((data): data is ParcelModel => data !== null));
    }

    passenger$(id: string) {
        return this.store.select(selectPassenger(id)).pipe(filter((data): data is PassengerModel => data !== null));
    }

    constructor(private store: Store) {}

    clear(): void {
        this.store.dispatch(bookingsActions.clear());
    }

    passengersByIds$(ids: string[]) {
        return this.store.select(selectPassengersByIds(ids)).pipe(filter((data): data is PassengerModel[] => data !== null));
    }

    passengerById$(id: string) {
        return this.store.select(selectPassengerById(id)).pipe(filter((data): data is PassengerModel => data !== null));
    }

    parcelsByIds$(ids: string[]) {
        return this.store.select(selectParcelsByIds(ids)).pipe(filter((data): data is ParcelModel[] => data !== null));
    }

    parcelById$(id: string) {
        return this.store.select(selectParcelById(id)).pipe(filter((data): data is ParcelModel => data !== null));
    }
}
