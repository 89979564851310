export enum ROUTE_TRACE {
    START,
    STOP,
    IN,
    OUT,
}

// export interface RoutesTracesModel {
//     valid: RouteActionModel[][];
//     invalid: RouteActionModel[];
// }

// export interface RouteTracePairwiseModel {
//     valid: [RouteActionModel, RouteActionModel | null][][];
//     invalid: RouteActionModel[];
// }

export interface RouteTraceModel {
    routeId: string;
    type: ROUTE_TRACE;
    transferPointId: string | null;
    time: Date | null;
}

export interface RouteTracesModel {
    pointId: string;
    traces: [RouteTraceModel, RouteTraceModel | null][][];
}
