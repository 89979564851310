import { RouteNotificationDto } from './route-notification.dto';

export class RouteNotification {
    id: string;
    controlTimestamp: number;
    notificationsEnabled: boolean;
    constructor(data: RouteNotificationDto) {
        this.id = data.id;
        this.controlTimestamp = parseInt(data.controlTimestamp, 10);
        this.notificationsEnabled = data.notificationsEnabled;
    }
}
