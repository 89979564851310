import { Injectable, inject } from '@angular/core';
import { filter } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectTaskPoints, selectLoaded, selectLoading, selectTask, selectTaskPointsByIds } from '../selectors/task-points.selector';
import { taskPointsActions } from '../actions/task-points.actions';
import { TaskPointModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class TaskPointsFacade {
    private store = inject(Store);

    loaded$ = this.store.select(selectLoaded).pipe(filter((data): data is boolean => data !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((data): data is boolean => data !== null));

    taskPoints$ = this.store.select(selectTaskPoints).pipe(filter((data): data is TaskPointModel[] => data !== null));
    task$ = (id: string) => this.store.select(selectTask(id)).pipe(filter((data): data is TaskPointModel => data !== null));
    taskPointsByIds$ = (ids: string[]) => this.store.select(selectTaskPointsByIds(ids)).pipe(filter((data): data is TaskPointModel[] => data !== null));

    clear(): void {
        this.store.dispatch(taskPointsActions.clear());
    }
}
