import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TransferPointState } from '../reducers/transfer-point.reducer';
import { TRANSFER_POINT_KEY } from '../keys';

const selectState = createFeatureSelector<TransferPointState>(TRANSFER_POINT_KEY);
export const selectTransferPoint = createSelector(selectState, (state) => state.transferPoint);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);
export const selectSaving = createSelector(selectState, (state) => state.saving);
export const selectSaved = createSelector(selectState, (state) => state.saved);
export const selectDeleting = createSelector(selectState, (state) => state.deleting);
export const selectDeleted = createSelector(selectState, (state) => state.deleted);
export const selectError = createSelector(selectState, (state) => state.error);
