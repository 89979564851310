import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoutesState } from '../reducers/routes.reducer';
import { ROUTES_KEY } from '../keys';
import { RouteTag } from '../models';

const selectState = createFeatureSelector<RoutesState>(ROUTES_KEY);

export const selectRoutesByTag = (tag: RouteTag) =>
    createSelector(
        selectState,
        (state) =>
            state.routes?.filter((route) =>
                route.tags.length > 0 ? route.tags.map((tag) => tag.code).includes(tag.code) : route.autoTags.map((tag) => tag.code).includes(tag.code),
            ) ?? [],
    );

export const selectRoutesWithoutTag = createSelector(
    selectState,
    (state) => state.routes?.filter((route) => route.autoTags.length === 0 && route.tags.length === 0) || [],
);

export const selectRoutesByTransferPointTag = (pointId: string | null, tag: RouteTag) =>
    createSelector(
        selectState,
        (state) =>
            state.routes
                ?.filter((route) => route.waypoints.findIndex((waypoint) => waypoint.pointId === pointId) !== -1)
                .filter((route) =>
                    route.tags.length > 0 ? route.tags.map((tag) => tag.code).includes(tag.code) : route.autoTags.map((tag) => tag.code).includes(tag.code),
                ) ?? [],
    );

export const selectRoutesByTransferPointWithoutTag = (pointId: string | null) =>
    createSelector(
        selectState,
        (state) =>
            state.routes
                ?.filter((route) => route.waypoints.findIndex((waypoint) => waypoint.pointId === pointId) !== -1)
                .filter((route) => route.autoTags.length === 0 && route.tags.length === 0) ?? [],
    );

export const selectCustomTags = createSelector(selectState, (state) => {
    const tags = [...new Set(state.routes?.map((route) => route.tags).flat() || [])];
    const autoTagsSorted: RouteTag[] = [
        {
            name: 'Dowożący',
            code: 'Dowożący',
        },
        {
            name: 'Docelowy',
            code: 'Docelowy',
        },
        {
            name: 'Rozwożący',
            code: 'Rozwożący',
        },
    ];

    tags.forEach((current) => {
        if (
            autoTagsSorted.findIndex(
                (tag) => tag.code.toLocaleLowerCase().trim() !== '' && tag.code.toLocaleLowerCase().trim() === current.code.toLocaleLowerCase().trim(),
            ) === -1
        ) {
            autoTagsSorted.push(current);
        }
    });

    return autoTagsSorted;
});

export const selectTags = createSelector(selectState, (state) => {
    const autoTags =
        state.routes
            ?.filter((route) => route.tags.length === 0)
            .map((route) => route.autoTags)
            .flat() || [];
    const tags = [...new Set(state.routes?.map((route) => route.tags).flat() || [])];
    const autoTagsSorted: RouteTag[] = [];

    if (autoTags.map((tag) => tag.code).includes('Dowożący')) {
        autoTagsSorted.push({
            name: 'Dowożący',
            code: 'Dowożący',
        });
    }
    if (autoTags.map((tag) => tag.code).includes('Docelowy')) {
        autoTagsSorted.push({
            name: 'Docelowy',
            code: 'Docelowy',
        });
    }
    if (autoTags.map((tag) => tag.code).includes('Rozwożący')) {
        autoTagsSorted.push({
            name: 'Rozwożący',
            code: 'Rozwożący',
        });
    }

    tags.forEach((current) => {
        if (
            autoTagsSorted.findIndex(
                (tag) => tag.code.toLocaleLowerCase().trim() !== '' && tag.code.toLocaleLowerCase().trim() === current.code.toLocaleLowerCase().trim(),
            ) === -1
        ) {
            autoTagsSorted.push(current);
        }
    });

    return autoTagsSorted;
});

// select all tags
export const selectTransferPointTags = (pointId: string) =>
    createSelector(selectState, (state) => {
        const routes = state.routes?.filter((route) => route.waypoints.findIndex((waypoint) => waypoint.pointId === pointId) !== -1) ?? [];
        const autoTags =
            routes
                ?.filter((route) => route.tags.length === 0)
                .map((route) => route.autoTags)
                .flat() || [];
        const tags = [...new Set(routes?.map((route) => route.tags).flat() || [])];
        const autoTagsSorted: RouteTag[] = [];

        if (autoTags.map((tag) => tag.code).includes('Dowożący')) {
            autoTagsSorted.push({
                name: 'Dowożący',
                code: 'Dowożący',
            });
        }
        if (autoTags.map((tag) => tag.code).includes('Docelowy')) {
            autoTagsSorted.push({
                name: 'Docelowy',
                code: 'Docelowy',
            });
        }
        if (autoTags.map((tag) => tag.code).includes('Rozwożący')) {
            autoTagsSorted.push({
                name: 'Rozwożący',
                code: 'Rozwożący',
            });
        }

        tags.forEach((current) => {
            if (autoTagsSorted.findIndex((tag) => tag.code === current.code) === -1) {
                autoTagsSorted.push(current);
            }
        });

        return autoTagsSorted;
    });
