import { createAction, props } from '@ngrx/store';
import { GeoPositionModel } from '../../models';
import { RouteCalculationModel } from '../models';

enum RouteCalculationActionTypes {
    GET_CALCULATION = '[Planning - Route Calculation] Get Calculation',
    GET_CALCULATION_SUCCESS_ACTION = '[Planning - Route Calculation] Get Calculation Success',
    GET_CALCULATION_ERROR_ACTION = '[Planning - Route Calculation] Get Calculation Error',
    CLEAR_ACTION = '[Planning - Route Calculation] Clear',
}

const getRouteCalculationAction = createAction(RouteCalculationActionTypes.GET_CALCULATION, props<{ points: GeoPositionModel[] }>());
const getRouteCalculationSuccessAction = createAction(
    RouteCalculationActionTypes.GET_CALCULATION_SUCCESS_ACTION,
    props<{
        payload: {
            calculation: RouteCalculationModel;
            raw: unknown;
        };
    }>(),
);
const getRouteCalculationErrorAction = createAction(RouteCalculationActionTypes.GET_CALCULATION_ERROR_ACTION);
const clearAction = createAction(RouteCalculationActionTypes.CLEAR_ACTION);

export const routeCalculationActions = {
    getRouteCalculationAction,
    getRouteCalculationSuccessAction,
    getRouteCalculationErrorAction,
    clearAction,
};
