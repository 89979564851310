import { createReducer, on } from '@ngrx/store';
import { selectItemsActions } from '../actions/select-items.actions';

export interface SelectItemsState {
    idsBooking: string[];
    idsDraft: string[];
    idsTransfer: string[];
    idsTask: string[];
    isSelectingEnabled: boolean;
}

export const initialState: SelectItemsState = {
    idsBooking: [],
    idsDraft: [],
    idsTransfer: [],
    idsTask: [],
    isSelectingEnabled: false,
};

export const selectItemsReducer = createReducer(
    initialState,
    on(selectItemsActions.addIdsBookingAction, (state, action): SelectItemsState => {
        return {
            ...state,
            idsBooking: [...state.idsBooking, ...action.ids].filter((v, i, a) => a.indexOf(v) === i),
        };
    }),
    on(
        selectItemsActions.removeIdsBookingAction,
        (state, action): SelectItemsState => ({
            ...state,
            idsBooking: [...state.idsBooking.filter((id) => !action.ids.includes(id))],
        }),
    ),
    on(selectItemsActions.toggleIdsBookingAction, (state, action): SelectItemsState => {
        const ids = [...state.idsBooking];
        action.ids.forEach((actionId) => {
            if (ids.includes(actionId)) {
                ids.splice(ids.indexOf(actionId), 1);
            } else {
                ids.push(actionId);
            }
        });
        return {
            ...state,
            idsBooking: ids.filter((v, i, a) => a.indexOf(v) === i),
        };
    }),
    on(selectItemsActions.addIdsDraftAction, (state, action): SelectItemsState => {
        return {
            ...state,
            idsDraft: [...state.idsDraft, ...action.ids].filter((v, i, a) => a.indexOf(v) === i),
        };
    }),
    on(
        selectItemsActions.removeIdsDraftAction,
        (state, action): SelectItemsState => ({
            ...state,
            idsDraft: [...state.idsDraft.filter((id) => !action.ids.includes(id))],
        }),
    ),
    on(selectItemsActions.addIdsTransferAction, (state, action): SelectItemsState => {
        return {
            ...state,
            idsTransfer: [...state.idsTransfer, ...action.ids].filter((v, i, a) => a.indexOf(v) === i),
        };
    }),
    on(
        selectItemsActions.removeIdsTransferAction,
        (state, action): SelectItemsState => ({
            ...state,
            idsTransfer: [...state.idsTransfer.filter((id) => !action.ids.includes(id))],
        }),
    ),
    on(selectItemsActions.addIdsTaskAction, (state, action): SelectItemsState => {
        return {
            ...state,
            idsTask: [...state.idsTask, ...action.ids].filter((v, i, a) => a.indexOf(v) === i),
        };
    }),
    on(
        selectItemsActions.removeIdsTaskAction,
        (state, action): SelectItemsState => ({
            ...state,
            idsTask: [...state.idsTask.filter((id) => !action.ids.includes(id))],
        }),
    ),
    on(
        selectItemsActions.clearAction,
        (state): SelectItemsState => ({
            ...state,
            ...initialState,
        }),
    ),
);
