import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectProcessing } from '../selectors/processing.selector';
import { processingActions } from '../actions/processing.actions';

@Injectable({
    providedIn: 'root',
})
export class PlanningProcessingFacade {
    private readonly store = inject(Store);

    readonly processing$ = this.store.select(selectProcessing);

    add(key: string) {
        this.store.dispatch(processingActions.add({ key }));
    }

    del(key: string) {
        this.store.dispatch(processingActions.del({ key }));
    }
}
