import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoutesMonitorState } from '../reducers/routes-monitor.reducer';
import { ROUTES_MONITOR_KEY } from '../keys';
import { ROUTE_ERROR_TYPES } from '../enums/errors.enum';

const selectState = createFeatureSelector<RoutesMonitorState>(ROUTES_MONITOR_KEY);
export const selectErrors = createSelector(selectState, (state) => state.errorsDraft);

/**
 * Routes
 */
export const selectTransfersErrors = createSelector(selectState, (state) => {
    return state.errorsDraft.filter((error) => error.type === ROUTE_ERROR_TYPES.BOOKING_STILL_IN || error.type === ROUTE_ERROR_TYPES.BOOKING_NOT_OUT) || [];
});

export const selectTimeErrors = createSelector(selectState, (state) => {
    return state.errorsDraft.filter((error) => error.type === ROUTE_ERROR_TYPES.WAYPOINT_TIME || error.type === ROUTE_ERROR_TYPES.WAYPOINT_TIME_EMPTY) || [];
});

/**
 * Drivers
 * */
export const selectDriversErrors = createSelector(
    selectState,
    (state) =>
        state.errorsDraft.filter((error) => error.type === ROUTE_ERROR_TYPES.DRIVER_MULTIPLE_ROUTES_SAME_TAG || error.type === ROUTE_ERROR_TYPES.NO_DRIVER) ||
        [],
);

export const selectDriverErrors = (driverId: string) =>
    createSelector(selectState, (state) => {
        return state.errorsDraft.filter((error) => error.driverId === driverId && error.type === ROUTE_ERROR_TYPES.DRIVER_MULTIPLE_ROUTES_SAME_TAG) || [];
    });

export const selectDriverWarnings = (driverId: string) =>
    createSelector(selectState, (state) => {
        return state.errorsDraft.filter((error) => error.driverId === driverId && error.type === ROUTE_ERROR_TYPES.DRIVER_MULTIPLE_ROUTES_NOT_SAME_TAG) || [];
    });

export const selectRouteDriverErrors = (routeId: string | null) =>
    createSelector(selectState, (state) => {
        return state.errorsDraft.filter((error) => error.routeId === routeId && error.type === ROUTE_ERROR_TYPES.NO_DRIVER) || [];
    });

/**
 * Vehicles
 * */
export const selectVehiclesErrors = createSelector(
    selectState,
    (state) =>
        state.errorsDraft.filter((error) => error.type === ROUTE_ERROR_TYPES.VEHICLE_MULTIPLE_ROUTES_SAME_TAG || error.type === ROUTE_ERROR_TYPES.NO_VEHICLE) ||
        [],
);

export const selectVehicleErrors = (vehicleId: string) =>
    createSelector(selectState, (state) => {
        return state.errorsDraft.filter((error) => error.vehicleId === vehicleId && error.type === ROUTE_ERROR_TYPES.VEHICLE_MULTIPLE_ROUTES_SAME_TAG) || [];
    });

export const selectVehicleWarnings = (vehicleId: string) =>
    createSelector(selectState, (state) => {
        return (
            state.errorsDraft.filter((error) => error.vehicleId === vehicleId && error.type === ROUTE_ERROR_TYPES.VEHICLE_MULTIPLE_ROUTES_NOT_SAME_TAG) || []
        );
    });

export const selectRouteVehicleErrors = (routeId: string | null) =>
    createSelector(selectState, (state) => {
        return state.errorsDraft.filter((error) => error.routeId === routeId && error.type === ROUTE_ERROR_TYPES.NO_VEHICLE) || [];
    });

/**
 * Transfers
 */
export const selectRouteTransferErrors = (routeId: string | null) =>
    createSelector(selectState, (state) => {
        return (
            state.errorsDraft.filter(
                (error) =>
                    error.routeId === routeId &&
                    (error.type === ROUTE_ERROR_TYPES.BOOKING_STILL_IN ||
                        error.type === ROUTE_ERROR_TYPES.BOOKING_NOT_OUT ||
                        error.type === ROUTE_ERROR_TYPES.TRACE_START ||
                        error.type === ROUTE_ERROR_TYPES.TRACE_STOP ||
                        error.type === ROUTE_ERROR_TYPES.TRACE_IN ||
                        error.type === ROUTE_ERROR_TYPES.TRACE_OUT),
            ) || []
        );
    });

export const selectWaypointsErrors = (pointsIds: string[]) =>
    createSelector(selectState, (state) => {
        return (
            state.errorsDraft.filter(
                (error) =>
                    error.pointId !== undefined &&
                    error.pointId !== null &&
                    pointsIds.includes(error.pointId) &&
                    (error.type === ROUTE_ERROR_TYPES.MULTIPLE_STARTS || error.type === ROUTE_ERROR_TYPES.MULTIPLE_STOPS),
            ) || []
        );
    });

export const selectRouteWaypointErrors = (routeId: string | null, pointIds: string | string[]) =>
    createSelector(selectState, (state) => {
        const ids = <string[]>[];
        if (typeof pointIds === 'string') {
            ids.push(pointIds);
        } else {
            ids.push(...pointIds);
        }
        return (
            state.errorsDraft.filter(
                (error) =>
                    error.pointId !== undefined &&
                    error.routeId !== undefined &&
                    error.pointId !== null &&
                    ids.includes(error.pointId) &&
                    error.routeId === routeId &&
                    (error.type === ROUTE_ERROR_TYPES.BOOKING_STILL_IN ||
                        error.type === ROUTE_ERROR_TYPES.BOOKING_NOT_OUT ||
                        error.type === ROUTE_ERROR_TYPES.TRACE_START ||
                        error.type === ROUTE_ERROR_TYPES.TRACE_STOP ||
                        error.type === ROUTE_ERROR_TYPES.TRACE_IN ||
                        error.type === ROUTE_ERROR_TYPES.TRACE_OUT ||
                        error.type === ROUTE_ERROR_TYPES.TRACE_LEFT_BEHIND),
            ) || []
        );
    });

export const selectRouteWaypointTransferErrors = (routeId: string | null, transferPointId: string | null, pointIds: string | string[]) =>
    createSelector(selectState, (state) => {
        const ids = <string[]>[];
        if (typeof pointIds === 'string') {
            ids.push(pointIds);
        } else {
            ids.push(...pointIds);
        }
        return (
            state.errorsDraft.filter(
                (error) =>
                    error.pointId !== undefined &&
                    error.routeId !== undefined &&
                    error.pointId !== null &&
                    error.routeId === routeId &&
                    error.transferPointId === transferPointId &&
                    ids.includes(error.pointId) &&
                    (error.type === ROUTE_ERROR_TYPES.BOOKING_STILL_IN ||
                        error.type === ROUTE_ERROR_TYPES.BOOKING_NOT_OUT ||
                        error.type === ROUTE_ERROR_TYPES.TRACE_START ||
                        error.type === ROUTE_ERROR_TYPES.TRACE_STOP ||
                        error.type === ROUTE_ERROR_TYPES.TRACE_IN ||
                        error.type === ROUTE_ERROR_TYPES.TRACE_OUT ||
                        error.type === ROUTE_ERROR_TYPES.TRACE_LEFT_BEHIND),
            ) || []
        );
    });

export const selectWaypointErrors = (pointId: string) =>
    createSelector(selectState, (state) => {
        return (
            state.errorsDraft.filter(
                (error) =>
                    error.pointId !== undefined &&
                    error.pointId === pointId &&
                    (error.type === ROUTE_ERROR_TYPES.MULTIPLE_STARTS || error.type === ROUTE_ERROR_TYPES.MULTIPLE_STOPS),
            ) || []
        );
    });

export const selectRouteTimeErrors = (routeId: string | null) =>
    createSelector(selectState, (state) => {
        return (
            state.errorsDraft.filter(
                (error) =>
                    error.routeId === routeId && (error.type === ROUTE_ERROR_TYPES.WAYPOINT_TIME || error.type === ROUTE_ERROR_TYPES.WAYPOINT_TIME_EMPTY),
            ) || []
        );
    });

/**
 * Route Point
 */

export const selectRoutePointIdTransferErrors = (pointId: string, routeId: string | null) =>
    createSelector(selectState, (state) => {
        return (
            state.errorsDraft.filter(
                (error) =>
                    error.routeId === routeId &&
                    error.pointId === pointId &&
                    (error.type === ROUTE_ERROR_TYPES.BOOKING_STILL_IN ||
                        error.type === ROUTE_ERROR_TYPES.BOOKING_NOT_OUT ||
                        error.type === ROUTE_ERROR_TYPES.MULTIPLE_STARTS ||
                        error.type === ROUTE_ERROR_TYPES.MULTIPLE_STOPS),
            ) || []
        );
    });

export const selectRoutePointIdTimeErrors = (pointId: string, routeId: string | null) =>
    createSelector(selectState, (state) => {
        return (
            state.errorsDraft.filter(
                (error) =>
                    error.routeId === routeId &&
                    error.pointId === pointId &&
                    (error.type === ROUTE_ERROR_TYPES.WAYPOINT_TIME || error.type === ROUTE_ERROR_TYPES.WAYPOINT_TIME_EMPTY),
            ) || []
        );
    });

/**
 * Traces
 */
export const selectBookingRoutesTraces = (pointId: string) =>
    createSelector(selectState, (state) => {
        return state.traces.find((traces) => traces.pointId === pointId)?.traces || [];
    });
