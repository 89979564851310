import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FilterModel } from '../models';

export const filtersActions = createActionGroup({
    source: 'Planning - Filters',
    events: {
        'Get Filters': props<{ silent: boolean }>(),
        'Get Filters Success': props<{ payload: FilterModel[] }>(),
        'Get Filters Error': emptyProps(),
        Clear: emptyProps(),
    },
});
