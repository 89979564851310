import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Route } from '../models';
import { RouteNotification } from '../models/route-notification';

export const routesActions = createActionGroup({
    source: 'Planning - Routes',
    events: {
        'Get Routes': props<{ silent: boolean }>(),
        'Get Routes Success': props<{ payload: Route[] }>(),
        'Get Routes Error': emptyProps(),

        'Activate Notifications Success': props<{ payload: RouteNotification[] }>(),
        'Deactivate Notifications Success': props<{ payload: RouteNotification[] }>(),

        'Clear Store': props<{ range?: 'transfer-point' | 'draft' }>(),
    },
});
