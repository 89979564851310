import { PLANNING_TYPE } from '../../enums';
import { GeoAddressDto, GeoAddressModel } from '../../models';

export interface TransferPointDto {
    id: string;
    name: string;
    planningType: PLANNING_TYPE;
    date: string;
    address: GeoAddressDto;
    active: boolean;
    predefined: boolean;
}

export class TransferPointModel {
    id: string;
    name: string;
    planningType: PLANNING_TYPE;
    date: string;
    address: GeoAddressModel;
    active: boolean;
    predefined: boolean;

    constructor(data: TransferPointDto) {
        this.id = data.id;
        this.name = data.name;
        this.planningType = data.planningType;
        this.date = data.date;
        this.address = new GeoAddressModel(data.address);
        this.active = data.active;
        this.predefined = data.predefined;
    }
}
