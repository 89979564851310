import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectSelectedRoutes } from '../selectors/select-routes.selector';
import { selectRoutesActions } from '../actions/select-routes.actions';
import { tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PlanningSelectRoutesFacade {
    selectedIds$ = this.store.select(selectSelectedRoutes);

    constructor(private store: Store) {}

    get selectedIds() {
        let ids: string[] = [];
        this.store
            .select(selectSelectedRoutes)
            .pipe(tap((value) => (ids = value)))
            .subscribe();
        return ids;
    }

    selectIds(id: string | string[]): void {
        const ids = Array.isArray(id) ? id : [id];
        this.store.dispatch(selectRoutesActions.selectIdsAction({ ids }));
    }

    unselectIds(id: string | string[]): void {
        const ids = Array.isArray(id) ? id : [id];
        this.store.dispatch(selectRoutesActions.unselectIdsAction({ ids }));
    }

    clear() {
        this.store.dispatch(selectRoutesActions.clearAction());
    }
}
