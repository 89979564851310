import { createReducer, on } from '@ngrx/store';
import { routesActions } from '../actions/routes.actions';
import { WaypointNewModel } from '../../models';
import { RoutesUtils } from '../utils/routes.util';
import { RouteDraft, Route } from '../models/route';
import { WaypointsTransitsUtils } from '../utils/waypoints-transits.util';
import { routesDraftActions } from '../actions/routes-draft.actions';
import { RouteError } from '../models/route.error';

export interface RoutesState {
    routes: Route[];
    loading: boolean | null;
    loaded: boolean | null;
    draft: {
        route: RouteDraft | Route | null;
        transferPointEdit: string | null;
        saving: boolean | null;
        saved: boolean | null;
        error: RouteError | null;
    };
}

export const initialState: RoutesState = {
    routes: [],
    loading: null,
    loaded: null,
    draft: {
        route: null,
        transferPointEdit: null,
        saving: null,
        saved: null,
        error: null,
    },
};

export const routesReducer = createReducer(
    initialState,
    on(
        routesActions.getRoutes,
        (state): RoutesState => ({
            ...state,
            loading: true,
        }),
    ),
    on(routesActions.getRoutesSuccess, (state, action): RoutesState => {
        const routesUtils = new RoutesUtils([...(action.payload || [])]);
        routesUtils.setAllSequences();
        const routes = routesUtils.routesWithoutDraft;
        return {
            ...state,
            routes: routes,
            loaded: true,
            loading: false,
        };
    }),
    on(
        routesActions.getRoutesError,
        (state): RoutesState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),

    /**
     * Add/Remove Route
     */
    on(
        routesDraftActions.addNewDraft,
        (state, action): RoutesState => ({
            ...state,
            draft: {
                ...state.draft,
                route: new RouteDraft(action.query),
            },
        }),
    ),
    on(routesDraftActions.setRouteDraft, (state, action): RoutesState => {
        const route = state.routes.find((route) => route.id === action.routeId);
        if (route) {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    route,
                },
            };
        }
        return state;
    }),

    on(routesDraftActions.reloadRouteDraft, (state): RoutesState => {
        const id = state.draft.route?.id;

        if (id) {
            const route = state.routes.find((route) => route.id === id);
            if (route) {
                return {
                    ...state,
                    draft: {
                        ...initialState.draft,
                        route,
                    },
                };
            }
        }

        return state;
    }),

    /**
     * Add Waypoint to Route
     */
    on(routesDraftActions.addRouteDraftWaypoint, (state, action): RoutesState => {
        if (state.draft.route !== null) {
            const waypointIndex = state.draft.route.waypoints.findIndex((waypoint) => waypoint.pointId === action.pointId && waypoint.type === action.kind);
            if (waypointIndex === -1) {
                const waypoint = new WaypointNewModel(action.pointId, action.kind);
                const routesUtils = new RoutesUtils([...state.routes]);
                routesUtils.setDraftRoute(state.draft.route);
                routesUtils.add(waypoint);

                return {
                    ...state,
                    draft: {
                        ...state.draft,
                        route: {
                            ...state.draft.route,
                            waypoints: routesUtils.waypoints,
                        },
                    },
                };
            }
        }
        return state;
    }),

    /**
     * Remove Waypoint from Route
     */
    on(routesDraftActions.removeRouteDraftWaypoint, (state, action): RoutesState => {
        if (state.draft.route !== null) {
            const waypointIndex = state.draft.route.waypoints.findIndex((waypoint) => waypoint.pointId === action.pointId && waypoint.type === action.kind);
            if (waypointIndex !== -1) {
                const routesUtils = new RoutesUtils([...state.routes]);
                routesUtils.setDraftRoute(state.draft.route);
                routesUtils.remove(waypointIndex);

                return {
                    ...state,
                    draft: {
                        ...state.draft,
                        route: {
                            ...state.draft.route,
                            waypoints: routesUtils.waypoints,
                        },
                    },
                };
            }
        }
        return state;
    }),

    /**
     * Add Waypoint to Route - Transfer Time Update
     */
    on(routesDraftActions.addRouteDraftWaypointTransferTime, (state, action): RoutesState => {
        if (state.draft.route !== null) {
            const routesUtils = new RoutesUtils([...state.routes]);
            routesUtils.setDraftRoute(state.draft.route);
            routesUtils.setWaypointTime(action.pointId, action.kind, action.date);
            return {
                ...state,
                draft: {
                    ...state.draft,
                    route: {
                        ...state.draft.route,
                        waypoints: routesUtils.waypoints,
                    },
                },
            };
        }
        return state;
    }),

    /**
     * Toggle Waypoint from Route
     */
    on(routesDraftActions.toggleRouteDraftWaypointTransfersIn, (state, action): RoutesState => {
        if (state.draft.route !== null) {
            const waypointIndex = state.draft.route.waypoints.findIndex((waypoint) => waypoint.pointId === action.pointId);
            if (waypointIndex !== -1) {
                const routesUtils = new RoutesUtils([...state.routes]);
                routesUtils.setDraftRoute(state.draft.route);
                routesUtils.toggleTransfersIn(action.pointId);

                return {
                    ...state,
                    draft: {
                        ...state.draft,
                        route: {
                            ...state.draft.route,
                            waypoints: routesUtils.waypoints,
                        },
                    },
                };
            }
        }
        return state;
    }),

    /**
     * Toggle Waypoint from Route
     */
    on(routesDraftActions.toggleRouteDraftWaypointTransfersOut, (state, action): RoutesState => {
        if (state.draft.route !== null) {
            const waypointIndex = state.draft.route.waypoints.findIndex((waypoint) => waypoint.pointId === action.pointId);
            if (waypointIndex !== -1) {
                const routesUtils = new RoutesUtils([...state.routes]);
                routesUtils.setDraftRoute(state.draft.route);
                routesUtils.toggleTransfersOut(action.pointId);

                return {
                    ...state,
                    draft: {
                        ...state.draft,
                        route: {
                            ...state.draft.route,
                            waypoints: routesUtils.waypoints,
                        },
                    },
                };
            }
        }
        return state;
    }),

    /**
     * Move waypoint
     */
    on(routesDraftActions.moveRouteDraftWaypoint, (state, action): RoutesState => {
        if (state.draft.route !== null) {
            const routesUtils = new RoutesUtils([...state.routes]);
            routesUtils.setDraftRoute(state.draft.route);
            routesUtils.move(action.fromIndex, action.toIndex);

            return {
                ...state,
                draft: {
                    ...state.draft,
                    route: {
                        ...state.draft.route,
                        waypoints: routesUtils.waypoints,
                    },
                },
            };
        }
        return state;
    }),

    /**
     * Add Driver/Vehicle
     */
    on(routesDraftActions.setRouteDraftDriverAndVehicleSuccess, (state, action): RoutesState => {
        if (state.draft.route !== null) {
            const route = {
                ...state.draft.route,
                driverId: action.driverId !== null ? action.driverId : state.draft.route.driverId,
                vehicleId: action.vehicleId !== null ? action.vehicleId : state.draft.route.vehicleId,
            };
            return {
                ...state,
                draft: {
                    ...state.draft,
                    route,
                },
            };
        }
        return state;
    }),

    /**
     * Move Transfer in/out
     */
    on(routesDraftActions.moveRouteDraftPointTransferToggle, (state, action): RoutesState => {
        if (state.draft.route !== null && state.draft.transferPointEdit !== null) {
            const routesWithDraft = state.routes.map((route) => (route.id === state.draft.route?.id ? state.draft.route : route));
            const waypointsTransitsUtils = new WaypointsTransitsUtils(routesWithDraft, state.draft.route.id, state.draft.transferPointEdit);
            waypointsTransitsUtils.moveToggle(action.pointId);

            return {
                ...state,
                draft: {
                    ...state.draft,
                    route: {
                        ...state.draft.route,
                        waypoints: waypointsTransitsUtils.get(),
                    },
                },
            };
        }
        return state;
    }),

    /**
     * Move Transfer out
     */
    on(routesDraftActions.moveRouteDraftPointTransferOut, (state, action): RoutesState => {
        if (state.draft.route !== null && state.draft.transferPointEdit !== null) {
            const routesWithDraft = state.routes.map((route) => (route.id === state.draft.route?.id ? state.draft.route : route));
            const waypointsTransitsUtils = new WaypointsTransitsUtils(routesWithDraft, state.draft.route.id, state.draft.transferPointEdit);
            waypointsTransitsUtils.moveOut(action.pointId);

            return {
                ...state,
                draft: {
                    ...state.draft,
                    route: {
                        ...state.draft.route,
                        waypoints: waypointsTransitsUtils.get(),
                    },
                },
            };
        }
        return state;
    }),

    /**
     * Move Transfer in
     */
    on(routesDraftActions.moveRouteDraftPointTransferIn, (state, action): RoutesState => {
        if (state.draft.route !== null && state.draft.transferPointEdit !== null) {
            const routesWithDraft = state.routes.map((route) => (route.id === state.draft.route?.id ? state.draft.route : route));
            const waypointsTransitsUtils = new WaypointsTransitsUtils(routesWithDraft, state.draft.route.id, state.draft.transferPointEdit);
            waypointsTransitsUtils.moveIn(action.pointId);

            return {
                ...state,
                draft: {
                    ...state.draft,
                    route: {
                        ...state.draft.route,
                        waypoints: waypointsTransitsUtils.get(),
                    },
                },
            };
        }
        return state;
    }),

    /**
     * Add Driver/Vehicle
     */
    on(routesDraftActions.selectRouteDraftTransferPointEdit, (state, action): RoutesState => {
        if (state.draft.route !== null) {
            const pointExists = state.draft.route.waypoints.findIndex((waypoint) => waypoint.pointId === action.pointId) !== -1;
            if (pointExists) {
                return {
                    ...state,
                    draft: {
                        ...state.draft,
                        transferPointEdit: action.pointId,
                    },
                };
            }
        }
        return state;
    }),

    /**
     * Set DriverId
     */
    on(routesDraftActions.setRouteDraftDriverId, (state, action): RoutesState => {
        if (state.draft.route !== null) {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    route: {
                        ...state.draft.route,
                        driverId: action.driverId,
                    },
                },
            };
        }
        return state;
    }),

    /**
     * Set Additional Drivers Ids
     */
    on(routesDraftActions.setRouteDraftAdditinalDriversIds, (state, action): RoutesState => {
        if (state.draft.route !== null) {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    route: {
                        ...state.draft.route,
                        additionalDriversIds: action.driversIds.filter((id): id is string => id !== null),
                    },
                },
            };
        }
        return state;
    }),

    /**
     * Set VehicleID
     */
    on(routesDraftActions.setRouteDraftVehicleId, (state, action): RoutesState => {
        if (state.draft.route !== null) {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    route: {
                        ...state.draft.route,
                        vehicleId: action.vehicleId,
                    },
                },
            };
        }
        return state;
    }),

    /**
     * Set Route Draft Tags
     */
    on(routesDraftActions.setRouteDraftTags, (state, action): RoutesState => {
        if (state.draft.route !== null) {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    route: {
                        ...state.draft.route,
                        tags: action.tags,
                    },
                },
            };
        }
        return state;
    }),

    /**
     * Set Route Waypoint Time
     */
    on(routesDraftActions.setRouteWaypointTime, (state, action): RoutesState => {
        if (state.draft.route !== null) {
            const routesUtils = new RoutesUtils([...state.routes]);
            routesUtils.setDraftRoute(state.draft.route);
            routesUtils.setWaypointTime(action.pointId, action.kind, action.time);
            return {
                ...state,
                draft: {
                    ...state.draft,
                    route: {
                        ...state.draft.route,
                        waypoints: routesUtils.waypoints,
                    },
                },
            };
        }
        return state;
    }),

    /**
     * Set Route Waypoint Time Relative
     */
    on(routesDraftActions.setRouteWaypointTimeRelativeSuccess, (state, action): RoutesState => {
        if (state.draft.route !== null && action.calculation !== null) {
            const routesUtils = new RoutesUtils([...state.routes]);
            routesUtils.setDraftRoute(state.draft.route);
            routesUtils.setWaypointTimeRelative(action.pointId, action.kind, action.direction, action.calculation);
            return {
                ...state,
                draft: {
                    ...state.draft,
                    route: {
                        ...state.draft.route,
                        waypoints: routesUtils.waypoints,
                    },
                },
            };
        }
        return state;
    }),

    /**
     * Set Route Waypoints Length
     */
    on(routesDraftActions.setRouteWaypointsLengthSuccess, (state, action): RoutesState => {
        if (state.draft.route !== null && action.payload !== null) {
            const routesUtils = new RoutesUtils([...state.routes]);
            routesUtils.setDraftRoute(state.draft.route);
            routesUtils.setWaypointsLength(action.payload.calculation);
            return {
                ...state,
                draft: {
                    ...state.draft,
                    route: {
                        ...state.draft.route,
                        hereMapsRoute: action.payload.raw || null,
                        waypoints: routesUtils.waypoints,
                    },
                },
            };
        }
        return state;
    }),

    /**
     * Save New Route
     */
    on(
        routesDraftActions.saveRouteDraft,
        (state): RoutesState => ({
            ...state,
            draft: {
                ...state.draft,
                saved: false,
                saving: true,
                transferPointEdit: null,
            },
        }),
    ),

    on(routesDraftActions.saveRouteDraftSuccess, (state, action): RoutesState => {
        const routes = [...state.routes];
        // update
        action.payload.updated.forEach((updatedRoute) => {
            const routeIndex = routes.findIndex((route) => route.id === updatedRoute.id);
            if (routeIndex === -1) {
                routes.push(updatedRoute);
            } else {
                routes.splice(routeIndex, 1, updatedRoute);
            }
        });

        // delete
        action.payload.deletedIds.forEach((deletedId) => {
            const routeIndex = routes.findIndex((route) => route.id === deletedId);
            if (routeIndex !== -1) {
                routes.splice(routeIndex, 1);
            }
        });

        // set sequence
        const routesUtils = new RoutesUtils(routes);
        routesUtils.setAllSequences();
        return {
            ...state,
            routes: routesUtils.routesWithoutDraft,
            draft: {
                ...state.draft,
                saved: true,
                saving: false,
            },
        };
    }),

    on(routesDraftActions.saveRouteDraftError, (state, action): RoutesState => {
        return {
            ...state,
            draft: {
                ...state.draft,
                saved: false,
                saving: false,
                error: action.payload,
            },
        };
    }),

    /**
     * Update Route
     */
    on(
        routesDraftActions.updateRouteDraft,
        (state): RoutesState => ({
            ...state,
            draft: {
                ...state.draft,
                saved: false,
                saving: true,
                transferPointEdit: null,
            },
        }),
    ),

    on(routesDraftActions.updateRouteDraftSuccess, (state, action): RoutesState => {
        const routes = [...state.routes];
        // update
        action.payload.updated.forEach((updatedRoute) => {
            const routeIndex = routes.findIndex((route) => route.id === updatedRoute.id);
            routes.splice(routeIndex, 1, updatedRoute);
        });

        // delete
        action.payload.deletedIds.forEach((deletedId) => {
            const routeIndex = routes.findIndex((route) => route.id === deletedId);
            if (routeIndex !== -1) {
                routes.splice(routeIndex, 1);
            }
        });

        // set sequence
        const routesUtils = new RoutesUtils(routes);
        routesUtils.setAllSequences();
        return {
            ...state,
            routes: routesUtils.routesWithoutDraft,
            draft: {
                ...state.draft,
                saved: true,
                saving: false,
            },
        };
    }),

    on(routesDraftActions.updateRouteDraftDeleteSuccess, (state): RoutesState => {
        const routes = [...state.routes];
        if (state.draft.route) {
            const id = state.draft.route.id;
            const routeIndex = routes.findIndex((route) => route.id === id);
            routes.splice(routeIndex, 1);
        }
        const routesUtils = new RoutesUtils(routes);
        routesUtils.setAllSequences();
        return {
            ...state,
            routes: routesUtils.routesWithoutDraft,
            draft: {
                ...state.draft,
                saved: true,
                saving: false,
            },
        };
    }),

    on(routesDraftActions.updateRouteDraftError, (state, action): RoutesState => {
        return {
            ...state,
            draft: {
                ...state.draft,
                saved: false,
                saving: false,
                error: action.payload,
            },
        };
    }),

    /**
     * Notification Status Update
     */
    on(routesActions.activateNotificationsSuccess, (state, action): RoutesState => {
        if (state.routes.length === 0) {
            return { ...state };
        }

        const routes = [...state.routes];

        action.payload.forEach((routeSettings) => {
            const routeIndex = routes.findIndex((route) => route.id === routeSettings.id);
            if (routeIndex !== -1) {
                const route = {
                    ...routes[routeIndex],
                    notificationsEnabled: routeSettings.notificationsEnabled,
                    controlTimestamp: routeSettings.controlTimestamp,
                };
                routes.splice(routeIndex, 1, route);
            }
        });

        return {
            ...state,
            routes,
        };
    }),

    on(routesActions.deactivateNotificationsSuccess, (state, action): RoutesState => {
        if (state.routes.length === 0) {
            return { ...state };
        }

        const routes = [...state.routes];

        action.payload.forEach((routeSettings) => {
            const routeIndex = routes.findIndex((route) => route.id === routeSettings.id);
            if (routeIndex !== -1) {
                const route = {
                    ...routes[routeIndex],
                    notificationsEnabled: routeSettings.notificationsEnabled,
                    controlTimestamp: routeSettings.controlTimestamp,
                };
                routes.splice(routeIndex, 1, route);
            }
        });

        return {
            ...state,
            routes,
        };
    }),

    /**
     * Clear
     */
    on(routesActions.clearStore, (state, action): RoutesState => {
        if (action.range === 'transfer-point') {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    transferPointEdit: null,
                },
            };
        }
        if (action.range === 'draft') {
            return {
                ...state,
                draft: {
                    ...initialState.draft,
                },
            };
        }
        return {
            ...state,
            ...initialState,
        };
    }),
);
