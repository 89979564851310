import { Injectable } from '@angular/core';
import { filter, map, share } from 'rxjs';
import { Store } from '@ngrx/store';
import * as routeSelectors from '../selectors/route.selectors';
import * as routesTagsSelectors from '../selectors/routes-tags.selectors';
import * as routesSelectors from '../selectors/routes.selectors';
import { routesActions } from '../actions/routes.actions';
import { Route, RouteTag } from '../models';
import { WAYPOINT_TYPES } from '../../enums';

@Injectable({
    providedIn: 'root',
})
export class PlanningRoutesFacade {
    loaded$ = this.store.select(routesSelectors.selectLoaded).pipe(filter((data): data is boolean => data !== null));
    loading$ = this.store.select(routesSelectors.selectLoading).pipe(filter((data): data is boolean => data !== null));
    saved$ = this.store.select(routesSelectors.selectSaved).pipe(filter((saved): saved is boolean => saved !== null));
    saving$ = this.store.select(routesSelectors.selectSaving).pipe(filter((data): data is boolean => data !== null));

    /** Routes */
    routes$ = this.store.select(routesSelectors.selectRoutes);
    routesWaypoints$ = this.store.select(routesSelectors.selectRoutesWaypoints);

    routesByTransferPoint$ = (pointId: string) => this.store.select(routesSelectors.selectRoutesByTransferPoint(pointId));
    routesByTag$ = (tag: RouteTag) => this.store.select(routesTagsSelectors.selectRoutesByTag(tag));
    routesWithoutTag$ = this.store.select(routesTagsSelectors.selectRoutesWithoutTag);

    route$ = (id: string | null) => this.store.select(routeSelectors.selectRoute(id)).pipe(filter((data): data is Route => data !== null));
    routeWaypoitns$ = (id: string | null) => this.store.select(routeSelectors.selectRouteWaypoints(id));
    routeMaxPassengers$ = (id: string | null) => this.store.select(routeSelectors.selectRouteMaxPassengers(id));

    routeDriverId$(id: string) {
        return this.store.select(routeSelectors.selectRouteDriverId(id));
    }

    routeAdditionalDriversIds$ = (id: string | null) => this.store.select(routeSelectors.selectRouteAdditionalDriversId(id));

    routeVehicleId$(id: string) {
        return this.store.select(routeSelectors.selectRouteVehicleId(id));
    }

    /** Tags */
    tags$ = this.store.select(routesTagsSelectors.selectTags).pipe(filter((data): data is RouteTag[] => data !== null));
    tagsCustoms$ = this.store.select(routesTagsSelectors.selectCustomTags).pipe(filter((data): data is RouteTag[] => data !== null));
    transferPointTags$ = (pointID: string) =>
        this.store.select(routesTagsSelectors.selectTransferPointTags(pointID)).pipe(filter((data): data is RouteTag[] => data !== null));

    routesByTransferPointTag$ = (pointId: string, tag: RouteTag) =>
        this.store.select(routesTagsSelectors.selectRoutesByTransferPointTag(pointId, tag)).pipe(filter((data): data is Route[] => data !== null));

    routesByTransferPointWithoutTag$ = (pointId: string) =>
        this.store.select(routesTagsSelectors.selectRoutesByTransferPointWithoutTag(pointId)).pipe(filter((data): data is Route[] => data !== null));

    /** Customs */
    waypointIds$ = this.routes$.pipe(
        map((routes) =>
            routes.reduce(
                (prev, next) => {
                    prev.push(...next.waypoints.map((waypoint) => ({ id: waypoint.pointId, kind: waypoint.type })));
                    return prev;
                },
                [] as { id: string; kind: WAYPOINT_TYPES }[],
            ),
        ),
        share(),
    );

    driverIds$ = this.routes$.pipe(
        map((routes) =>
            routes.reduce((prev, next) => {
                if (next.driverId) {
                    prev.push(next.driverId);
                }
                return prev;
            }, [] as string[]),
        ),
        share(),
    );

    vehicleIds$ = this.routes$.pipe(
        map((routes) =>
            routes.reduce((prev, next) => {
                if (next.vehicleId) {
                    prev.push(next.vehicleId);
                }
                return prev;
            }, [] as string[]),
        ),
        share(),
    );

    /**
     * Activated Notifications
     */
    allNotificationsActive$ = this.store.select(routesSelectors.selectAllNotificationsActive);

    constructor(private store: Store) {}

    clear(range?: 'transfer-point' | 'draft'): void {
        switch (range) {
            case 'transfer-point':
                this.store.dispatch(routesActions.clearStore({ range }));
                break;
            case 'draft':
                this.store.dispatch(routesActions.clearStore({ range }));
                break;
            default:
                this.store.dispatch(routesActions.clearStore({}));
                break;
        }
    }
}
