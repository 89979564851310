import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs';
import { GeoPositionModel } from '../../models';
import { RouteCalculationModel } from '../models';
import { routeCalculationActions } from '../actions/route-calculation.actions';
import { selectCalculation, selectLoaded, selectLoading } from '../selectors/route-calculation.selector';

@Injectable({
    providedIn: 'root',
})
export class RouteCalculationFacade {
    calculation$ = this.store.select(selectCalculation);
    loading$ = this.store.select(selectLoading).pipe(filter((loading): loading is boolean => loading !== null));
    laoded$ = this.store.select(selectLoaded).pipe(filter((laoded): laoded is boolean => laoded !== null));

    constructor(private store: Store) {}

    getRouteCalculation(points: GeoPositionModel[]): void {
        this.store.dispatch(routeCalculationActions.getRouteCalculationAction({ points }));
    }

    clear() {
        this.store.dispatch(routeCalculationActions.clearAction());
    }

    get calculation(): RouteCalculationModel | null {
        let value = null;
        this.calculation$
            .pipe(
                take(1),
                tap((data) => (value = data)),
            )
            .subscribe();
        return value;
    }
}
