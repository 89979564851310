import { Injectable, inject } from '@angular/core';
import { of, catchError, map, finalize, takeUntil, repeat, filter } from 'rxjs';
import { exhaustMapWithTrailing } from 'rxjs-exhaustmap-with-trailing';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { GuiFacade } from '@app/gui';
import { HttpService } from '../services/http.service';
import { taskPointsActions } from '../actions/task-points.actions';
import { Store } from '@ngrx/store';
import { taskPointActions } from '../../task-point/actions/task-point.actions';
import { queryActions } from '../../query/actions/query.actions';
import { NonNullablePlanningQuery } from '../../query/selectors/query.selector';
import { routesDraftActions } from '../../routes/actions/routes-draft.actions';
import { transferPointActions } from '../../transfer-point/actions/transfer-point.actions';
import { draftPointActions } from '../../draft-point/actions/draft-point.actions';
import { filterActions } from '../../filter/actions/filter.actions';
import { PlanningProcessingFacade } from '../../processing';
import { refreshActions } from '../../refresh/actions/refresh.actions';

@Injectable()
export class TaskPointsEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    private readonly httpService = inject(HttpService);
    private readonly guiFacade = inject(GuiFacade);
    private readonly planningProcessingFacade = inject(PlanningProcessingFacade);

    takeUntil$ = this.actions$.pipe(ofType(queryActions.set, queryActions.clear));

    queryPlanningChange$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(queryActions.set),
            filter((data) => data.query !== null),
            map(() => taskPointsActions.getTaskPoints({ silent: false })),
        );
    });

    reloadQueryPlanning$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                // refresh
                refreshActions.event,
                // task
                taskPointActions.addTaskPointSuccess,
                taskPointActions.updateTaskPointSuccess,
                taskPointActions.deleteTaskPointSuccess,
                taskPointActions.addTaskPointError,
                taskPointActions.updateTaskPointError,
                taskPointActions.deleteTaskPointError,
                taskPointActions.getTaskPointError,

                // transfer
                transferPointActions.addTransferPointSuccess,
                transferPointActions.updateTransferPointSuccess,
                transferPointActions.deleteTransferPointSuccess,
                transferPointActions.addTransferPointError,
                transferPointActions.updateTransferPointError,
                transferPointActions.deleteTransferPointError,

                // Draft
                draftPointActions.addDraftPointSuccess,
                draftPointActions.updateDraftPointSuccess,
                draftPointActions.deleteDraftPointSuccess,
                draftPointActions.addDraftPointError,
                draftPointActions.updateDraftPointError,
                draftPointActions.deleteDraftPointError,

                // filters
                filterActions.addFilterSuccess,
                filterActions.deleteFilterSuccess,
                filterActions.updateFilterSuccess,
                filterActions.attachFilterSuccess,
                filterActions.detachFilterSuccess,
                filterActions.addFilterError,
                filterActions.deleteFilterError,
                filterActions.updateFilterError,
                filterActions.attachFilterError,
                filterActions.detachFilterError,

                // routes
                routesDraftActions.updateRouteDraftSuccess,
                routesDraftActions.saveRouteDraftSuccess,
                routesDraftActions.updateRouteDraftError,
                routesDraftActions.saveRouteDraftError,
            ),
            map(() => taskPointsActions.getTaskPoints({ silent: true })),
        );
    });

    clear$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(queryActions.set),
            map(() => taskPointsActions.clear()),
        );
    });

    getTaskPoints$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(taskPointsActions.getTaskPoints),
            concatLatestFrom(() => this.store.pipe(NonNullablePlanningQuery)),
            exhaustMapWithTrailing(([{ silent }, query]) => {
                silent ? this.planningProcessingFacade.add('task-points') : this.guiFacade.showLoader('get-task-points');
                return this.httpService.getTaskPoints(query).pipe(
                    map((response) => taskPointsActions.getTaskPointsSuccess({ payload: response })),
                    catchError(() => of(taskPointsActions.getTaskPointsError())),
                    finalize(() => {
                        silent ? this.planningProcessingFacade.del('task-points') : this.guiFacade.hideLoader('get-task-points');
                    }),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });
}
