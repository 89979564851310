import { createReducer, on } from '@ngrx/store';
import { partnersActions } from '../actions/partners.actions';
import { PartnerModel } from '../models';

export interface PartnersState {
    partners: PartnerModel[] | null;
    loading: boolean | null;
    loaded: boolean | null;
}

export const initialState: PartnersState = {
    partners: null,
    loading: null,
    loaded: null,
};

export const partnersReducer = createReducer(
    initialState,
    on(
        partnersActions.getPartnersAction,
        (state): PartnersState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        partnersActions.getPartnersSuccessAction,
        (state, action): PartnersState => ({
            ...state,
            partners: action.payload,
            loaded: true,
            loading: false,
        }),
    ),
    on(
        partnersActions.getPartnersErrorAction,
        (state): PartnersState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),
    on(
        partnersActions.clearAction,
        (state): PartnersState => ({
            ...state,
            ...initialState,
        }),
    ),
);
