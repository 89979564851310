import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoutesNotificationState } from '../reducers/routes-notification.reducer';
import { ROUTES_NOTIFICATIONS_KEY } from '../keys';

const selectState = createFeatureSelector<RoutesNotificationState>(ROUTES_NOTIFICATIONS_KEY);

const selectLoading = createSelector(selectState, (state) => state.activating.length > 0 || state.deactivating.length > 0);
const selectLoadingById = (id: string) => createSelector(selectState, (state) => state.activating.includes(id) || state.deactivating.includes(id));

export { selectLoading, selectLoadingById };
