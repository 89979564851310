import { Injectable, inject } from '@angular/core';
import { map, mergeMap, catchError, of, takeUntil, repeat, finalize } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routeCalculationActions } from '../actions/route-calculation.actions';
import { GuiFacade } from '@app/gui';
import { HttpService } from '../services/http.service';
import { queryActions } from '../../query/actions/query.actions';

@Injectable()
export class RouteCalculationEffects {
    private actions$ = inject(Actions);
    private guiFacade = inject(GuiFacade);
    private httpService = inject(HttpService);

    takeUntil$ = this.actions$.pipe(ofType(queryActions.set, queryActions.clear));

    getTransferPoints$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routeCalculationActions.getRouteCalculationAction),
            mergeMap((action) => {
                this.guiFacade.showLoader('get-route-calculation');
                return this.httpService.getRouteCalculation(action.points).pipe(
                    map((response) => routeCalculationActions.getRouteCalculationSuccessAction({ payload: response })),
                    catchError(() => {
                        return of(routeCalculationActions.getRouteCalculationErrorAction());
                    }),
                    finalize(() => this.guiFacade.hideLoader('get-route-calculation')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });
}
