import { PLANNING_TYPE } from '../../enums';
import { GeoAddressModel } from '../../models';

export class TransferPointValue {
    name: string;
    planningType: PLANNING_TYPE;
    date: string;
    address: GeoAddressModel;
    active: boolean;
    predefined: boolean;

    constructor(data: TransferPointValue) {
        const { latitude, longitude } = data.address.geoPosition;

        this.name = data.name;
        this.planningType = data.planningType;
        this.date = data.date;
        this.address = {
            ...data.address,
            geoPosition: {
                latitude: latitude ? parseFloat(latitude.toFixed(6)) : latitude,
                longitude: longitude ? parseFloat(longitude.toFixed(6)) : longitude,
            },
        };
        this.active = data.active;
        this.predefined = data.predefined;
    }
}
