import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { QueryState } from '../reducers/query.reducer';
import { QUERY_KEY } from '../keys';
import { filter, pipe } from 'rxjs';
import { PlanningQuery } from '../../models';

const selectState = createFeatureSelector<QueryState>(QUERY_KEY);
export const selectPlanningQuery = createSelector(selectState, (state) => state);

export const NonNullablePlanningQuery = pipe(
    select(selectState),
    filter((query): query is PlanningQuery => query.date !== null && query.type !== null),
);
