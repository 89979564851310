import { CanDeactivateFn } from '@angular/router';
import { Component, inject } from '@angular/core';
import { PlanningQueryFacade } from '../facades/query.facade';

export const PlanningQueryCleanup: CanDeactivateFn<Component> = () => {
    const planningQueryFacade = inject(PlanningQueryFacade);

    planningQueryFacade.clear();
    return true;
};
