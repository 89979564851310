import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, mergeMap, takeUntil, repeat, finalize } from 'rxjs';
import { transferPointActions } from '../actions/transfer-point.actions';
import { GuiFacade } from '@app/gui';
import { HttpService } from '../services/http.service';
import { queryActions } from '../../query/actions/query.actions';

@Injectable()
export class TransferPointEffects {
    private actions$ = inject(Actions);
    private httpService = inject(HttpService);
    private guiFacade = inject(GuiFacade);

    takeUntil$ = this.actions$.pipe(ofType(queryActions.set, queryActions.clear));

    getDraftPoint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(transferPointActions.getTransferPoint),
            mergeMap((action) => {
                this.guiFacade.showLoader('get-transfer-point');
                return this.httpService.getTransferPoint(action.id).pipe(
                    map((data) => transferPointActions.getTransferPointSuccess({ payload: data })),
                    catchError((error) => of(transferPointActions.getTransferPointError({ payload: error }))),
                    finalize(() => this.guiFacade.hideLoader('get-transfer-point')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    addTransferPoint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(transferPointActions.addTransferPoint),
            mergeMap((action) => {
                this.guiFacade.showLoader('add-transfer-point');
                return this.httpService.addTransferPoint(action.value).pipe(
                    map(() => transferPointActions.addTransferPointSuccess()),
                    catchError((error) => of(transferPointActions.addTransferPointError({ payload: error }))),
                    finalize(() => this.guiFacade.hideLoader('add-transfer-point')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    updateTransferPoint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(transferPointActions.updateTransferPoint),
            mergeMap((action) => {
                this.guiFacade.showLoader('update-transfer-point');
                return this.httpService.updateTransferPoint(action.id, action.value).pipe(
                    map(() => transferPointActions.updateTransferPointSuccess()),
                    catchError((error) => of(transferPointActions.updateTransferPointError({ payload: error }))),
                    finalize(() => this.guiFacade.hideLoader('update-transfer-point')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    deleteTransferPoint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(transferPointActions.deleteTransferPoint),
            mergeMap((action) => {
                this.guiFacade.showLoader('delete-transfer-point');
                return this.httpService.deleteTransferPoint(action.id).pipe(
                    map(() => transferPointActions.deleteTransferPointSuccess()),
                    catchError((error) => of(transferPointActions.deleteTransferPointError({ payload: error }))),
                    finalize(() => this.guiFacade.hideLoader('delete-transfer-point')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });
}
