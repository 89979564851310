import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, mergeMap, takeUntil, repeat, finalize } from 'rxjs';
import { taskPointActions } from '../actions/task-point.actions';
import { GuiFacade } from '@app/gui';
import { HttpService } from '../services/http.service';
import { queryActions } from '../../query/actions/query.actions';

@Injectable()
export class TaskPointEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly guiFacade = inject(GuiFacade);

    takeUntil$ = this.actions$.pipe(ofType(queryActions.set, queryActions.clear));

    getDraftPoint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(taskPointActions.getTaskPoint),
            mergeMap((action) => {
                this.guiFacade.showLoader('get-taskPoint');
                return this.httpService.getTaskPoint(action.id).pipe(
                    map((data) => taskPointActions.getTaskPointSuccess({ payload: data })),
                    catchError((error) => of(taskPointActions.getTaskPointError({ payload: error }))),
                    finalize(() => this.guiFacade.hideLoader('get-taskPoint')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    addTaskPoint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(taskPointActions.addTaskPoint),
            mergeMap((action) => {
                this.guiFacade.showLoader('add-taskPoint');
                return this.httpService.addTaskPoint(action.value).pipe(
                    map(() => taskPointActions.addTaskPointSuccess()),
                    catchError((error) => of(taskPointActions.addTaskPointError({ payload: error }))),
                    finalize(() => this.guiFacade.hideLoader('add-taskPoint')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    updateTaskPoint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(taskPointActions.updateTaskPoint),
            mergeMap((action) => {
                this.guiFacade.showLoader('update-taskPoint');
                return this.httpService.updateTaskPoint(action.id, action.value).pipe(
                    map(() => taskPointActions.updateTaskPointSuccess()),
                    catchError((error) => of(taskPointActions.updateTaskPointError({ payload: error }))),
                    finalize(() => this.guiFacade.hideLoader('update-taskPoint')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    deleteTaskPoint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(taskPointActions.deleteTaskPoint),
            mergeMap((action) => {
                this.guiFacade.showLoader('delete-taskPoint');
                return this.httpService.deleteTaskPoint(action.id).pipe(
                    map(() => taskPointActions.deleteTaskPointSuccess()),
                    catchError((error) => of(taskPointActions.deleteTaskPointError({ payload: error }))),
                    finalize(() => this.guiFacade.hideLoader('delete-taskPoint')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });
}
