import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { RouteNotification } from '../models/route-notification';

export const routesNotificationActions = createActionGroup({
    source: 'Planning - Routes Notifications',
    events: {
        Activate: props<{ ids: string[] }>(),
        'Activate All': emptyProps(),
        'Activate Success': props<{ payload: RouteNotification[] }>(),
        'Activate Error': props<{ ids: string[] }>(),
        Deactivate: props<{ ids: string[] }>(),
        'Deactivate All': emptyProps(),
        'Deactivate Success': props<{ payload: RouteNotification[] }>(),
        'Deactivate Error': props<{ ids: string[] }>(),
    },
});
