import { createReducer, on } from '@ngrx/store';
import { routeCalculationActions } from '../actions/route-calculation.actions';
import { RouteCalculationModel } from '../models';

export interface RouteCalculatinState {
    calculation: RouteCalculationModel | null;
    raw: unknown | null;
    loading: boolean | null;
    loaded: boolean | null;
}

export const initialState: RouteCalculatinState = {
    calculation: null,
    raw: null,
    loading: null,
    loaded: null,
};

export const routeCalculationReducer = createReducer(
    initialState,
    on(
        routeCalculationActions.getRouteCalculationAction,
        (state): RouteCalculatinState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        routeCalculationActions.getRouteCalculationSuccessAction,
        (state, action): RouteCalculatinState => ({
            ...state,
            loading: false,
            loaded: true,
            calculation: action.payload.calculation,
            raw: action.payload.raw,
        }),
    ),
    on(
        routeCalculationActions.getRouteCalculationAction,
        (state): RouteCalculatinState => ({
            ...state,
            loading: false,
            loaded: false,
            calculation: null,
            raw: null,
        }),
    ),
    on(
        routeCalculationActions.clearAction,
        (state): RouteCalculatinState => ({
            ...state,
            ...initialState,
        }),
    ),
);
