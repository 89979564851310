import { Injectable, inject } from '@angular/core';
import { of, catchError, map, mergeMap, filter, forkJoin, take, finalize, takeUntil, repeat } from 'rxjs';
import { exhaustMapWithTrailing } from 'rxjs-exhaustmap-with-trailing';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { GuiFacade } from '@app/gui';
import { Store } from '@ngrx/store';
import { HttpService } from '../services/http.service';
import { routesActions } from '../actions/routes.actions';
import { queryActions } from '../../query/actions/query.actions';
import { NonNullablePlanningQuery } from '../../query/selectors/query.selector';
import { selectDraftPoint } from '../../draft-points/selectors/draft-points.selector';
import { selectDraft } from '../selectors/draft.selectors';
import { RouteDraft, Route } from '../models';
import { WAYPOINT_TYPES } from '../../enums';
import { selectCalculation } from '../../route-calculation/selectors/route-calculation.selector';
import { taskPointActions } from '../../task-point/actions/task-point.actions';
import { selectTransferPoint } from '../../transfer-points-preset/selectors/transfer-points-preset.selector';
import { transferPointActions } from '../../transfer-point/actions/transfer-point.actions';
import { routeCalculationActions } from '../../route-calculation/actions/route-calculation.actions';
import { routesNotificationActions } from '../actions/routes-notification.actions';
import { routesDraftActions } from '../actions/routes-draft.actions';
import { RouteError } from '../models/route.error';
import { Time } from '@app/shared/classes';
import { draftPointActions } from '../../draft-point/actions/draft-point.actions';
import { filterActions } from '../../filter/actions/filter.actions';
import { PlanningProcessingFacade } from '../../processing';
import { refreshActions } from '../../refresh/actions/refresh.actions';

@Injectable()
export class RoutesEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    private readonly httpService = inject(HttpService);
    private readonly guiFacade = inject(GuiFacade);
    private readonly planningProcessingFacade = inject(PlanningProcessingFacade);

    takeUntil$ = this.actions$.pipe(ofType(queryActions.set, queryActions.clear));

    queryChange$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(queryActions.set),
            filter((data) => data.query !== null),
            map(() => routesActions.getRoutes({ silent: false })),
        );
    });

    planningReload$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                // refresh
                refreshActions.event,
                // task
                taskPointActions.addTaskPointSuccess,
                taskPointActions.updateTaskPointSuccess,
                taskPointActions.deleteTaskPointSuccess,
                taskPointActions.addTaskPointError,
                taskPointActions.updateTaskPointError,
                taskPointActions.deleteTaskPointError,

                // transfer
                transferPointActions.addTransferPointSuccess,
                transferPointActions.updateTransferPointSuccess,
                transferPointActions.deleteTransferPointSuccess,
                transferPointActions.addTransferPointError,
                transferPointActions.updateTransferPointError,
                transferPointActions.deleteTransferPointError,

                // Draft
                draftPointActions.addDraftPointSuccess,
                draftPointActions.updateDraftPointSuccess,
                draftPointActions.deleteDraftPointSuccess,
                draftPointActions.addDraftPointError,
                draftPointActions.updateDraftPointError,
                draftPointActions.deleteDraftPointError,

                // filters
                filterActions.addFilterSuccess,
                filterActions.deleteFilterSuccess,
                filterActions.updateFilterSuccess,
                filterActions.attachFilterSuccess,
                filterActions.detachFilterSuccess,
                filterActions.addFilterError,
                filterActions.deleteFilterError,
                filterActions.updateFilterError,
                filterActions.attachFilterError,
                filterActions.detachFilterError,

                // routes
                routesDraftActions.updateRouteDraftSuccess,
                routesDraftActions.saveRouteDraftSuccess,
                routesDraftActions.updateRouteDraftError,
                routesDraftActions.saveRouteDraftError,
            ),
            map(() => routesActions.getRoutes({ silent: true })),
        );
    });

    queryPlanningChangeClean$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(queryActions.set),
            map(() => routesActions.clearStore({})),
        );
    });

    getRoutes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routesActions.getRoutes),
            concatLatestFrom(() => this.store.pipe(NonNullablePlanningQuery)),
            exhaustMapWithTrailing(([{ silent }, query]) => {
                silent ? this.planningProcessingFacade.add('routes') : this.guiFacade.showLoader('get-routes');
                return this.httpService.getRoutes(query).pipe(
                    map((response) => routesActions.getRoutesSuccess({ payload: response })),
                    catchError(() => of(routesActions.getRoutesError())),
                    finalize(() => {
                        silent ? this.planningProcessingFacade.del('routes') : this.guiFacade.hideLoader('get-routes');
                    }),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    addDraftId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routesDraftActions.setRouteDraftDriverAndVehicle),
            concatLatestFrom((action) => this.store.select(selectDraftPoint(action.pointId))),
            map(([action, draftPoint]) =>
                routesDraftActions.setRouteDraftDriverAndVehicleSuccess({
                    pointId: action.pointId,
                    driverId: draftPoint?.driverId ?? null,
                    vehicleId: draftPoint?.vehicleId ?? null,
                }),
            ),
        );
    });

    saveRouteDraft$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routesDraftActions.saveRouteDraft),
            concatLatestFrom(() => this.store.select(selectDraft).pipe(filter((route): route is RouteDraft => route !== null))),
            mergeMap(([, route]) => {
                return this.httpService.addRoute(route).pipe(
                    map((response) => routesDraftActions.saveRouteDraftSuccess({ payload: response })),
                    catchError((error: RouteError) => of(routesDraftActions.saveRouteDraftError({ payload: error }))),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    updateRoute$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routesDraftActions.updateRouteDraft),
            concatLatestFrom(() => this.store.select(selectDraft).pipe(filter((route): route is Route => route !== null))),
            mergeMap(([, route]) => {
                return this.httpService.updateRoute(route.id, route).pipe(
                    map((response) => {
                        if (response === null) {
                            return routesDraftActions.updateRouteDraftDeleteSuccess();
                        }
                        return routesDraftActions.updateRouteDraftSuccess({ payload: response });
                    }),
                    catchError((error: RouteError) => {
                        return of(routesDraftActions.updateRouteDraftError({ payload: error }));
                    }),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    toggleRouteDraftWaypoint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routesDraftActions.toggleRouteDraftWaypoint),
            concatLatestFrom(() => this.store.select(selectDraft)),
            filter(([, routeDraft]) => routeDraft !== null),
            map(([action, routeDraft]) => {
                const waypointIndex = routeDraft?.waypoints.findIndex((waypoint) => waypoint.pointId === action.pointId && waypoint.type === action.kind);
                if (waypointIndex === -1) {
                    return routesDraftActions.addRouteDraftWaypoint({
                        pointId: action.pointId,
                        kind: action.kind,
                    });
                }
                return routesDraftActions.removeRouteDraftWaypoint({
                    pointId: action.pointId,
                    kind: action.kind,
                });
            }),
        );
    });

    addRouteDraftWaypointDraft$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routesDraftActions.addRouteDraftWaypoint),
            filter((action) => action.kind === WAYPOINT_TYPES.DRAFT),
            concatLatestFrom((action) => this.store.select(selectDraftPoint(action.pointId))),
            map(([action, draftPoint]) =>
                routesDraftActions.setRouteDraftDriverAndVehicleSuccess({
                    pointId: action.pointId,
                    driverId: draftPoint?.driverId ?? null,
                    vehicleId: draftPoint?.vehicleId ?? null,
                }),
            ),
        );
    });

    addRouteDraftWaypointTransfer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routesDraftActions.addRouteDraftWaypoint),
            filter((action) => action.kind === WAYPOINT_TYPES.TRANSFER),
            concatLatestFrom((action) =>
                forkJoin([this.store.select(selectTransferPoint(action.pointId)).pipe(take(1)), this.store.pipe(NonNullablePlanningQuery).pipe(take(1))]),
            ),
            map(([action, [transferPoint, query]]) => {
                const day = Time.getDay(query.date);
                let diff = null;
                switch (day) {
                    case 0:
                        diff = transferPoint?.recurringDaysAndTime?.sun || null;
                        break;
                    case 1:
                        diff = transferPoint?.recurringDaysAndTime?.mon || null;
                        break;
                    case 2:
                        diff = transferPoint?.recurringDaysAndTime?.tue || null;
                        break;
                    case 3:
                        diff = transferPoint?.recurringDaysAndTime?.wed || null;
                        break;
                    case 4:
                        diff = transferPoint?.recurringDaysAndTime?.thu || null;
                        break;
                    case 5:
                        diff = transferPoint?.recurringDaysAndTime?.fri || null;
                        break;
                    case 6:
                        diff = transferPoint?.recurringDaysAndTime?.sat || null;
                        break;
                }
                if (diff !== null) {
                    const date = Time.stringToDate(query.date);
                    date.setSeconds(date.getSeconds() + diff);
                    return {
                        action: action,
                        date: date,
                    };
                }
                return {
                    action: action,
                    date: null,
                };
            }),
            filter((data) => data.date !== null),
            map((data) =>
                routesDraftActions.addRouteDraftWaypointTransferTime({
                    pointId: data.action.pointId,
                    kind: data.action.kind,
                    date: data.date,
                }),
            ),
        );
    });

    setRouteWaypointTimeRelative$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routesDraftActions.setRouteWaypointTimeRelative),
            concatLatestFrom(() => this.store.select(selectCalculation)),
            map(([action, calculation]) =>
                routesDraftActions.setRouteWaypointTimeRelativeSuccess({
                    pointId: action.pointId,
                    kind: action.kind,
                    direction: action.direction,
                    calculation: calculation,
                }),
            ),
        );
    });

    setRouteWaypointsLength$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routeCalculationActions.getRouteCalculationSuccessAction),
            map((data) =>
                routesDraftActions.setRouteWaypointsLengthSuccess({
                    payload: data.payload,
                }),
            ),
        );
    });

    activateNotificationsSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routesNotificationActions.activateSuccess),
            map(({ payload }) => routesActions.activateNotificationsSuccess({ payload })),
        );
    });

    deactivateNotificationsSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routesNotificationActions.deactivateSuccess),
            map(({ payload }) => routesActions.deactivateNotificationsSuccess({ payload })),
        );
    });
}
