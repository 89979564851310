import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { TaskPointModel, TaskPointValue, TaskPointDto } from '../models';
import { PointError } from '../../models/point.error';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getTaskPoint(id: string): Observable<TaskPointModel> {
        return this.httpClient
            .skipErrorHandler()
            .get<ApiResponse<TaskPointDto>>(`task/${id}`)
            .pipe(
                map((response) => new TaskPointModel(response.data)),
                catchError((response: HttpErrorResponse) => {
                    throw new PointError(response);
                }),
            );
    }

    addTaskPoint(value: TaskPointValue): Observable<null> {
        return this.httpClient
            .skipErrorHandler()
            .post<null>('task', new TaskPointValue(value))
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    throw new PointError(response);
                }),
            );
    }

    updateTaskPoint(id: string, value: TaskPointValue): Observable<null> {
        return this.httpClient
            .skipErrorHandler()
            .put<null>(`task/${id}`, new TaskPointValue(value))
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    throw new PointError(response);
                }),
            );
    }

    deleteTaskPoint(id: string): Observable<null> {
        return this.httpClient
            .skipErrorHandler()
            .delete<null>(`task/${id}`)
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    throw new PointError(response);
                }),
            );
    }
}
