import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PassengerModel, ParcelModel } from '../models';

export const bookingsActions = createActionGroup({
    source: 'Planning - Bookings',
    events: {
        'Get Bookings': props<{ silent: boolean }>(),
        'Get Bookings Success': props<{
            payload: {
                passengers: PassengerModel[];
                parcels: ParcelModel[];
            };
        }>(),
        'Get Bookings Error': emptyProps(),
        'Get Filters Error': emptyProps(),
        Clear: emptyProps(),
    },
});
