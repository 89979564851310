import { createReducer, on } from '@ngrx/store';
import { draftPointActions } from '../actions/draft-point.actions';
import { DraftPointModel } from '../models';
import { PointError } from '../../models/point.error';

export interface DraftPointState {
    draftPoint: DraftPointModel | null;
    loading: boolean | null;
    loaded: boolean | null;
    saving: boolean | null;
    saved: boolean | null;
    deleting: boolean | null;
    deleted: boolean | null;
    error: PointError | null;
}

export const initialState: DraftPointState = {
    draftPoint: null,
    loading: null,
    loaded: null,
    saving: null,
    saved: null,
    deleting: null,
    deleted: null,
    error: null,
};

export const draftPointReducer = createReducer(
    initialState,
    on(
        draftPointActions.getDraftPoint,
        (state): DraftPointState => ({
            ...state,
            loading: true,
            error: null,
        }),
    ),
    on(
        draftPointActions.getDraftPointSuccess,
        (state, action): DraftPointState => ({
            ...state,
            loading: false,
            loaded: true,
            draftPoint: action.payload,
        }),
    ),
    on(
        draftPointActions.getDraftPointError,
        (state, action): DraftPointState => ({
            ...state,
            loading: false,
            loaded: false,
            error: action.payload,
        }),
    ),
    on(
        draftPointActions.addDraftPoint,
        (state): DraftPointState => ({
            ...state,
            saving: true,
            error: null,
        }),
    ),
    on(
        draftPointActions.addDraftPointSuccess,
        (state): DraftPointState => ({
            ...state,
            saving: false,
            saved: true,
        }),
    ),
    on(
        draftPointActions.addDraftPointError,
        (state, action): DraftPointState => ({
            ...state,
            saving: false,
            saved: false,
            error: action.payload,
        }),
    ),
    on(
        draftPointActions.updateDraftPoint,
        (state): DraftPointState => ({
            ...state,
            saving: true,
            error: null,
        }),
    ),
    on(
        draftPointActions.updateDraftPointSuccess,
        (state): DraftPointState => ({
            ...state,
            saving: false,
            saved: true,
        }),
    ),
    on(
        draftPointActions.updateDraftPointError,
        (state, action): DraftPointState => ({
            ...state,
            saving: false,
            saved: false,
            error: action.payload,
        }),
    ),
    on(
        draftPointActions.deleteDraftPoint,
        (state): DraftPointState => ({
            ...state,
            deleting: true,
            error: null,
        }),
    ),
    on(
        draftPointActions.deleteDraftPointSuccess,
        (state): DraftPointState => ({
            ...state,
            deleting: false,
            deleted: true,
        }),
    ),
    on(
        draftPointActions.deleteDraftPointError,
        (state, action): DraftPointState => ({
            ...state,
            deleting: false,
            deleted: false,
            error: action.payload,
        }),
    ),
    on(
        draftPointActions.clear,
        (state): DraftPointState => ({
            ...state,
            ...initialState,
        }),
    ),
);
