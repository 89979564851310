import { Injectable } from '@angular/core';
import { filter } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectTransferPoints, selectLoaded, selectLoading, selectTransferPoint, selectTransferPointsByIds } from '../selectors/transfer-points.selector';
import { transferPointsActions } from '../actions/transfer-points.actions';
import { TransferPointModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class TransferPointsFacade {
    transferPoints$ = this.store.select(selectTransferPoints).pipe(filter((data): data is TransferPointModel[] => data !== null));
    loaded$ = this.store.select(selectLoaded).pipe(filter((data): data is boolean => data !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((data): data is boolean => data !== null));

    constructor(private store: Store) {}

    transfer$ = (id: string) => {
        return this.store.select(selectTransferPoint(id)).pipe(filter((data): data is TransferPointModel => data !== null));
    };

    transferPointsByIds$(ids: string[]) {
        return this.store.select(selectTransferPointsByIds(ids)).pipe(filter((data): data is TransferPointModel[] => data !== null));
    }

    clear(): void {
        this.store.dispatch(transferPointsActions.clear());
    }
}
