import { DRAFT_POINT_TYPES } from '../../enums';
import { GeoAddressModel } from '../../models';

export class DraftPointValue {
    type: DRAFT_POINT_TYPES;
    address: GeoAddressModel;
    date: string;
    planningType: number;
    driverId?: string;
    vehicleId?: string;

    constructor(data: DraftPointValue) {
        const { latitude, longitude } = data.address.geoPosition;

        this.type = data.type;
        this.date = data.date;
        this.address = {
            ...data.address,
            geoPosition: {
                latitude: latitude ? parseFloat(latitude.toFixed(6)) : latitude,
                longitude: longitude ? parseFloat(longitude.toFixed(6)) : longitude,
            },
        };
        this.planningType = data.planningType;
        if (data.driverId) {
            this.driverId = data.driverId;
        }
        if (data.vehicleId) {
            this.vehicleId = data.vehicleId;
        }
    }
}
