import { createReducer, on } from '@ngrx/store';
import { filterActions } from '../actions/filter.actions';
import { FilterError } from '../models';

export interface FilterState {
    loading: boolean | null;
    loaded: boolean | null;
    saving: boolean | null;
    saved: boolean | null;
    deleting: boolean | null;
    deleted: boolean | null;
    error: FilterError | null;
    attaching: boolean | null;
    attached: boolean | null;
    detaching: boolean | null;
    detached: boolean | null;
}

export const initialState: FilterState = {
    loading: null,
    loaded: null,
    saving: null,
    saved: null,
    deleting: null,
    deleted: null,
    error: null,
    attaching: null,
    attached: null,
    detaching: null,
    detached: null,
};

export const filterReducer = createReducer(
    initialState,
    on(
        filterActions.getFilter,
        (state): FilterState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        filterActions.getFilterSuccess,
        (state): FilterState => ({
            ...state,
            loading: false,
            loaded: true,
        }),
    ),
    on(
        filterActions.getFilterError,
        (state): FilterState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),

    on(
        filterActions.addFilter,
        (state): FilterState => ({
            ...state,
            saved: false,
            saving: true,
        }),
    ),
    on(
        filterActions.addFilterSuccess,
        (state): FilterState => ({
            ...state,
            saving: false,
            saved: true,
        }),
    ),
    on(
        filterActions.addFilterError,
        (state): FilterState => ({
            ...state,
            saving: false,
            saved: false,
        }),
    ),

    on(
        filterActions.updateFilter,
        (state): FilterState => ({
            ...state,
            saved: false,
            saving: true,
        }),
    ),
    on(
        filterActions.updateFilterSuccess,
        (state): FilterState => ({
            ...state,
            saving: false,
            saved: true,
        }),
    ),
    on(
        filterActions.updateFilterError,
        (state, action): FilterState => ({
            ...state,
            saving: false,
            saved: false,
            error: action.payload,
        }),
    ),

    on(
        filterActions.deleteFilter,
        (state): FilterState => ({
            ...state,
            deleted: false,
            deleting: true,
        }),
    ),
    on(
        filterActions.deleteFilterSuccess,
        (state): FilterState => ({
            ...state,
            deleting: false,
            deleted: true,
        }),
    ),
    on(
        filterActions.deleteFilterError,
        (state, action): FilterState => ({
            ...state,
            deleting: false,
            deleted: false,
            error: action.payload,
        }),
    ),

    on(
        filterActions.attachFilter,
        (state): FilterState => ({
            ...state,
            attached: false,
            attaching: true,
        }),
    ),
    on(
        filterActions.attachFilterSuccess,
        (state): FilterState => ({
            ...state,
            attached: true,
            attaching: false,
        }),
    ),
    on(
        filterActions.attachFilterError,
        (state, action): FilterState => ({
            ...state,
            attached: false,
            attaching: false,
            error: action.payload,
        }),
    ),

    on(
        filterActions.detachFilter,
        (state): FilterState => ({
            ...state,
            detached: false,
            detaching: true,
        }),
    ),
    on(
        filterActions.detachFilterSuccess,
        (state): FilterState => ({
            ...state,
            detached: true,
            detaching: false,
        }),
    ),
    on(
        filterActions.detachFilterError,
        (state, action): FilterState => ({
            ...state,
            detached: false,
            detaching: false,
            error: action.payload,
        }),
    ),
    on(
        filterActions.clear,
        (state): FilterState => ({
            ...state,
            ...initialState,
        }),
    ),
);
