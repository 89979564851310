import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { QUERY_KEY } from './query';
import { queryReducer } from './query/reducers/query.reducer';

import { DRAFT_POINT_KEY } from './draft-point';
import { draftPointReducer } from './draft-point/reducers/draft-point.reducer';
import { DraftPointEffects } from './draft-point/effects/draft-point.effect';

import { DRAFT_POINTS_KEY } from './draft-points';
import { draftPointsReducer } from './draft-points/reducers/draft-points.reducer';
import { DraftPointsEffects } from './draft-points/effects/draft-points.effect';

import { TASK_POINT_KEY } from './task-point';
import { taskPointReducer } from './task-point/reducers/task-point.reducer';
import { TaskPointEffects } from './task-point/effects/task-point.effect';

import { TASK_POINTS_KEY } from './task-points';
import { taskPointsReducer } from './task-points/reducers/task-points.reducer';
import { TaskPointsEffects } from './task-points/effects/task-points.effect';

import { TRANSFER_POINT_KEY } from './transfer-point';
import { transferPointReducer } from './transfer-point/reducers/transfer-point.reducer';
import { TransferPointEffects } from './transfer-point/effects/transfer-point.effect';

import { TRANSFER_POINTS_KEY } from './transfer-points';
import { transferPointsReducer } from './transfer-points/reducers/transfer-points.reducer';
import { TransferPointsEffects } from './transfer-points/effects/transfer-points.effect';

import { TRANSFER_POINTS_PRESET_KEY } from './transfer-points-preset';
import { transferPointsPresetReducer } from './transfer-points-preset/reducers/transfer-points-preset.reducer';
import { TransferPointsPresetEffects } from './transfer-points-preset/effects/transfer-points-preset.effect';

import { BOOKINGS_KEY } from './bookings';
import { bookingsReducer } from './bookings/reducers/bookings.reducer';
import { BookingsEffects } from './bookings/effects/bookings.effect';

import { DRIVERS_KEY } from './drivers';
import { driversReducer } from './drivers/reducers/drivers.reducer';
import { DriversEffects } from './drivers/effects/drivers.effect';

import { VEHICLES_KEY } from './vehicles';
import { vehilcesReducer } from './vehicles/reducers/vehicles.reducer';
import { VehiclesEffects } from './vehicles/effects/vehicles.effect';

import { ROUTES_KEY } from './routes';
import { routesReducer } from './routes/reducers/routes.reducer';
import { RoutesEffects } from './routes/effects/routes.effect';

import { ROUTES_NOTIFICATIONS_KEY } from './routes';
import { routesNotificationsReducer } from './routes/reducers/routes-notification.reducer';
import { RoutesNotificationsEffects } from './routes/effects/routes-notification.effect';

import { ROUTES_MONITOR_KEY } from './routes-monitor';
import { routesMonitorReducer } from './routes-monitor/reducers/routes-monitor.reducer';
import { RoutesMonitorEffects } from './routes-monitor/effects/routes-monitor.effect';

import { FILTERS_KEY } from './filters';
import { filtersReducer } from './filters/reducers/filters.reducer';
import { FiltersEffects } from './filters/effects/filters.effect';

import { FILTER_KEY } from './filter';
import { filterReducer } from './filter/reducers/filter.reducer';
import { FilterEffects } from './filter/effects/filter.effect';

import { SELECT_ITEMS_KEY } from './select-items';
import { selectItemsReducer } from './select-items/reducers/select-items.reducer';

import { SELECT_ROUTES_KEY } from './select-routes';
import { selectRoutesReducer } from './select-routes/reducers/select-routes.reducer';
import { SelectRoutesEffects } from './select-routes/effects/select-routes.effect';

import { ROUTE_CALCULATION_KEY } from './route-calculation';
import { routeCalculationReducer } from './route-calculation/reducers/route-calculation.reducer';
import { RouteCalculationEffects } from './route-calculation/effects/route-calculation.effect';

import { PARTNERS_FEATURE_KEY } from './partners';
import { partnersReducer } from './partners/reducers/partners.reducer';
import { PartnersEffects } from './partners/effects/partners.effect';

import { STAGE_KEY } from './stage';
import { stageReducer } from './stage/reducers/stage.reducer';

import { PROCESSING_KEY } from './processing';
import { processingReducer } from './processing/reducers/processing.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(QUERY_KEY, queryReducer),
        StoreModule.forFeature(DRAFT_POINT_KEY, draftPointReducer),
        StoreModule.forFeature(DRAFT_POINTS_KEY, draftPointsReducer),
        StoreModule.forFeature(TASK_POINT_KEY, taskPointReducer),
        StoreModule.forFeature(TASK_POINTS_KEY, taskPointsReducer),
        StoreModule.forFeature(TRANSFER_POINT_KEY, transferPointReducer),
        StoreModule.forFeature(TRANSFER_POINTS_KEY, transferPointsReducer),
        StoreModule.forFeature(TRANSFER_POINTS_PRESET_KEY, transferPointsPresetReducer),
        StoreModule.forFeature(BOOKINGS_KEY, bookingsReducer),
        StoreModule.forFeature(DRIVERS_KEY, driversReducer),
        StoreModule.forFeature(VEHICLES_KEY, vehilcesReducer),
        StoreModule.forFeature(ROUTES_KEY, routesReducer),
        StoreModule.forFeature(ROUTES_NOTIFICATIONS_KEY, routesNotificationsReducer),
        StoreModule.forFeature(ROUTES_MONITOR_KEY, routesMonitorReducer),
        StoreModule.forFeature(FILTERS_KEY, filtersReducer),
        StoreModule.forFeature(FILTER_KEY, filterReducer),
        StoreModule.forFeature(SELECT_ITEMS_KEY, selectItemsReducer),
        StoreModule.forFeature(SELECT_ROUTES_KEY, selectRoutesReducer),
        StoreModule.forFeature(ROUTE_CALCULATION_KEY, routeCalculationReducer),
        StoreModule.forFeature(PARTNERS_FEATURE_KEY, partnersReducer),
        StoreModule.forFeature(STAGE_KEY, stageReducer),
        StoreModule.forFeature(PROCESSING_KEY, processingReducer),
        EffectsModule.forFeature([
            DraftPointEffects,
            DraftPointsEffects,
            TaskPointEffects,
            TaskPointsEffects,
            TransferPointEffects,
            TransferPointsEffects,
            TransferPointsPresetEffects,
            BookingsEffects,
            DriversEffects,
            VehiclesEffects,
            RoutesEffects,
            RoutesNotificationsEffects,
            RoutesMonitorEffects,
            FiltersEffects,
            FilterEffects,
            SelectRoutesEffects,
            RouteCalculationEffects,
            PartnersEffects,
        ]),
    ],
})
export class PlanningStoreModule {}
