import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { FilterError, FilterValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    addFilter(value: FilterValue): Observable<unknown> {
        return this.httpClient.post<ApiResponse<unknown>>('filter', value);
    }

    updateFilter(id: string, value: FilterValue): Observable<unknown> {
        return this.httpClient.skipErrorHandler().put<ApiResponse<unknown>>(`filter/${id}`, value);
    }

    deleteFilter(id: string): Observable<unknown> {
        return this.httpClient
            .skipErrorHandler()
            .delete<ApiResponse<unknown>>(`filter/${id}`)
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    throw new FilterError(response);
                }),
            );
    }

    attachToFilter(id: string, bookingIds: string[]): Observable<unknown> {
        return this.httpClient
            .skipErrorHandler()
            .put<ApiResponse<unknown>>(`filter/${id}/bookings`, { bookingIds })
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    throw new FilterError(response);
                }),
            );
    }

    detach(bookingIds: string[]): Observable<unknown> {
        return this.httpClient
            .skipErrorHandler()
            .post<ApiResponse<unknown>>('filter/bookings/detach', { bookingIds })
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    throw new FilterError(response);
                }),
            );
    }
}
