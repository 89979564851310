import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { TransferPointDto, TransferPointModel } from '../models';
import { PlanningQuery } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getTransferPoints(query: PlanningQuery): Observable<TransferPointModel[]> {
        const params = {
            'planningType[eq]': query.type,
            'date[eq]': query.date,
        };
        return this.httpClient
            .get<ApiResponse<TransferPointDto[]>>(`transfer-points`, { params: new HttpParams({ fromObject: params }) })
            .pipe(map((response) => response.data.map((item) => new TransferPointModel(item)).filter((item) => item.predefined === true)));
    }
}
