import { createReducer, on } from '@ngrx/store';
import { bookingsActions } from '../actions/bookings.actions';
import { ParcelModel, PassengerModel } from '../models';

export interface BookingsState {
    parcels: ParcelModel[] | null;
    passengers: PassengerModel[] | null;
    total: number | null;
    loading: boolean | null;
    loaded: boolean | null;
}

export const initialState: BookingsState = {
    parcels: null,
    passengers: null,
    total: null,
    loading: null,
    loaded: null,
};

export const bookingsReducer = createReducer(
    initialState,
    on(
        bookingsActions.getBookings,
        (state): BookingsState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        bookingsActions.getBookingsSuccess,
        (state, action): BookingsState => ({
            ...state,
            parcels: action.payload.parcels,
            passengers: action.payload.passengers,
            loaded: true,
            loading: false,
        }),
    ),
    on(
        bookingsActions.getBookingsError,
        (state): BookingsState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),
    on(
        bookingsActions.clear,
        (state): BookingsState => ({
            ...state,
            ...initialState,
        }),
    ),
);
