import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';
import { GeoPositionModel } from '../../models';
import { RouteCalculationModel, RouteCalculationResponseDto } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getRouteCalculation(value: GeoPositionModel[]): Observable<{ calculation: RouteCalculationModel; raw: unknown }> {
        const via = [...value];
        const start = via.shift();
        const end = via.pop();

        const params = new HttpParams().appendAll({
            transportMode: 'car',
            'avoid[features]': 'ferry,carShuttleTrain',
            routingMode: 'fast',
            origin: start ? `${start.latitude},${start.longitude}` : '',
            destination: end ? `${end.latitude},${end.longitude}` : '',
            via: via.map((v) => `${v.latitude},${v.longitude}`),
            return: 'summary,typicalDuration,polyline,actions,instructions,turnByTurnActions',
            spans: 'names,length,duration,baseDuration,typicalDuration',
            apikey: environment.integrations.hereMaps.apiKey,
        });

        return this.httpClient
            .addHerempasRoutePrefix()
            .get<RouteCalculationResponseDto>(`https://router.hereapi.com/v8/routes`, { params })
            .pipe(
                map((response) => ({
                    calculation: new RouteCalculationModel(response),
                    raw: response.routes[0],
                })),
            );
    }
}
