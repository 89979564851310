import { Injectable, inject } from '@angular/core';
import { of, catchError, map, mergeMap, takeUntil, repeat, filter, finalize } from 'rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { GuiFacade } from '@app/gui';
import { queryActions } from '../../query/actions/query.actions';
import { HttpService } from '../services/http.service';
import { partnersActions } from '../actions/partners.actions';
import { Store } from '@ngrx/store';
import { NonNullablePlanningQuery } from '../../query/selectors/query.selector';

@Injectable()
export class PartnersEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly guiFacade = inject(GuiFacade);
    private readonly store = inject(Store);

    takeUntil$ = this.actions$.pipe(ofType(queryActions.set, queryActions.clear));

    queryPlanningChangeGet$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(queryActions.set),
            filter((data) => data.query !== null),
            map(() => partnersActions.getPartnersAction()),
        );
    });

    queryPlanningChangeClean$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(queryActions.set),
            map(() => partnersActions.clearAction()),
        );
    });

    getPartners$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(partnersActions.getPartnersAction),
            concatLatestFrom(() => this.store.pipe(NonNullablePlanningQuery)),
            mergeMap(([, query]) => {
                this.guiFacade.showLoader('get-planning-partners');
                return this.httpService.getPartners(query).pipe(
                    map((response) => partnersActions.getPartnersSuccessAction({ payload: response })),
                    catchError(() => of(partnersActions.getPartnersErrorAction())),
                    finalize(() => this.guiFacade.hideLoader('get-planning-partners')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });
}
