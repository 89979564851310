import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoutesState } from '../reducers/routes.reducer';
import { ROUTES_KEY } from '../keys';
import { PendingWaypoints } from '../utils/pending-transfers.util';
import { PassingWaypoints } from '../utils/passing-transfers.util';
import { WAYPOINT_TYPES } from '../../enums';

const selectState = createFeatureSelector<RoutesState>(ROUTES_KEY);

/** Draft */
export const selectDraft = createSelector(selectState, (state) => state.draft.route ?? null);
export const selectDraftWaypoints = createSelector(selectState, (state) => state.draft.route?.waypoints ?? null);
export const selectDraftWaypoint = (pointId: string, type: WAYPOINT_TYPES) =>
    createSelector(selectState, (state) => state.draft.route?.waypoints.find((waypoint) => waypoint.pointId === pointId && waypoint.type === type) ?? null);
export const selectDraftDriverId = createSelector(selectState, (state) => state.draft.route?.driverId ?? null);
export const selectDraftAdditionalDriversIds = createSelector(selectState, (state) => state.draft.route?.additionalDriversIds ?? []);
export const selectDraftVehicleId = createSelector(selectState, (state) => state.draft.route?.vehicleId ?? null);
export const selectDraftEditedTransferPointId = createSelector(selectState, (state) => state.draft.transferPointEdit ?? null);
export const selectDraftEditedWaypoint = createSelector(
    selectState,
    (state) => state.draft.route?.waypoints.find((waypoint) => waypoint.pointId === state.draft.transferPointEdit) ?? null,
);

export const selectDraftWaypointTransfersIn = (pointIds: string[]) =>
    createSelector(selectState, (state) =>
        pointIds.map((pointId) => state.draft.route?.waypoints.find((waypoint) => waypoint.pointId === pointId)?.transfersIn ?? []).flat(),
    );

export const selectDraftWaypointTransfersOut = (pointIds: string[]) =>
    createSelector(selectState, (state) =>
        pointIds.map((pointId) => state.draft.route?.waypoints.find((waypoint) => waypoint.pointId === pointId)?.transfersOut ?? []).flat(),
    );

export const selectDraftWaypointTransfersPending = (pointIds: string[]) =>
    createSelector(selectState, (state) => {
        const routesWithDraft = state.routes.map((route) => (route.id === state.draft.route?.id ? state.draft.route : route));

        if (state.draft.route !== null) {
            const draftRouteId = state.draft.route.id;
            return pointIds.map((pointId) => PendingWaypoints(routesWithDraft, draftRouteId, pointId)).flat();
        }

        return [];
    });

export const selectDraftWaypointTransfersPassing = (pointIds: string[]) =>
    createSelector(selectState, (state) => {
        if (state.draft.route !== null) {
            const draftRoute = state.draft.route;
            return pointIds.map((pointId) => PassingWaypoints(draftRoute, pointId)).flat();
        }
        return [];
    });

/**
 * Get Time between start and end
 */
export const selectDraftTotalTime = createSelector(selectState, (state) => {
    const times = state.draft.route?.waypoints.map((waypoint) => waypoint.time) || [];
    if (times?.length < 2) {
        return null;
    }
    const start = times[0];
    const end = times[times.length - 1];
    if (start === null || end === null) {
        return null;
    }
    const msec = (end.getTime() - start.getTime()) / 1000;
    if (msec < 0) {
        return null;
    }
    const hours = Math.floor(msec / 3600);
    const minutes = Math.floor((msec % 3600) / 60);
    return `${hours}h ${minutes}m`;
});

export const selectDraftLength = createSelector(selectState, (state) => {
    const times = state.draft.route?.waypoints.map((waypoint) => waypoint.length) || [];
    if (times.includes(null)) {
        return null;
    }
    return `${Math.floor((times.reduce((a, b) => <number>a + <number>b, 0) || 0) / 1000)}km`;
});

export const selectDraftError = createSelector(selectState, (state) => state.draft.error ?? null);
