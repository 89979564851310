import { Injectable, inject } from '@angular/core';
import { filter } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    selectTransferPoints,
    selectLoaded,
    selectLoading,
    selectTransferPoint,
    selectTransferPointsByIds,
} from '../selectors/transfer-points-preset.selector';
import { transferPointsPresetActions } from '../actions/transfer-points-preset.actions';
import { TransferPointModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class TransferPointsPresetFacade {
    private store = inject(Store);

    transferPoints$ = this.store.select(selectTransferPoints).pipe(filter((data): data is TransferPointModel[] => data !== null));
    loaded$ = this.store.select(selectLoaded).pipe(filter((data): data is boolean => data !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((data): data is boolean => data !== null));

    transfer$ = (id: string) => this.store.select(selectTransferPoint(id)).pipe(filter((data): data is TransferPointModel => data !== null));

    transferPointsByIds$ = (ids: string[]) =>
        this.store.select(selectTransferPointsByIds(ids)).pipe(filter((data): data is TransferPointModel[] => data !== null));

    clear(): void {
        this.store.dispatch(transferPointsPresetActions.clear());
    }
}
