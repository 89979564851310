import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, mergeMap, takeUntil, repeat, finalize } from 'rxjs';
import { draftPointActions } from '../actions/draft-point.actions';
import { GuiFacade } from '@app/gui';
import { HttpService } from '../services/http.service';
import { queryActions } from '../../query/actions/query.actions';

@Injectable()
export class DraftPointEffects {
    private actions$ = inject(Actions);
    private httpService = inject(HttpService);
    private guiFacade = inject(GuiFacade);

    takeUntil$ = this.actions$.pipe(ofType(queryActions.set, queryActions.clear));

    getDraftPoint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(draftPointActions.getDraftPoint),
            mergeMap((action) => {
                this.guiFacade.showLoader('get-draft-point');
                return this.httpService.getDraftPoint(action.id).pipe(
                    map((data) => draftPointActions.getDraftPointSuccess({ payload: data })),
                    catchError((error) => of(draftPointActions.getDraftPointError({ payload: error }))),
                    finalize(() => this.guiFacade.hideLoader('get-draft-point')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    addDraftPoint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(draftPointActions.addDraftPoint),
            mergeMap((action) => {
                this.guiFacade.showLoader('add-draft-point');
                return this.httpService.addDraftPoint(action.value).pipe(
                    map(() => draftPointActions.addDraftPointSuccess()),
                    catchError((error) => of(draftPointActions.addDraftPointError({ payload: error }))),
                    finalize(() => this.guiFacade.hideLoader('add-draft-point')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    updateDraftPoint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(draftPointActions.updateDraftPoint),
            mergeMap((action) => {
                this.guiFacade.showLoader('update-draft-point');
                return this.httpService.updateDraftPoint(action.id, action.value).pipe(
                    map(() => draftPointActions.updateDraftPointSuccess()),
                    catchError((error) => of(draftPointActions.updateDraftPointError({ payload: error }))),
                    finalize(() => this.guiFacade.hideLoader('update-draft-point')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    deleteDraftPoint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(draftPointActions.deleteDraftPoint),
            mergeMap((action) => {
                this.guiFacade.showLoader('delete-draft-point');
                return this.httpService.deleteDraftPoint(action.id).pipe(
                    map(() => draftPointActions.deleteDraftPointSuccess()),
                    catchError((error) => of(draftPointActions.deleteDraftPointError({ payload: error }))),
                    finalize(() => this.guiFacade.hideLoader('delete-draft-point')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });
}
