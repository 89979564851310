import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Route, RouteTag } from '../models';
import { PlanningQuery } from '../../models';
import { WAYPOINT_TYPES } from '../../enums';
import { RouteCalculationModel } from '../../route-calculation/models';
import { RouteError } from '../models/route.error';

export const routesDraftActions = createActionGroup({
    source: 'Planning - Route Draft',
    events: {
        'Add New Draft': props<{ query: PlanningQuery }>(),
        'Set Route Draft': props<{ routeId: string }>(),
        'Reload Route Draft': emptyProps(),

        'Add Route Draft Waypoint': props<{ pointId: string; kind: WAYPOINT_TYPES }>(),
        'Remove Route Draft Waypoint': props<{ pointId: string; kind: WAYPOINT_TYPES }>(),
        'Toggle Route Draft Waypoint': props<{ pointId: string; kind: WAYPOINT_TYPES }>(),

        'Add Route Draft Waypoint TransferTime': props<{ pointId: string; kind: WAYPOINT_TYPES; date: Date | null }>(),
        'Toggle Route Draft Waypoint Transfers Out': props<{ pointId: string }>(),
        'Toggle Route Draft Waypoint Transfers In': props<{ pointId: string }>(),
        'Move Route Draft Waypoint': props<{ fromIndex: number; toIndex: number }>(),

        'Set Route Draft Driver And Vehicle': props<{ pointId: string }>(),
        'Set Route Draft Driver And Vehicle Success': props<{ pointId: string; driverId: string | null; vehicleId: string | null }>(),
        'Set Route Draft Driver Id': props<{ driverId: string | null }>(),
        'Set Route Draft Additinal Drivers Ids': props<{ driversIds: (string | null)[] }>(),
        'Set Route Draft Vehicle Id': props<{ vehicleId: string | null }>(),
        'Set Route Draft Tags': props<{ tags: RouteTag[] }>(),

        'Set Route Waypoint Time': props<{ pointId: string; kind: WAYPOINT_TYPES; time: Date | null }>(),
        'Set Route Waypoint Time Relative': props<{ pointId: string; kind: WAYPOINT_TYPES; direction: 'up' | 'down' }>(),
        'Set Route Waypoints Length Success': props<{ payload: { calculation: RouteCalculationModel; raw: unknown } }>(),
        'Set Route Waypoint Time Relative Success': props<{
            pointId: string;
            kind: WAYPOINT_TYPES;
            direction: 'up' | 'down';
            calculation: RouteCalculationModel | null;
        }>(),
        'Select Route Draft Transfer Point Edit': props<{ pointId: string }>(),

        'Move Route Draft Point Transfer Toggle': props<{ pointId: string }>(),
        'Move Route Draft Point Transfer Out': props<{ pointId: string }>(),
        'Move Route Draft Point Transfer In': props<{ pointId: string }>(),

        // Save
        'Save Route Draft': emptyProps(),
        'Save Route Draft Success': props<{
            payload: {
                updated: Route[];
                deletedIds: string[];
            };
        }>(),
        'Save Route Draft Error': props<{ payload: RouteError }>(),

        // Update
        'Update Route Draft': emptyProps(),
        'Update Route Draft Success': props<{
            payload: {
                updated: Route[];
                deletedIds: string[];
            };
        }>(),
        'Update Route Draft Error': props<{ payload: RouteError }>(),

        // On Update delete route
        'Update Route Draft Delete Success': emptyProps(),
    },
});
