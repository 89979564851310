import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs';
import { PlanningQuery } from '../../models';
import { queryActions } from '../actions/query.actions';
import { QueryModel } from '../models';
import { selectPlanningQuery } from '../selectors/query.selector';

@Injectable({
    providedIn: 'root',
})
export class PlanningQueryFacade {
    private store = inject(Store);

    query$ = this.store.select(selectPlanningQuery);

    setQuery(query: QueryModel): void {
        this.store.dispatch(queryActions.set({ query }));
    }

    clear(): void {
        this.store.dispatch(queryActions.clear());
    }

    get query(): PlanningQuery | null {
        let value: PlanningQuery | null = null;
        this.store
            .select(selectPlanningQuery)
            .pipe(
                take(1),
                tap((data) => {
                    if (data.date !== null && data.type !== null) {
                        value = {
                            date: data.date,
                            type: data.type,
                        };
                    }
                }),
            )
            .subscribe();
        return value;
    }
}
