import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs';
import { taskPointActions } from '../actions/task-point.actions';
import { TaskPointModel, TaskPointValue } from '../models';
import {
    selectTaskPoint,
    selectLoaded,
    selectLoading,
    selectSaved,
    selectSaving,
    selectDeleted,
    selectDeleting,
    selectError,
} from '../selectors/task-point.selector';

@Injectable({
    providedIn: 'root',
})
export class TaskPointFacade {
    taskPoint$ = this.store.select(selectTaskPoint).pipe(filter((data): data is TaskPointModel => data !== null));
    loaded$ = this.store.select(selectLoaded).pipe(filter((data): data is boolean => data !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((data): data is boolean => data !== null));
    saved$ = this.store.select(selectSaved).pipe(filter((data): data is boolean => data !== null));
    saving$ = this.store.select(selectSaving).pipe(filter((data): data is boolean => data !== null));
    deleted$ = this.store.select(selectDeleted).pipe(filter((data): data is boolean => data !== null));
    deleting$ = this.store.select(selectDeleting).pipe(filter((data): data is boolean => data !== null));
    error$ = this.store.select(selectError);

    constructor(private store: Store) {}

    get saving() {
        let saving = false;
        this.store
            .select(selectSaving)
            .pipe(
                take(1),
                tap((value) => (saving = value === true)),
            )
            .subscribe();
        return saving;
    }

    getTaskPoint(id: string): void {
        this.store.dispatch(taskPointActions.getTaskPoint({ id }));
    }

    addTaskPoint(value: TaskPointValue): void {
        this.store.dispatch(taskPointActions.addTaskPoint({ value }));
    }

    updateTaskPoint(id: string, value: TaskPointValue): void {
        this.store.dispatch(taskPointActions.updateTaskPoint({ id, value }));
    }

    deleteTaskPoint(id: string): void {
        this.store.dispatch(taskPointActions.deleteTaskPoint({ id }));
    }

    clear(): void {
        this.store.dispatch(taskPointActions.clear());
    }
}
