import { Injectable } from '@angular/core';
import { debounceTime, fromEvent, map, repeat, takeUntil, tap } from 'rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routesMonitorActions } from '../actions/routes-monitor.actions';
import { routesActions } from '../../routes/actions/routes.actions';
import { selectRoutesWithDraft, selectRoutes } from '../../routes/selectors/routes.selectors';
import { Store } from '@ngrx/store';
import { RouteErrorModel } from '../models';
import { RouteTracesModel } from '../models/route-trace.model';
import { RouteDraft, Route } from '../../routes/models';
import { routesDraftActions } from '../../routes/actions/routes-draft.actions';
import { queryActions } from '../../query/actions/query.actions';

@Injectable()
export class RoutesMonitorEffects {
    worker = new Worker(new URL('../workers/check.worker', import.meta.url));
    draftWorker = new Worker(new URL('../workers/check.worker', import.meta.url));

    takeUntil$ = this.actions$.pipe(ofType(queryActions.set, queryActions.clear));

    checkRoutesMonitor$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(routesActions.getRoutesSuccess),
                debounceTime(25),
                concatLatestFrom(() => this.store.select(selectRoutes)),
                tap(([, routes]) => this.worker.postMessage({ routes: routes || [] })),
            );
        },
        { dispatch: false },
    );

    checkRoutesMonitorCallback$ = createEffect(() => {
        return fromEvent<MessageEvent<{ errors: RouteErrorModel[]; traces: RouteTracesModel[] }>>(this.worker, 'message').pipe(
            map((event) => routesMonitorActions.checkRoutesCallbackAction({ errors: event.data.errors, traces: event.data.traces })),
        );
    });

    checkRoutesDraftMonitor$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    routesDraftActions.addNewDraft,
                    routesDraftActions.setRouteDraft,
                    routesDraftActions.addRouteDraftWaypoint,
                    routesDraftActions.removeRouteDraftWaypoint,
                    routesDraftActions.toggleRouteDraftWaypoint,
                    routesDraftActions.toggleRouteDraftWaypointTransfersIn,
                    routesDraftActions.toggleRouteDraftWaypointTransfersOut,
                    routesDraftActions.moveRouteDraftPointTransferToggle,
                    routesDraftActions.moveRouteDraftPointTransferOut,
                    routesDraftActions.moveRouteDraftPointTransferIn,
                    routesDraftActions.setRouteDraftDriverAndVehicleSuccess,
                    routesDraftActions.setRouteDraftDriverId,
                    routesDraftActions.setRouteDraftVehicleId,
                    routesDraftActions.setRouteDraftTags,
                    routesDraftActions.setRouteWaypointTime,
                    routesDraftActions.setRouteWaypointTimeRelative,
                    routesDraftActions.setRouteWaypointTimeRelativeSuccess,
                ),
                debounceTime(50),
                concatLatestFrom(() => this.store.select(selectRoutesWithDraft)),
                tap(([, data]) => {
                    const routes: (Route | RouteDraft)[] = [...data.routes];
                    const draft = data.draft;
                    if (draft) {
                        if (draft.id === null) {
                            routes.push(draft);
                        } else {
                            const index = routes.findIndex((route) => route.id === draft.id);
                            if (index !== -1) {
                                routes.splice(index, 1, draft);
                            }
                        }
                        this.draftWorker.postMessage({ routes: routes || [] });
                    }
                }),
            );
        },
        { dispatch: false },
    );

    checkRoutesDraftMonitorCallback$ = createEffect(() => {
        return fromEvent<MessageEvent<{ errors: RouteErrorModel[]; traces: RouteTracesModel[] }>>(this.draftWorker, 'message').pipe(
            map((event) => routesMonitorActions.checkRoutesDraftCallbackAction({ errors: event.data.errors })),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    constructor(
        private actions$: Actions,
        private store: Store,
    ) {}
}
