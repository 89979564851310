import { Injectable, inject } from '@angular/core';
import { of, catchError, map, mergeMap, finalize } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GuiFacade } from '@app/gui';
import { HttpService } from '../services/http.service';
import { vehiclesActions } from '../actions/vehicles.actions';

@Injectable()
export class VehiclesEffects {
    private actions$ = inject(Actions);
    private httpService = inject(HttpService);
    private guiFacade = inject(GuiFacade);

    getDrivers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehiclesActions.getVehicles),
            mergeMap(() => {
                this.guiFacade.showLoader('get-planning-vehicles');
                return this.httpService.getVehicles().pipe(
                    map((response) => vehiclesActions.getVehiclesSuccess({ payload: response })),
                    catchError(() => of(vehiclesActions.getVehiclesError())),
                    finalize(() => this.guiFacade.hideLoader('get-planning-vehicles')),
                );
            }),
        );
    });
}
