import { createReducer, on } from '@ngrx/store';
import { routesMonitorActions } from '../actions/routes-monitor.actions';
import { RouteErrorModel, RouteTracesModel } from '../models';

export interface RoutesMonitorState {
    errors: RouteErrorModel[];
    errorsDraft: RouteErrorModel[];
    traces: RouteTracesModel[];
}

export const initialState: RoutesMonitorState = {
    errors: [],
    errorsDraft: [],
    traces: [],
};

export const routesMonitorReducer = createReducer(
    initialState,
    on(
        routesMonitorActions.checkRoutesCallbackAction,
        (state, action): RoutesMonitorState => ({
            ...state,
            errors: action.errors,
            traces: action.traces,
        }),
    ),
    on(
        routesMonitorActions.checkRoutesDraftCallbackAction,
        (state, action): RoutesMonitorState => ({
            ...state,
            errorsDraft: action.errors,
        }),
    ),
    on(
        routesMonitorActions.clearDraftAction,
        (state): RoutesMonitorState => ({
            ...state,
            errorsDraft: initialState.errorsDraft,
        }),
    ),
    on(
        routesMonitorActions.clearAction,
        (state): RoutesMonitorState => ({
            ...state,
            ...initialState,
        }),
    ),
);
