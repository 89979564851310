import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DraftPointState } from '../reducers/draft-point.reducer';
import { DRAFT_POINT_KEY } from '../keys';

const selectState = createFeatureSelector<DraftPointState>(DRAFT_POINT_KEY);
export const selectDraftPoint = createSelector(selectState, (state) => state.draftPoint);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);
export const selectSaving = createSelector(selectState, (state) => state.saving);
export const selectSaved = createSelector(selectState, (state) => state.saved);
export const selectDeleting = createSelector(selectState, (state) => state.deleting);
export const selectDeleted = createSelector(selectState, (state) => state.deleted);
export const selectError = createSelector(selectState, (state) => state.error);
