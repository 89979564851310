import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DraftPointModel } from '../models';

export const draftPointsActions = createActionGroup({
    source: 'Planning - Draft Points',
    events: {
        'Get Draft Points': props<{ silent: boolean }>(),
        'Get Draft Points Success': props<{ payload: DraftPointModel[] }>(),
        'Get Draft Points Error': emptyProps(),
        Clear: emptyProps(),
    },
});
