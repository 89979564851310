import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { routesMonitorActions } from '../actions/routes-monitor.actions';
import {
    selectErrors,
    selectRouteDriverErrors,
    selectRouteVehicleErrors,
    selectRouteTransferErrors,
    selectRouteTimeErrors,
    selectRoutePointIdTimeErrors,
    selectRoutePointIdTransferErrors,
    selectDriverErrors,
    selectVehicleErrors,
    selectTransfersErrors,
    selectTimeErrors,
    selectWaypointsErrors,
    selectWaypointErrors,
    selectRouteWaypointErrors,
    selectDriversErrors,
    selectDriverWarnings,
    selectVehiclesErrors,
    selectVehicleWarnings,
    selectBookingRoutesTraces,
    selectRouteWaypointTransferErrors,
    selectTransferErrors,
} from '../selectors/routes-monitor.selector';

@Injectable({
    providedIn: 'root',
})
export class RoutesMonitorFacade {
    private readonly store = inject(Store);

    errors$ = this.store.select(selectErrors);

    /**
     * Global Errors
     */
    driversErrors$ = this.store.select(selectDriversErrors);
    vehiclesErrors$ = this.store.select(selectVehiclesErrors);
    transfersErrors$ = this.store.select(selectTransfersErrors);
    timeErrors$ = this.store.select(selectTimeErrors);

    /**
     * Route
     */

    driverErrors$ = (driverId: string) => {
        return this.store.select(selectDriverErrors(driverId));
    };

    driverWarnings$ = (driverId: string) => {
        return this.store.select(selectDriverWarnings(driverId));
    };

    routeDriverErrors$ = (driverId: string) => {
        return this.store.select(selectRouteDriverErrors(driverId));
    };

    vehicleErrors$ = (vehicleId: string) => {
        return this.store.select(selectVehicleErrors(vehicleId));
    };

    vehicleWarnings$ = (vehicleId: string) => {
        return this.store.select(selectVehicleWarnings(vehicleId));
    };

    routeVehicleErrors$ = (routeId: string | null) => {
        return this.store.select(selectRouteVehicleErrors(routeId));
    };

    routeTransferErrors$ = (routeId: string | null) => {
        return this.store.select(selectRouteTransferErrors(routeId));
    };

    routeTimeErrors$ = (routeId: string | null) => {
        return this.store.select(selectRouteTimeErrors(routeId));
    };

    waypointsErrors$ = (pointsIds: string[]) => {
        return this.store.select(selectWaypointsErrors(pointsIds));
    };

    routeWaypointErrors$ = (routeId: string | null, pointIds: string | string[]) => {
        return this.store.select(selectRouteWaypointErrors(routeId, pointIds));
    };

    routeWaypointTransferErrors$ = (routeId: string | null, transferPointId: string | null, pointIds: string | string[]) => {
        return this.store.select(selectRouteWaypointTransferErrors(routeId, transferPointId, pointIds));
    };

    waypointErrors$ = (pointId: string) => {
        return this.store.select(selectWaypointErrors(pointId));
    };

    /**
     * Transfer
     */
    transferErrors$ = (pointId: string) => {
        return this.store.select(selectTransferErrors(pointId));
    };

    /**
     * Route PointId
     */
    routePointIdTransferErrors$ = (pointId: string, routeId: string | null) => {
        return this.store.select(selectRoutePointIdTransferErrors(pointId, routeId));
    };

    routePointIdTimeErrors$ = (pointId: string, routeId: string | null) => {
        return this.store.select(selectRoutePointIdTimeErrors(pointId, routeId));
    };

    /**
     * Traces
     */
    getBookingTraces$ = (bookingId: string) => {
        return this.store.select(selectBookingRoutesTraces(bookingId));
    };

    clear() {
        this.store.dispatch(routesMonitorActions.clearAction());
    }
}
