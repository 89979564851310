import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DraftPointModel, DraftPointValue } from '../models';
import { PointError } from '../../models/point.error';

export const draftPointActions = createActionGroup({
    source: 'Planning - Draft Point',
    events: {
        'Get Draft Point': props<{ id: string }>(),
        'Get Draft Point Success': props<{ payload: DraftPointModel }>(),
        'Get Draft Point Error': props<{ payload: PointError }>(),
        'Add Draft Point': props<{ value: DraftPointValue }>(),
        'Add Draft Point Success': emptyProps(),
        'Add Draft Point Error': props<{ payload: PointError }>(),
        'Update Draft Point': props<{ id: string; value: DraftPointValue }>(),
        'Update Draft Point Success': emptyProps(),
        'Update Draft Point Error': props<{ payload: PointError }>(),
        'Delete Draft Point': props<{ id: string }>(),
        'Delete Draft Point Success': emptyProps(),
        'Delete Draft Point Error': props<{ payload: PointError }>(),
        Clear: emptyProps(),
    },
});
