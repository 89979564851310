import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoutesState } from '../reducers/routes.reducer';
import { ROUTES_KEY } from '../keys';
import { BOOKING_TYPES, WAYPOINT_TYPES } from '../../enums';

const selectState = createFeatureSelector<RoutesState>(ROUTES_KEY);

/**
 * Route
 */
export const selectRoute = (routeId: string | null) => createSelector(selectState, (state) => state.routes?.find((route) => route.id === routeId) ?? null);
export const selectRouteWaypoints = (routeId: string | null) =>
    createSelector(selectState, (state) => state.routes?.find((route) => route.id === routeId)?.waypoints ?? []);

export const selectRouteDriverId = (routeId: string | null) =>
    createSelector(selectState, (state) => state.routes?.find((route) => route.id === routeId)?.driverId ?? null);

export const selectRouteAdditionalDriversId = (routeId: string | null) =>
    createSelector(selectState, (state) => state.routes?.find((route) => route.id === routeId)?.additionalDriversIds ?? []);

export const selectRouteVehicleId = (routeId: string | null) =>
    createSelector(selectState, (state) => state.routes?.find((route) => route.id === routeId)?.vehicleId ?? null);

export const selectRouteMaxPassengers = (routeId: string | null) =>
    createSelector(selectState, (state) => {
        const route = state.routes?.find((route) => route.id === routeId);
        let max = 0;

        route?.waypoints.reduce((total, waypoint) => {
            switch (waypoint.type) {
                case WAYPOINT_TYPES.PASSENGER_START:
                    total = total + 1;
                    break;
                case WAYPOINT_TYPES.PASSENGER_STOP:
                    total = total - 1;
                    break;
                case WAYPOINT_TYPES.TRANSFER: {
                    const transfersInCount = (waypoint.transfersIn || []).filter((transferIn) => transferIn.bookingType === BOOKING_TYPES.PASSENGER).length;
                    const transfersOutCount = (waypoint.transfersOut || []).filter((transferOut) => transferOut.bookingType === BOOKING_TYPES.PASSENGER).length;
                    total = total + transfersInCount - transfersOutCount;
                    break;
                }
            }
            max = total > max ? total : max;
            return total;
        }, 0);

        return max;
    });
