import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { PartnerDto, PartnerModel } from '../models';
import { PlanningQuery } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getPartners(query: PlanningQuery): Observable<PartnerModel[]> {
        return this.httpClient
            .get<ApiResponse<PartnerDto[]>>(`partners/planning/${query.date}/${query.type}`)
            .pipe(map((response) => (response.data || []).map((item) => new PartnerModel(item))));
    }
}
