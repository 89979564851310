import { createAction, props } from '@ngrx/store';
import { RouteErrorModel, RouteTracesModel } from '../models';

enum RoutesMonitorActionTypes {
    CHECK_ROUTES_CALLBACK_ACTION = '[Planning - Routes Monitor] Check Routes Callback Action',
    CHECK_ROUTES_DRAFT_CALLBACK_ACTION = '[Planning - Routes Monitor] Check Routes Draft Callback Action',
    CLEAR_DRAFT_ACTION = '[Planning - Routes Flow Monitor] Clear Draft',
    CLEAR_ACTION = '[Planning - Routes Flow Monitor] Clear',
}

const checkRoutesCallbackAction = createAction(
    RoutesMonitorActionTypes.CHECK_ROUTES_CALLBACK_ACTION,
    props<{ traces: RouteTracesModel[]; errors: RouteErrorModel[] }>(),
);
const checkRoutesDraftCallbackAction = createAction(RoutesMonitorActionTypes.CHECK_ROUTES_DRAFT_CALLBACK_ACTION, props<{ errors: RouteErrorModel[] }>());
const clearDraftAction = createAction(RoutesMonitorActionTypes.CLEAR_DRAFT_ACTION);
const clearAction = createAction(RoutesMonitorActionTypes.CLEAR_ACTION);

export const routesMonitorActions = {
    checkRoutesCallbackAction,
    checkRoutesDraftCallbackAction,
    clearDraftAction,
    clearAction,
};
