import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filterActions } from '../actions/filter.actions';
import { FilterValue } from '../models';
import {
    selectLoaded,
    selectLoading,
    selectSaved,
    selectSaving,
    selectDeleted,
    selectDeleting,
    selectError,
    selectAttached,
    selectAttaching,
    selectDetached,
    selectDetaching,
} from '../selectors/filter.selector';

@Injectable({
    providedIn: 'root',
})
export class PlanningFilterFacade {
    loaded$ = this.store.select(selectLoaded);
    loading$ = this.store.select(selectLoading);
    saved$ = this.store.select(selectSaved);
    saving$ = this.store.select(selectSaving);
    deleted$ = this.store.select(selectDeleted);
    deleting$ = this.store.select(selectDeleting);
    attached$ = this.store.select(selectAttached);
    attaching$ = this.store.select(selectAttaching);
    detached$ = this.store.select(selectDetached);
    detaching$ = this.store.select(selectDetaching);
    error$ = this.store.select(selectError);

    constructor(private store: Store) {}

    addFilter(value: FilterValue): void {
        this.store.dispatch(filterActions.addFilter({ value }));
    }

    updateFilter(id: string, value: FilterValue): void {
        this.store.dispatch(filterActions.updateFilter({ id, value }));
    }

    deleteFilter(id: string): void {
        this.store.dispatch(filterActions.deleteFilter({ id }));
    }

    attachBookings(id: string, bookingIds: string[]): void {
        this.store.dispatch(filterActions.attachFilter({ id, bookingIds }));
    }

    detachBookings(bookingIds: string[]): void {
        this.store.dispatch(filterActions.detachFilter({ bookingIds }));
    }

    clear(): void {
        this.store.dispatch(filterActions.clear());
    }
}
