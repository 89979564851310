import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FilterModel } from '../../filters';
import { FilterError, FilterValue } from '../models';

export const filterActions = createActionGroup({
    source: 'Planning - Filter',
    events: {
        'Get Filter': props<{ id: string }>(),
        'Get Filter Success': props<{ payload: FilterModel }>(),
        'Get Filter Error': emptyProps(),

        'Add Filter': props<{ value: FilterValue }>(),
        'Add Filter Success': emptyProps(),
        'Add Filter Error': emptyProps(),

        'Update Filter': props<{ id: string; value: FilterValue }>(),
        'Update Filter Success': emptyProps(),
        'Update Filter Error': props<{ payload: FilterError }>(),

        'Delete Filter': props<{ id: string }>(),
        'Delete Filter Success': emptyProps(),
        'Delete Filter Error': props<{ payload: FilterError }>(),

        'Attach Filter': props<{ id: string; bookingIds: string[] }>(),
        'Attach Filter Success': emptyProps(),
        'Attach Filter Error': props<{ payload: FilterError }>(),

        'Detach Filter': props<{ bookingIds: string[] }>(),
        'Detach Filter Success': emptyProps(),
        'Detach Filter Error': props<{ payload: FilterError }>(),

        Clear: emptyProps(),
    },
});
