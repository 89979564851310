import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { DraftPointModel, DraftPointValue, DraftPointDto } from '../models';
import { PointError } from '../../models/point.error';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getDraftPoint(id: string): Observable<DraftPointModel> {
        return this.httpClient
            .skipErrorHandler()
            .get<ApiResponse<DraftPointDto>>(`draft-point/${id}`)
            .pipe(
                map((response) => new DraftPointModel(response.data)),
                catchError((response: HttpErrorResponse) => {
                    throw new PointError(response);
                }),
            );
    }

    addDraftPoint(value: DraftPointValue): Observable<null> {
        return this.httpClient
            .skipErrorHandler()
            .post<null>('draft-point', new DraftPointValue(value))
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    throw new PointError(response);
                }),
            );
    }

    updateDraftPoint(id: string, value: DraftPointValue): Observable<null> {
        return this.httpClient
            .skipErrorHandler()
            .put<null>(`draft-point/${id}`, new DraftPointValue(value))
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    throw new PointError(response);
                }),
            );
    }

    deleteDraftPoint(id: string): Observable<null> {
        return this.httpClient
            .skipErrorHandler()
            .delete<null>(`draft-point/${id}`)
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    throw new PointError(response);
                }),
            );
    }
}
