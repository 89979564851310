import { DRAFT_POINT_TYPES } from '../../enums';
import { GeoAddressDto, GeoAddressModel } from '../../models';

export interface DraftPointDto {
    id: string;
    type: DRAFT_POINT_TYPES;
    address: GeoAddressDto;
    date: string;
    planningType: number;
    driverId?: string;
    vehicleId?: string;
}

export class DraftPointModel {
    id: string;
    type: DRAFT_POINT_TYPES;
    address: GeoAddressModel;
    date: string;
    planningType: number;
    driverId?: string;
    vehicleId?: string;
    constructor(data: DraftPointDto) {
        this.id = data.id;
        this.type = data.type;
        this.date = data.date;
        this.address = new GeoAddressModel(data.address);
        this.planningType = data.planningType;
        if (data.driverId) {
            this.driverId = data.driverId;
        }
        if (data.vehicleId) {
            this.vehicleId = data.vehicleId;
        }
    }
}
