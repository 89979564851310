import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const processingActions = createActionGroup({
    source: 'Planning - Processing',
    events: {
        Add: props<{ key: string }>(),
        Del: props<{ key: string }>(),
        Clear: emptyProps(),
    },
});
