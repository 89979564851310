import { Injectable, inject } from '@angular/core';
import { filter, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectVehicles, selectLoaded, selectLoading, selectVehicle, selectVehiclesIdsSortedByVehicleName } from '../selectors/vehicles.selector';
import { vehiclesActions } from '../actions/vehicles.actions';
import { Vehicle } from '../models';

@Injectable({
    providedIn: 'root',
})
export class PlanningVehiclesFacade {
    private store = inject(Store);
    loaded$ = this.store.select(selectLoaded).pipe(filter((data): data is boolean => data !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((data): data is boolean => data !== null));

    vehicle$ = (id: string) => this.store.select(selectVehicle(id)).pipe(filter((data): data is Vehicle => data !== null));
    vehicles$ = this.store.select(selectVehicles).pipe(filter((data): data is Vehicle[] => data !== null));

    vehiclesSelectOptions$ = this.vehicles$.pipe(
        map((vehicles) =>
            vehicles.map((vehicle) => ({
                label: `${vehicle.name}`,
                value: vehicle.id,
            })),
        ),
    );

    idsSortedByVehicleName$ = this.store.select(selectVehiclesIdsSortedByVehicleName);

    getVehicles() {
        this.store.dispatch(vehiclesActions.getVehicles());
    }

    clear(): void {
        this.store.dispatch(vehiclesActions.clear());
    }
}
