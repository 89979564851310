import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PartnersState } from '../reducers/partners.reducer';
import { PARTNERS_FEATURE_KEY } from '../keys';

export const selectState = createFeatureSelector<PartnersState>(PARTNERS_FEATURE_KEY);
export const selectPartners = createSelector(selectState, (state) => state.partners || []);
export const selectPartner = (id: string) => createSelector(selectState, (state) => state.partners?.find((partner) => partner.id === id) || null);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);

export const selectPartnersSelectOptions = createSelector(selectState, (state: PartnersState) => {
    const items = (state.partners || []).map((partner) => ({ label: partner.name, value: partner.id }));
    items.sort((a, b) => a.label.localeCompare(b.label));
    return items;
});
