import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { HttpService } from '../services/http.service';
import { catchError, concatMap, map, of, repeat, takeUntil } from 'rxjs';
import { routesNotificationActions } from '../actions/routes-notification.actions';
import { Store } from '@ngrx/store';
import { selectNotificationsActiveRoutesIds, selectNotificationsDeactiveRoutesIds } from '../selectors/routes.selectors';
import { queryActions } from '../../query/actions/query.actions';

@Injectable()
export class RoutesNotificationsEffects {
    takeUntil$ = this.actions$.pipe(ofType(queryActions.set, queryActions.clear));

    activateNotifications$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routesNotificationActions.activate),
            concatMap(({ ids }) => {
                return this.httpService.activateRoute(ids).pipe(
                    map((payload) => routesNotificationActions.activateSuccess({ payload })),
                    catchError(() => of(routesNotificationActions.activateError({ ids }))),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    activateAllNotifications$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routesNotificationActions.activateAll),
            concatLatestFrom(() => this.store.select(selectNotificationsDeactiveRoutesIds)),
            map(([, ids]) => routesNotificationActions.activate({ ids })),
        );
    });

    deactivateNotifications$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routesNotificationActions.deactivate),
            concatMap(({ ids }) => {
                return this.httpService.deactivateRoute(ids).pipe(
                    map((payload) => routesNotificationActions.deactivateSuccess({ payload })),
                    catchError(() => of(routesNotificationActions.deactivateError({ ids }))),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    deactivateAllNotifications$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routesNotificationActions.deactivateAll),
            concatLatestFrom(() => this.store.select(selectNotificationsActiveRoutesIds)),
            map(([, ids]) => routesNotificationActions.deactivate({ ids })),
        );
    });

    constructor(
        private store: Store,
        private actions$: Actions,
        private httpService: HttpService,
    ) {}
}
