import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { queryActions } from '../../query/actions/query.actions';
import { selectRoutesActions } from '../actions/select-routes.actions';

@Injectable()
export class SelectRoutesEffects {
    queryPlanningChangeGet$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(queryActions.set),
            map(() => selectRoutesActions.clearAction()),
        );
    });

    constructor(private actions$: Actions) {}
}
