import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { TransferPointModel, TransferPointDto } from '../models';
import { PlanningQuery } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getTransferPointsPreset(query: PlanningQuery): Observable<TransferPointModel[]> {
        return this.httpClient
            .get<ApiResponse<TransferPointDto[]>>(`transfer-points/planning/${query.date}/${query.type}`)
            .pipe(map((response) => response.data.map((item) => new TransferPointModel(item))));
    }
}
