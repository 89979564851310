import { createReducer, on } from '@ngrx/store';
import { taskPointActions } from '../actions/task-point.actions';
import { TaskPointModel } from '../models';
import { PointError } from '../../models/point.error';

export interface TaskPointState {
    taskPoint: TaskPointModel | null;
    loading: boolean | null;
    loaded: boolean | null;
    saving: boolean | null;
    saved: boolean | null;
    deleting: boolean | null;
    deleted: boolean | null;
    error: PointError | null;
}

export const initialState: TaskPointState = {
    taskPoint: null,
    loading: null,
    loaded: null,
    saving: null,
    saved: null,
    deleting: null,
    deleted: null,
    error: null,
};

export const taskPointReducer = createReducer(
    initialState,
    on(
        taskPointActions.getTaskPoint,
        (state): TaskPointState => ({
            ...state,
            loading: true,
            error: null,
        }),
    ),
    on(
        taskPointActions.getTaskPointSuccess,
        (state, action): TaskPointState => ({
            ...state,
            loading: false,
            loaded: true,
            taskPoint: action.payload,
        }),
    ),
    on(
        taskPointActions.getTaskPointError,
        (state, action): TaskPointState => ({
            ...state,
            loading: false,
            loaded: false,
            error: action.payload,
        }),
    ),
    on(
        taskPointActions.addTaskPoint,
        (state): TaskPointState => ({
            ...state,
            saving: true,
            error: null,
        }),
    ),
    on(
        taskPointActions.addTaskPointSuccess,
        (state): TaskPointState => ({
            ...state,
            saving: false,
            saved: true,
        }),
    ),
    on(
        taskPointActions.addTaskPointError,
        (state): TaskPointState => ({
            ...state,
            saving: false,
            saved: false,
        }),
    ),
    on(
        taskPointActions.updateTaskPoint,
        (state): TaskPointState => ({
            ...state,
            saving: true,
            error: null,
        }),
    ),
    on(
        taskPointActions.updateTaskPointSuccess,
        (state): TaskPointState => ({
            ...state,
            saving: false,
            saved: true,
        }),
    ),
    on(
        taskPointActions.updateTaskPointError,
        (state, action): TaskPointState => ({
            ...state,
            saving: false,
            saved: false,
            error: action.payload,
        }),
    ),
    on(
        taskPointActions.deleteTaskPoint,
        (state): TaskPointState => ({
            ...state,
            deleting: true,
            error: null,
        }),
    ),
    on(
        taskPointActions.deleteTaskPointSuccess,
        (state): TaskPointState => ({
            ...state,
            deleting: false,
            deleted: true,
        }),
    ),
    on(
        taskPointActions.deleteTaskPointError,
        (state, action): TaskPointState => ({
            ...state,
            deleting: false,
            deleted: false,
            error: action.payload,
        }),
    ),
    on(
        taskPointActions.clear,
        (state): TaskPointState => ({
            ...state,
            ...initialState,
        }),
    ),
);
