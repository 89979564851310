import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TaskPointModel, TaskPointValue } from '../models';
import { PointError } from '../../models/point.error';

export const taskPointActions = createActionGroup({
    source: 'Planning - Task Point',
    events: {
        'Get Task Point': props<{ id: string }>(),
        'Get Task Point Success': props<{ payload: TaskPointModel }>(),
        'Get Task Point Error': props<{ payload: PointError }>(),
        'Add Task Point': props<{ value: TaskPointValue }>(),
        'Add Task Point Success': emptyProps(),
        'Add Task Point Error': props<{ payload: PointError }>(),
        'Update Task Point': props<{ id: string; value: TaskPointValue }>(),
        'Update Task Point Success': emptyProps(),
        'Update Task Point Error': props<{ payload: PointError }>(),
        'Delete Task Point': props<{ id: string }>(),
        'Delete Task Point Success': emptyProps(),
        'Delete Task Point Error': props<{ payload: PointError }>(),
        Clear: emptyProps(),
    },
});
