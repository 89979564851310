import { BOOKING_TYPES, WAYPOINT_TYPES } from '../../enums';
import { WaypointModel, WaypointTransferModel } from '../../models';
import { RouteDraft, Route } from '../models';

export const PendingWaypoints = (routes: (Route | RouteDraft)[], routeId: string | null, waypointId: string) => {
    const route = routes?.find((route) => route.id === routeId);
    const routeWaypoint = route?.waypoints.find((waypoint) => waypoint.pointId === waypointId);
    const transfers =
        routes
            ?.map((route) => route.waypoints)
            .flat()
            .filter((waypoint) => waypoint.type === WAYPOINT_TYPES.TRANSFER && waypoint.pointId === waypointId) ?? [];

    const transfersOuts = transfers.map((waypoint) => waypoint.transfersOut || []).flat();
    const transfersIns = transfers.map((waypoint) => waypoint.transfersIn || []).flat();
    const curretRouteWaypointTransfersOut = routeWaypoint?.transfersOut || [];
    const transfersPending = transfersOuts
        .filter((transfersOut) => transfersIns.findIndex((transferIn) => transfersOut?.bookingId === transferIn?.bookingId) === -1)
        .filter(
            (transfersOut) =>
                curretRouteWaypointTransfersOut.findIndex((currentTransferOut) => transfersOut?.bookingId === currentTransferOut.bookingId) === -1,
        );

    // remove duplicates
    return (
        transfersPending.reduce(
            (prev, next) => {
                const index = prev.findIndex(
                    (waypointTransfer) => waypointTransfer.bookingId === next.bookingId && waypointTransfer.bookingType === next.bookingType,
                );
                if (index === -1) {
                    return [...prev, next];
                }
                return prev;
            },
            <WaypointTransferModel[]>[],
        ) ?? []
    );
};

export const PendingTransfersOut = (waypoints: WaypointModel[], waypointPointId: string) => {
    const waypointIndex = waypoints.findIndex((waypoint) => waypoint.pointId === waypointPointId);

    const transfersOut = waypoints.reduce(
        (transfers, waypoint, currentIndex) => {
            switch (waypoint.type) {
                case WAYPOINT_TYPES.PASSENGER_START:
                    if (currentIndex < waypointIndex) {
                        transfers.push({ bookingId: waypoint.pointId, bookingType: BOOKING_TYPES.PASSENGER });
                    }
                    break;
                case WAYPOINT_TYPES.PARCEL_START:
                    if (currentIndex < waypointIndex) {
                        transfers.push({ bookingId: waypoint.pointId, bookingType: BOOKING_TYPES.PARCEL });
                    }
                    break;
                case WAYPOINT_TYPES.PASSENGER_STOP:
                case WAYPOINT_TYPES.PARCEL_STOP: {
                    if (currentIndex < waypointIndex) {
                        const index = transfers.findIndex((transfer) => transfer.bookingId === waypoint.pointId);
                        if (index !== -1) {
                            transfers.splice(index, 1);
                        }
                    }
                    break;
                }
                case WAYPOINT_TYPES.TRANSFER:
                    if (currentIndex < waypointIndex) {
                        const transfersOut = waypoint.transfersOut || [];
                        const transfersIn = waypoint.transfersIn || [];
                        transfersIn.forEach((transferIn) => {
                            const index = transfers.findIndex((transfer) => transfer.bookingId === transferIn.bookingId);
                            if (index === -1) {
                                transfers.push({ ...transferIn });
                            }
                        });
                        transfersOut.forEach((transfersOut) => {
                            const index = transfers.findIndex((transfer) => transfer.bookingId === transfersOut.bookingId);
                            if (index !== -1) {
                                transfers.splice(index, 1);
                            }
                        });
                    }
                    break;
            }
            return transfers;
        },
        <WaypointTransferModel[]>[],
    );

    return transfersOut;
};

export const PendingTransfersIn = (waypoints: WaypointModel[], waypointPointId: string) => {
    const waypointIndex = waypoints.findIndex((waypoint) => waypoint.pointId === waypointPointId);

    const transfersIn = waypoints.reduceRight(
        (transfers, waypoint, currentIndex) => {
            switch (waypoint.type) {
                case WAYPOINT_TYPES.PASSENGER_STOP:
                    if (currentIndex > waypointIndex) {
                        transfers.push({ bookingId: waypoint.pointId, bookingType: BOOKING_TYPES.PASSENGER });
                    }
                    break;
                case WAYPOINT_TYPES.PARCEL_STOP:
                    if (currentIndex > waypointIndex) {
                        transfers.push({ bookingId: waypoint.pointId, bookingType: BOOKING_TYPES.PARCEL });
                    }
                    break;
                case WAYPOINT_TYPES.PASSENGER_START:
                case WAYPOINT_TYPES.PARCEL_START: {
                    const index = transfers.findIndex((transfer) => transfer.bookingId === waypoint.pointId);
                    if (index !== -1) {
                        transfers.splice(index, 1);
                    }
                    break;
                }
                case WAYPOINT_TYPES.TRANSFER:
                    if (currentIndex < waypointIndex) {
                        const transfersOut = waypoint.transfersOut || [];
                        const transfersIn = waypoint.transfersIn || [];
                        transfersIn.forEach((transferIn) => {
                            const index = transfers.findIndex((transfer) => transfer.bookingId === transferIn.bookingId);
                            transfers.splice(index, 1);
                        });
                        transfersOut.forEach((transfersOut) => {
                            const index = transfers.findIndex((transfer) => transfer.bookingId === transfersOut.bookingId);
                            transfers.splice(index, 1);
                        });
                    }
                    break;
            }
            return transfers;
        },
        <WaypointTransferModel[]>[],
    );

    return transfersIn;
};
