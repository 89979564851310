import { createReducer, on } from '@ngrx/store';
import { vehiclesActions } from '../actions/vehicles.actions';
import { Vehicle } from '../models';

export interface VehiclesState {
    vehicles: Vehicle[] | null;
    total: number | null;
    loading: boolean | null;
    loaded: boolean | null;
}

export const initialState: VehiclesState = {
    vehicles: null,
    total: null,
    loading: null,
    loaded: null,
};

export const vehilcesReducer = createReducer(
    initialState,
    on(
        vehiclesActions.getVehicles,
        (state): VehiclesState => ({
            ...state,
            loading: true,
        }),
    ),
    on(vehiclesActions.getVehiclesSuccess, (state, action): VehiclesState => {
        const sortedVehicles = [...action.payload].sort((a, b) => a.name.localeCompare(b.name));

        return {
            ...state,
            vehicles: sortedVehicles,
            loaded: true,
            loading: false,
        };
    }),
    on(
        vehiclesActions.getVehiclesError,
        (state): VehiclesState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),
    on(
        vehiclesActions.clear,
        (state): VehiclesState => ({
            ...state,
            ...initialState,
        }),
    ),
);
