import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TransferPointsState } from '../reducers/transfer-points.reducer';
import { TRANSFER_POINTS_KEY } from '../keys';

export const selectState = createFeatureSelector<TransferPointsState>(TRANSFER_POINTS_KEY);
export const selectTransferPoints = createSelector(selectState, (state) => state.transferPoints);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);
export const selectTransferPoint = (id: string) => createSelector(selectState, (state) => state.transferPoints?.find((task) => task.id === id) || null);

export const selectTransferPointsByIds = (ids: string[]) =>
    createSelector(selectState, (state) => state.transferPoints?.filter((transferPoint) => ids.includes(transferPoint.id)) || null);
