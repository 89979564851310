import { FilterDto } from './filter.dto';

export class FilterModel {
    id: string;
    name: string;
    color: string;
    date: string;
    default: boolean;
    planningType: number;
    bookings: string[];

    constructor(filter: FilterDto) {
        this.id = filter.id;
        this.name = filter.name;
        this.color = filter.color;
        this.date = filter.date;
        this.default = filter.default;
        this.planningType = filter.planningType;
        this.bookings = filter.bookings;
    }
}
