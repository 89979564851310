import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Vehicle } from '../models';

export const vehiclesActions = createActionGroup({
    source: 'Planning - Vehicles',
    events: {
        'Get Vehicles': emptyProps(),
        'Get Vehicles Success': props<{ payload: Vehicle[] }>(),
        'Get Vehicles Error': emptyProps(),
        Clear: emptyProps(),
    },
});
