import { RouteDto } from './route.dto';
import { PlanningQuery, WaypointModel } from '../../models';

export class Route {
    id: string;
    date: Date;
    planningType: number;
    vehicleId: string | null;
    driverId: string | null;
    additionalDriversIds: string[];
    waypoints: WaypointModel[];
    confirmed: boolean;
    controlTimestamp: number;
    tags: RouteTag[];
    autoTags: RouteTag[];
    notificationsEnabled: boolean;
    hereMapsRoute: null | unknown;
    constructor(data: RouteDto) {
        this.id = data.id;
        this.date = new Date(data.date);
        this.planningType = data.planningType;
        this.vehicleId = data.vehicle || null;
        this.driverId = data.mainDriver || null;
        this.additionalDriversIds = data.drivers || [];
        this.waypoints = data.waypoints.map((waypoint) => new WaypointModel(waypoint));
        this.confirmed = data.confirmed === true;
        this.controlTimestamp = parseInt(data.controlTimestamp, 10);
        this.tags = (data.tags || []).map((tag) => new RouteTag(tag));
        this.autoTags = (data.autoTags || []).map((tag) => new RouteTag(tag));
        this.notificationsEnabled = data?.notificationsEnabled ?? false;
        this.hereMapsRoute = null;
    }
}

export class RouteDraft {
    id: string | null;
    date: Date;
    planningType: number;
    vehicleId: string | null;
    driverId: string | null;
    additionalDriversIds: string[];
    waypoints: WaypointModel[];
    confirmed: boolean;
    controlTimestamp: number | null;
    tags: RouteTag[];
    autoTags: RouteTag[];
    hereMapsRoute: null | unknown;
    constructor(query: PlanningQuery) {
        this.id = null;
        this.date = new Date(query.date);
        this.planningType = query.type;
        this.vehicleId = null;
        this.driverId = null;
        this.additionalDriversIds = [];
        this.waypoints = [];
        this.confirmed = false;
        this.controlTimestamp = null;
        this.tags = [];
        this.autoTags = [];
        this.hereMapsRoute = null;
    }
}

export class RouteTag {
    name: string;
    code: string;
    constructor(data: string) {
        this.name = `${data.trim()}`;
        this.code = data.trim();
    }
}
