import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs';
import { STAGE_TYPES } from '../../enums';
import { stageActions } from '../actions/stage.actions';
import { selectStage } from '../selectors/stage.selector';

@Injectable({
    providedIn: 'root',
})
export class PlanningStageFacade {
    private store = inject(Store);

    stage$ = this.store.select(selectStage);

    get stage(): STAGE_TYPES | null {
        let stage: STAGE_TYPES | null = null;
        this.stage$
            .pipe(
                take(1),
                tap((data) => (stage = data)),
            )
            .subscribe();
        return stage;
    }

    setStage(stage: STAGE_TYPES): void {
        this.store.dispatch(stageActions.set({ stage }));
    }

    clear(): void {
        this.store.dispatch(stageActions.clear());
    }
}
