import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { QueryModel } from '../models';

export const queryActions = createActionGroup({
    source: 'Planning - Query',
    events: {
        Set: props<{ query: QueryModel }>(),
        Clear: emptyProps(),
    },
});
