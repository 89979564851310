import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VehiclesState } from '../reducers/vehicles.reducer';
import { VEHICLES_KEY } from '../keys';

export const selectState = createFeatureSelector<VehiclesState>(VEHICLES_KEY);
export const selectVehicles = createSelector(selectState, (state) => state.vehicles);
export const selectVehiclesIdsSortedByVehicleName = createSelector(selectState, (state) => {
    const vehicles = [...(state.vehicles || [])];
    vehicles.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    return vehicles.map((vehicle) => vehicle.id);
});

export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);

export const selectVehicle = (id: string) => createSelector(selectState, (state) => state.vehicles?.find((vehicle) => vehicle.id === id) || null);
