import { createReducer, on } from '@ngrx/store';
import { routesNotificationActions } from '../actions/routes-notification.actions';

export interface RoutesNotificationState {
    activating: string[];
    deactivating: string[];
}

export const initialState: RoutesNotificationState = {
    activating: [],
    deactivating: [],
};

export const routesNotificationsReducer = createReducer(
    initialState,
    on(routesNotificationActions.activate, (state, action): RoutesNotificationState => {
        const ids = [...state.activating, ...action.ids];
        return {
            ...state,
            activating: ids,
        };
    }),
    on(routesNotificationActions.activateSuccess, (state, action): RoutesNotificationState => {
        const ids = [...state.activating];
        action.payload
            .map((item) => item.id)
            .forEach((id) => {
                const index = ids.indexOf(id);
                if (index !== -1) {
                    ids.splice(index, 1);
                }
            });
        return {
            ...state,
            activating: ids,
        };
    }),
    on(routesNotificationActions.activateError, (state, action): RoutesNotificationState => {
        const ids = [...state.activating];
        action.ids.forEach((id) => {
            const index = ids.indexOf(id);
            if (index !== -1) {
                ids.splice(index, 1);
            }
        });
        return {
            ...state,
            activating: ids,
        };
    }),

    on(routesNotificationActions.deactivate, (state, action): RoutesNotificationState => {
        const ids = [...state.deactivating, ...action.ids];
        return {
            ...state,
            deactivating: ids,
        };
    }),
    on(routesNotificationActions.deactivateSuccess, (state, action): RoutesNotificationState => {
        const ids = [...state.deactivating];
        action.payload
            .map((item) => item.id)
            .forEach((id) => {
                const index = ids.indexOf(id);
                if (index !== -1) {
                    ids.splice(index, 1);
                }
            });
        return {
            ...state,
            deactivating: ids,
        };
    }),
    on(routesNotificationActions.deactivateError, (state, action): RoutesNotificationState => {
        const ids = [...state.deactivating];
        action.ids.forEach((id) => {
            const index = ids.indexOf(id);
            if (index !== -1) {
                ids.splice(index, 1);
            }
        });
        return {
            ...state,
            deactivating: ids,
        };
    }),
);
