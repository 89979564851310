import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/models/api-response.model';
import { PlanningQuery } from '../../models';
import { Route } from '../models/route';
import { RouteDto } from '../models/route.dto';
import { RouteAddValue, RouteUpdateValue } from '../models/route.value';
import { RouteAddValueDto, RouteUpdateValueDto } from '../models/route.value.dto';
import { RouteNotification } from '../models/route-notification';
import { RouteNotificationDto } from '../models/route-notification.dto';
import { RouteError } from '../models/route.error';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getRoutes(query: PlanningQuery): Observable<Route[]> {
        return this.httpClient
            .get<ApiResponse<RouteDto[]>>(`routes/${query.date}/${query.type}`)
            .pipe(map((response) => response.data.map((item) => new Route(item))));
    }

    addRoute(value: RouteAddValue): Observable<{
        updated: Route[];
        deletedIds: string[];
    }> {
        return this.httpClient
            .skipErrorHandler()
            .post<
                ApiResponse<{
                    updated: RouteDto[];
                    deletedIds: string[];
                }>
            >('route', new RouteAddValueDto(value))
            .pipe(
                map((response) => ({
                    updated: response.data.updated.map((route) => new Route(route)),
                    deletedIds: [...response.data.deletedIds],
                })),
                catchError((response: HttpErrorResponse) => {
                    throw new RouteError(response);
                }),
            );
    }

    updateRoute(
        id: string,
        value: RouteUpdateValue,
    ): Observable<{
        updated: Route[];
        deletedIds: string[];
    }> {
        return this.httpClient
            .skipErrorHandler()
            .put<
                ApiResponse<{
                    updated: RouteDto[];
                    deletedIds: string[];
                }>
            >(`route/${id}`, new RouteUpdateValueDto(value))
            .pipe(
                map((response) => {
                    return {
                        updated: response.data.updated.map((route) => new Route(route)),
                        deletedIds: [...response.data.deletedIds],
                    };
                }),
                catchError((response: HttpErrorResponse) => {
                    throw new RouteError(response);
                }),
            );
    }

    activateRoute(ids: string[]): Observable<RouteNotification[]> {
        return this.httpClient
            .post<ApiResponse<RouteNotificationDto[]>>(`routes/notifications/activate`, { ids })
            .pipe(map((response) => response.data.map((item) => new RouteNotification(item))));
    }

    deactivateRoute(ids: string[]): Observable<RouteNotification[]> {
        return this.httpClient
            .post<ApiResponse<RouteNotificationDto[]>>(`routes/notifications/deactivate`, { ids })
            .pipe(map((response) => response.data.map((item) => new RouteNotification(item))));
    }
}
