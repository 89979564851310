import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SelectItemsState } from '../reducers/select-items.reducer';
import { SELECT_ITEMS_KEY } from '../keys';

const selectState = createFeatureSelector<SelectItemsState>(SELECT_ITEMS_KEY);
export const selectIdsBooking = createSelector(selectState, (state) => state.idsBooking);
export const selectIdsDraft = createSelector(selectState, (state) => state.idsDraft);
export const selectIdsTransfer = createSelector(selectState, (state) => state.idsTransfer);
export const selectIdsTask = createSelector(selectState, (state) => state.idsTask);
export const selectIds = createSelector(selectState, (state) => [...state.idsBooking, ...state.idsDraft, ...state.idsTransfer, ...state.idsTask]);
