import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { STAGE_TYPES } from '../../enums';

export const stageActions = createActionGroup({
    source: 'Planning - Stage',
    events: {
        Set: props<{ stage: STAGE_TYPES }>(),
        Clear: emptyProps(),
    },
});
