import { createReducer, on } from '@ngrx/store';
import { stageActions } from '../actions/stage.actions';
import { STAGE_TYPES } from '../../enums';

export interface StageState {
    stage: STAGE_TYPES | null;
}

export const initialState: StageState = {
    stage: null,
};

export const stageReducer = createReducer(
    initialState,
    on(stageActions.set, (state, action): StageState => {
        return { ...state, stage: action.stage };
    }),
    on(
        stageActions.clear,
        (state): StageState => ({
            ...state,
            ...initialState,
        }),
    ),
);
