import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TaskPointModel } from '../models';

export const taskPointsActions = createActionGroup({
    source: 'Planning - Task Points',
    events: {
        'Get Task Points': props<{ silent: boolean }>(),
        'Get Task Points Success': props<{ payload: TaskPointModel[] }>(),
        'Get Task Points Error': emptyProps(),
        Clear: emptyProps(),
    },
});
