import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaskPointsState } from '../reducers/task-points.reducer';
import { TASK_POINTS_KEY } from '../keys';

export const selectState = createFeatureSelector<TaskPointsState>(TASK_POINTS_KEY);
export const selectTaskPoints = createSelector(selectState, (state) => state.taskPoints);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);
export const selectTask = (id: string) => createSelector(selectState, (state) => state.taskPoints?.find((task) => task.id === id) || null);

export const selectTaskPointsByIds = (ids: string[]) =>
    createSelector(selectState, (state) => state.taskPoints?.filter((taskPoint) => ids.includes(taskPoint.id)) || null);
