import { createAction, props } from '@ngrx/store';

enum SelectRoutesActionTypes {
    SELECT_IDS_ACTION = '[Planning - Select Routes] Select routes',
    UNSELECT_IDS_ACTION = '[Planning - Select Routes] Unselect routes',
    CLEAR_ACTION = '[Planning - Select Routes] Clear',
    HIDE_ACTION = '[Planning - Select Routes] Hide routes',
    SHOW_ACTION = '[Planning - Select Routes] Show routes',
}

const selectIdsAction = createAction(SelectRoutesActionTypes.SELECT_IDS_ACTION, props<{ ids: string[] }>());
const unselectIdsAction = createAction(SelectRoutesActionTypes.UNSELECT_IDS_ACTION, props<{ ids: string[] }>());
const hideRoutesAction = createAction(SelectRoutesActionTypes.HIDE_ACTION);
const showRoutesAction = createAction(SelectRoutesActionTypes.SHOW_ACTION);
const clearAction = createAction(SelectRoutesActionTypes.CLEAR_ACTION);

export const selectRoutesActions = {
    selectIdsAction,
    unselectIdsAction,
    hideRoutesAction,
    showRoutesAction,
    clearAction,
};
