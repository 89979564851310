import { GeoAddressDto, GeoAddressModel } from '../../models';
import { TASK_POINT_TYPES } from '../../enums';

export interface TaskPointDto {
    id: string;
    planningType: number;
    date: string;
    name: string;
    type: TASK_POINT_TYPES;
    address: GeoAddressDto;
}

export class TaskPointModel {
    id: string;
    planningType: number;
    date: string;
    name: string;
    type: TASK_POINT_TYPES;
    address: GeoAddressModel;

    constructor(data: TaskPointDto) {
        this.id = data.id;
        this.planningType = data.planningType;
        this.date = data.date;
        this.name = data.name;
        this.type = data.type;
        this.address = new GeoAddressModel(data.address);
    }
}
