import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, mergeMap, takeUntil, repeat, finalize } from 'rxjs';
import { filterActions } from '../actions/filter.actions';
import { GuiFacade } from '@app/gui';
import { HttpService } from '../services/http.service';
import { queryActions } from '../../query/actions/query.actions';

@Injectable()
export class FilterEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly guiFacade = inject(GuiFacade);

    takeUntil$ = this.actions$.pipe(ofType(queryActions.set, queryActions.clear));

    addFilter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(filterActions.addFilter),
            mergeMap((action) => {
                this.guiFacade.showLoader('add-filter');
                return this.httpService.addFilter(action.value).pipe(
                    map(() => filterActions.addFilterSuccess()),
                    catchError(() => of(filterActions.addFilterError())),
                    finalize(() => this.guiFacade.hideLoader('add-filter')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    updateFilter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(filterActions.updateFilter),
            mergeMap((action) => {
                return this.httpService.updateFilter(action.id, action.value).pipe(
                    map(() => filterActions.updateFilterSuccess()),
                    catchError((error) => of(filterActions.updateFilterError({ payload: error }))),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    deleteFilter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(filterActions.deleteFilter),
            mergeMap((action) => {
                this.guiFacade.showLoader('delete-filter');
                return this.httpService.deleteFilter(action.id).pipe(
                    map(() => filterActions.deleteFilterSuccess()),
                    catchError((error) => of(filterActions.deleteFilterError({ payload: error }))),
                    finalize(() => this.guiFacade.hideLoader('delete-filter')),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    attachFilter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(filterActions.attachFilter),
            mergeMap((action) => {
                return this.httpService.attachToFilter(action.id, action.bookingIds).pipe(
                    map(() => filterActions.attachFilterSuccess()),
                    catchError((error) => of(filterActions.attachFilterError({ payload: error }))),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });

    detachFilter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(filterActions.detachFilter),
            mergeMap((action) => {
                return this.httpService.detach(action.bookingIds).pipe(
                    map(() => filterActions.detachFilterSuccess()),
                    catchError((error) => of(filterActions.detachFilterError({ payload: error }))),
                );
            }),
            takeUntil(this.takeUntil$),
            repeat(),
        );
    });
}
