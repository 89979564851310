import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { TaskPointModel, TaskPointDto } from '../models';
import { PlanningQuery } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getTaskPoints(query: PlanningQuery): Observable<TaskPointModel[]> {
        const params = {
            'planningType[eq]': query.type,
            'date[eq]': query.date,
        };
        return this.httpClient
            .get<ApiResponse<TaskPointDto[]>>(`tasks`, { params: new HttpParams({ fromObject: params }) })
            .pipe(map((response) => response.data.map((item) => new TaskPointModel(item))));
    }
}
