import { Injectable, inject } from '@angular/core';
import { filter, take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import * as routeDraft from '../selectors/draft.selectors';
import * as routesSelectors from '../selectors/routes.selectors';
import { routesActions } from '../actions/routes.actions';
import { Route, RouteDraft, RouteTag } from '../models';
import { WAYPOINT_TYPES } from '../../enums';
import { PlanningQuery, WaypointModel } from '../../models';
import { routesDraftActions } from '../actions/routes-draft.actions';

@Injectable({
    providedIn: 'root',
})
export class PlanningRoutesDraftFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(routesSelectors.selectLoaded).pipe(filter((data): data is boolean => data !== null));
    loading$ = this.store.select(routesSelectors.selectLoading).pipe(filter((data): data is boolean => data !== null));
    saved$ = this.store.select(routesSelectors.selectSaved).pipe(filter((saved): saved is boolean => saved !== null));
    saving$ = this.store.select(routesSelectors.selectSaving).pipe(filter((data): data is boolean => data !== null));
    error$ = this.store.select(routeDraft.selectDraftError);

    draft$ = this.store.select(routeDraft.selectDraft);
    draftNonNullable$ = this.store.select(routeDraft.selectDraft).pipe(filter((data): data is RouteDraft => data !== null));
    draftWaypoints$ = this.store.select(routeDraft.selectDraftWaypoints);
    draftWaypoint$ = (pointId: string, type: WAYPOINT_TYPES) =>
        this.store.select(routeDraft.selectDraftWaypoint(pointId, type)).pipe(filter((data): data is WaypointModel => data !== null));

    draftWaypointsNonNullable$ = this.store.select(routeDraft.selectDraftWaypoints).pipe(filter((data): data is WaypointModel[] => data !== null));
    draftDriverId$ = this.store.select(routeDraft.selectDraftDriverId);
    draftAdditionalDriversIds$ = this.store.select(routeDraft.selectDraftAdditionalDriversIds);
    draftVehicleId$ = this.store.select(routeDraft.selectDraftVehicleId);
    draftEditedTransferPointId$ = this.store.select(routeDraft.selectDraftEditedTransferPointId);
    draftEditedWaypoint$ = this.store.select(routeDraft.selectDraftEditedWaypoint).pipe(filter((data): data is WaypointModel => data !== null));
    draftTotalTime$ = this.store.select(routeDraft.selectDraftTotalTime);
    draftLength$ = this.store.select(routeDraft.selectDraftLength);

    routeDraftWaypointTransfersIn$ = (pointIds: string[]) => this.store.select(routeDraft.selectDraftWaypointTransfersIn(pointIds));
    routeDraftWaypointTransfersOut$ = (pointIds: string[]) => this.store.select(routeDraft.selectDraftWaypointTransfersOut(pointIds));
    routeDraftWaypointTransfersPendings$ = (pointIds: string[]) => this.store.select(routeDraft.selectDraftWaypointTransfersPending(pointIds));
    routeDraftWaypointTransfersPassings$ = (pointIds: string[]) => this.store.select(routeDraft.selectDraftWaypointTransfersPassing(pointIds));

    get draft(): RouteDraft | Route | null {
        let draft = null;
        this.store
            .select(routeDraft.selectDraft)
            .pipe(
                tap((value) => {
                    draft = value;
                }),
                take(1),
            )
            .subscribe();
        return draft;
    }

    /**
     * Dispatchess
     */
    editTransferPoint(pointId: string) {
        this.store.dispatch(routesDraftActions.selectRouteDraftTransferPointEdit({ pointId }));
    }

    moveRouteDraftPointTransferToggle(pointId: string) {
        this.store.dispatch(routesDraftActions.moveRouteDraftPointTransferToggle({ pointId }));
    }

    moveRouteDraftPointTransferOut(pointId: string) {
        this.store.dispatch(routesDraftActions.moveRouteDraftPointTransferOut({ pointId }));
    }

    moveRouteDraftPointTransferIn(pointId: string) {
        this.store.dispatch(routesDraftActions.moveRouteDraftPointTransferIn({ pointId }));
    }

    addRouteDraft(query: PlanningQuery): void {
        this.store.dispatch(routesDraftActions.addNewDraft({ query }));
    }

    setRouteDraft(routeId: string): void {
        this.store.dispatch(routesDraftActions.setRouteDraft({ routeId }));
    }

    reloadRouteDraft(): void {
        this.store.dispatch(routesDraftActions.reloadRouteDraft());
    }

    toggleWaypointId(pointId: string, kind: WAYPOINT_TYPES): void {
        this.store.dispatch(
            routesDraftActions.toggleRouteDraftWaypoint({
                pointId,
                kind,
            }),
        );
    }

    addWaypointId(pointId: string, kind: WAYPOINT_TYPES): void {
        this.store.dispatch(
            routesDraftActions.addRouteDraftWaypoint({
                pointId,
                kind,
            }),
        );
    }

    removeWaypointId(pointId: string, kind: WAYPOINT_TYPES): void {
        this.store.dispatch(
            routesDraftActions.removeRouteDraftWaypoint({
                pointId,
                kind,
            }),
        );
    }

    toggleTransfersIn(pointId: string) {
        this.store.dispatch(
            routesDraftActions.toggleRouteDraftWaypointTransfersIn({
                pointId,
            }),
        );
    }

    toggleTransfersOut(pointId: string) {
        this.store.dispatch(
            routesDraftActions.toggleRouteDraftWaypointTransfersOut({
                pointId,
            }),
        );
    }

    setDraftDriverId(driverId: string | null): void {
        this.store.dispatch(
            routesDraftActions.setRouteDraftDriverId({
                driverId,
            }),
        );
    }

    setDraftAdditionalDriversIds(driversIds: (string | null)[]): void {
        this.store.dispatch(
            routesDraftActions.setRouteDraftAdditinalDriversIds({
                driversIds,
            }),
        );
    }

    setDraftVehicleId(vehicleId: string | null): void {
        this.store.dispatch(
            routesDraftActions.setRouteDraftVehicleId({
                vehicleId,
            }),
        );
    }

    setDraftRouteWaypointTime(pointId: string, kind: WAYPOINT_TYPES, time: Date | null): void {
        this.store.dispatch(routesDraftActions.setRouteWaypointTime({ pointId, kind, time }));
    }

    setDraftRouteWaypointTimeRelative(pointId: string, kind: WAYPOINT_TYPES, direction: 'up' | 'down'): void {
        this.store.dispatch(routesDraftActions.setRouteWaypointTimeRelative({ pointId, kind, direction }));
    }

    saveDraft(): void {
        this.store.dispatch(routesDraftActions.saveRouteDraft());
    }

    updateRoute(): void {
        this.store.dispatch(routesDraftActions.updateRouteDraft());
    }

    moveWaypoint(fromIndex: number, toIndex: number): void {
        this.store.dispatch(routesDraftActions.moveRouteDraftWaypoint({ fromIndex, toIndex }));
    }

    setRouteDraftTags(tags: RouteTag[]): void {
        this.store.dispatch(routesDraftActions.setRouteDraftTags({ tags }));
    }

    clear(range?: 'transfer-point' | 'draft'): void {
        switch (range) {
            case 'transfer-point':
                this.store.dispatch(routesActions.clearStore({ range }));
                break;
            case 'draft':
                this.store.dispatch(routesActions.clearStore({ range }));
                break;
            default:
                this.store.dispatch(routesActions.clearStore({}));
                break;
        }
    }
}
