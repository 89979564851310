import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FiltersState } from '../reducers/filters.reducer';
import { FILTERS_KEY } from '../keys';

export const selectState = createFeatureSelector<FiltersState>(FILTERS_KEY);
export const selectFilters = createSelector(selectState, (state) => state.filters);
export const selectFilter = (id: string) => {
    return createSelector(selectState, (state) => (state.filters ? state.filters.find((filter) => filter.id === id) || null : null));
};
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);
