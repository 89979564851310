import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TransferPointModel, TransferPointValue } from '../models';
import { PointError } from '../../models/point.error';

export const transferPointActions = createActionGroup({
    source: 'Planning - Transfer Point',
    events: {
        'Get Transfer Point': props<{ id: string }>(),
        'Get Transfer Point Success': props<{ payload: TransferPointModel }>(),
        'Get Transfer Point Error': props<{ payload: PointError }>(),
        'Add Transfer Point': props<{ value: TransferPointValue }>(),
        'Add Transfer Point Success': emptyProps(),
        'Add Transfer Point Error': props<{ payload: PointError }>(),
        'Update Transfer Point': props<{ id: string; value: TransferPointValue }>(),
        'Update Transfer Point Success': emptyProps(),
        'Update Transfer Point Error': props<{ payload: PointError }>(),
        'Delete Transfer Point': props<{ id: string }>(),
        'Delete Transfer Point Success': emptyProps(),
        'Delete Transfer Point Error': props<{ payload: PointError }>(),
        Clear: emptyProps(),
    },
});
