import { Injectable, inject } from '@angular/core';
import { filter, take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectFilter, selectFilters, selectLoaded, selectLoading } from '../selectors/filters.selector';
import { filtersActions } from '../actions/filters.actions';
import { FilterModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class PlanningFiltersFacade {
    private readonly store = inject(Store);

    filters$ = this.store.select(selectFilters).pipe(filter((data): data is FilterModel[] => data !== null));
    loaded$ = this.store.select(selectLoaded).pipe(filter((data): data is boolean => data !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((data): data is boolean => data !== null));

    filter$ = (id: string) => this.store.select(selectFilter(id)).pipe(filter((data): data is FilterModel => data !== null));

    get filters() {
        let value: FilterModel[] = [];
        this.store
            .select(selectFilters)
            .pipe(
                take(1),
                tap((data) => (value = data ?? [])),
            )
            .subscribe();
        return value;
    }

    clear(): void {
        this.store.dispatch(filtersActions.clear());
    }
}
