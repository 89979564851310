import { createReducer, on } from '@ngrx/store';
import { driversActions } from '../actions/drivers.actions';
import { DriverModel } from '../models';

export interface DriversState {
    drivers: DriverModel[] | null;
    total: number | null;
    loading: boolean | null;
    loaded: boolean | null;
}

export const initialState: DriversState = {
    drivers: null,
    total: null,
    loading: null,
    loaded: null,
};

export const driversReducer = createReducer(
    initialState,
    on(
        driversActions.getDrivers,
        (state): DriversState => ({
            ...state,
            loading: true,
        }),
    ),
    on(driversActions.getDriversSuccess, (state, action): DriversState => {
        const sortedDrivers = [...action.payload].sort((a, b) => a.surname.localeCompare(b.surname));

        return {
            ...state,
            drivers: sortedDrivers,
            loaded: true,
            loading: false,
        };
    }),

    on(
        driversActions.getDriversError,
        (state): DriversState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),
    on(
        driversActions.clear,
        (state): DriversState => ({
            ...state,
            ...initialState,
        }),
    ),
);
