import { createReducer, on } from '@ngrx/store';
import { transferPointsActions } from '../actions/transfer-points.actions';
import { TransferPointModel } from '../models';

export interface TransferPointsState {
    transferPoints: TransferPointModel[] | null;
    total: number | null;
    loading: boolean | null;
    loaded: boolean | null;
}

export const initialState: TransferPointsState = {
    transferPoints: null,
    total: null,
    loading: null,
    loaded: null,
};

export const transferPointsReducer = createReducer(
    initialState,
    on(
        transferPointsActions.getTransferPoints,
        (state): TransferPointsState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        transferPointsActions.getTransferPointsSuccess,
        (state, action): TransferPointsState => ({
            ...state,
            transferPoints: action.payload,
            loaded: true,
            loading: false,
        }),
    ),
    on(
        transferPointsActions.getTransferPointsError,
        (state): TransferPointsState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),
    on(
        transferPointsActions.clear,
        (state): TransferPointsState => ({
            ...state,
            ...initialState,
        }),
    ),
);
