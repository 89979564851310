import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { VehicleDto, Vehicle } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getVehicles(): Observable<Vehicle[]> {
        return this.httpClient
            .get<ApiResponse<VehicleDto[]>>(`vehicles/planning`)
            .pipe(map((response) => (response.data || []).map((item) => new Vehicle(item))));
    }
}
