import { Injectable, inject } from '@angular/core';
import { of, catchError, map, mergeMap, finalize } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GuiFacade } from '@app/gui';
import { HttpService } from '../services/http.service';
import { driversActions } from '../actions/drivers.actions';

@Injectable()
export class DriversEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly guiFacade = inject(GuiFacade);

    getDrivers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(driversActions.getDrivers),
            mergeMap(() => {
                this.guiFacade.showLoader('get-planning-drivers');
                return this.httpService.getDrivers().pipe(
                    map((response) => driversActions.getDriversSuccess({ payload: response })),
                    catchError(() => of(driversActions.getDriversError())),
                    finalize(() => this.guiFacade.hideLoader('get-planning-drivers')),
                );
            }),
        );
    });
}
