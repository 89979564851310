import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DriverModel } from '../models';

export const driversActions = createActionGroup({
    source: 'Planning - Drivers',
    events: {
        'Get Drivers': emptyProps(),
        'Get Drivers Success': props<{ payload: DriverModel[] }>(),
        'Get Drivers Error': emptyProps(),
        Clear: emptyProps(),
    },
});
