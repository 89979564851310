import {
    RouteCalculationJunctureDto,
    RouteCalculationResponseDto,
    RouteCalculationSectionDto,
    RouteCalculationPlaceDto,
    RouteCalculationSummaryDto,
} from './calculation.dto';

export class RouteCalculationModel {
    id: string;
    sections: RouteCalculationSectionModel[];
    constructor(data: RouteCalculationResponseDto) {
        this.id = data.routes[0].id;
        this.sections = data.routes[0].sections.filter((section) => section.type === 'vehicle').map((section) => new RouteCalculationSectionModel(section));
    }
}

export class RouteCalculationSectionModel {
    id: string;
    type: string;
    departure: RouteCalculationJunctureModel;
    arrival: RouteCalculationJunctureModel;
    summary: RouteCalculationSummaryModel;
    polyline: string;
    transport: {
        mode: string;
    };

    constructor(data: RouteCalculationSectionDto) {
        this.id = data.id;
        this.type = data.type;
        this.departure = new RouteCalculationJunctureModel(data.departure);
        this.arrival = new RouteCalculationJunctureModel(data.arrival);
        this.summary = new RouteCalculationSummaryModel(data.summary);
        this.polyline = data.polyline;
        this.transport = {
            mode: data.transport.mode,
        };
    }
}

export class RouteCalculationJunctureModel {
    time: string;
    place: RouteCalculationPlaceModel;
    waypoint: number | null;
    constructor(data: RouteCalculationJunctureDto) {
        this.time = data.time;
        this.place = new RouteCalculationPlaceModel(data.place);
        this.waypoint = data.waypoint === undefined ? null : data.waypoint;
    }
}

export class RouteCalculationPlaceModel {
    type: string;
    location: {
        lat: number;
        lng: number;
    };

    constructor(data: RouteCalculationPlaceDto) {
        (this.type = data.type),
            (this.location = {
                lat: data.location.lat,
                lng: data.location.lng,
            });
    }
}

export class RouteCalculationSummaryModel {
    duration: number;
    length: number;
    baseDuration: number;
    typicalDuration: number;
    constructor(data: RouteCalculationSummaryDto) {
        this.duration = data.duration;
        this.length = data.length;
        this.baseDuration = data.baseDuration;
        this.typicalDuration = data.typicalDuration;
    }
}
