import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoutesState } from '../reducers/routes.reducer';
import { ROUTES_KEY } from '../keys';

const selectState = createFeatureSelector<RoutesState>(ROUTES_KEY);

/**
 * Routes
 */
export const selectRoutes = createSelector(selectState, (state) => state.routes || []);
export const selectRoutesWithDraft = createSelector(selectState, (state) => {
    return {
        routes: state.routes || [],
        draft: state.draft.route || null,
    };
});
export const selectRoutesWaypoints = createSelector(selectState, (state) => state.routes?.map((route) => route.waypoints).flat() || []);
export const selectRoutesByTransferPoint = (pointId: string | null) =>
    createSelector(selectState, (state) => state.routes?.filter((route) => route.waypoints.findIndex((waypoint) => waypoint.pointId === pointId) !== -1) ?? []);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);
export const selectSaving = createSelector(selectState, (state) => state.draft.saving);
export const selectSaved = createSelector(selectState, (state) => state.draft.saved);

export const selectNotificationsActiveRoutesIds = createSelector(selectState, (state) =>
    (state.routes || []).filter((route) => route.notificationsEnabled === true).map((route) => route.id),
);

export const selectNotificationsDeactiveRoutesIds = createSelector(selectState, (state) =>
    (state.routes || []).filter((route) => route.notificationsEnabled === false).map((route) => route.id),
);

export const selectAllNotificationsActive = createSelector(selectNotificationsDeactiveRoutesIds, (ids) => ids.length === 0);
