import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TransferPointModel } from '../models';

export const transferPointsActions = createActionGroup({
    source: 'Planning - Transfer Points',
    events: {
        'Get Transfer Points': props<{ silent: boolean }>(),
        'Get Transfer Points Success': props<{ payload: TransferPointModel[] }>(),
        'Get Transfer Points Error': emptyProps(),
        Clear: emptyProps(),
    },
});
