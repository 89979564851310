import { createAction, props } from '@ngrx/store';

enum SelectItemsActionTypes {
    ADD_IDS_BOOKING_ACTION = '[Planning - Select] Add booking IDs',
    REMOVE_IDS_BOOKING_ACTION = '[Planning - Select] Remove booking IDs',
    TOGGLE_IDS_BOOKINT_ACTION = '[Planning - Select] toggle booking IDs',
    ADD_IDS_DRAFT_ACTION = '[Planning - Select] Add draft IDs',
    REMOVE_IDS_DRAFT_ACTION = '[Planning - Select] Remove draft IDs',
    TOGGLE_IDS_DRAFT_ACTION = '[Planning - Select] toggle draft IDs',
    ADD_IDS_TRANSFER_ACTION = '[Planning - Select] Add transfer IDs',
    REMOVE_IDS_TRANSFER_ACTION = '[Planning - Select] Remove transfer IDs',
    TOGGLE_IDS_TRANSFER_ACTION = '[Planning - Select] toggle transfer IDs',
    ADD_IDS_TASK_ACTION = '[Planning - Select] Add task IDs',
    REMOVE_IDS_TASK_ACTION = '[Planning - Select] Remove task IDs',
    TOGGLE_IDS_TASK_ACTION = '[Planning - Select] toggle task IDs',
    CLEAR_ACTION = '[Planning - Select] Clear',
    ADD_POLYGON_SELECT_POINT = '[Planning - Select] Add point to selecting polygon',
    EDIT_POLYGON_SELECT_POINT = '[Planning - Select] Edit point from selecting polygon',
    CLEAR_POLYGON_SELECT = '[Planning - Select] Clear polygon selection',
    TOGGLE_POLYGON_SELECTING = '[Planning - Select] Toggle polygon selecting',
}

const addIdsBookingAction = createAction(SelectItemsActionTypes.ADD_IDS_BOOKING_ACTION, props<{ ids: string[] }>());
const removeIdsBookingAction = createAction(SelectItemsActionTypes.REMOVE_IDS_BOOKING_ACTION, props<{ ids: string[] }>());
const toggleIdsBookingAction = createAction(SelectItemsActionTypes.TOGGLE_IDS_BOOKINT_ACTION, props<{ ids: string[] }>());
const addIdsDraftAction = createAction(SelectItemsActionTypes.ADD_IDS_DRAFT_ACTION, props<{ ids: string[] }>());
const removeIdsDraftAction = createAction(SelectItemsActionTypes.REMOVE_IDS_DRAFT_ACTION, props<{ ids: string[] }>());
const toggleIdsDraftAction = createAction(SelectItemsActionTypes.TOGGLE_IDS_DRAFT_ACTION, props<{ ids: string[] }>());
const addIdsTransferAction = createAction(SelectItemsActionTypes.ADD_IDS_TRANSFER_ACTION, props<{ ids: string[] }>());
const removeIdsTransferAction = createAction(SelectItemsActionTypes.REMOVE_IDS_TRANSFER_ACTION, props<{ ids: string[] }>());
const toggleIdsTransferAction = createAction(SelectItemsActionTypes.TOGGLE_IDS_TRANSFER_ACTION, props<{ ids: string[] }>());
const addIdsTaskAction = createAction(SelectItemsActionTypes.ADD_IDS_TASK_ACTION, props<{ ids: string[] }>());
const removeIdsTaskAction = createAction(SelectItemsActionTypes.REMOVE_IDS_TASK_ACTION, props<{ ids: string[] }>());
const toggleIdsTaskAction = createAction(SelectItemsActionTypes.TOGGLE_IDS_TASK_ACTION, props<{ ids: string[] }>());
const clearAction = createAction(SelectItemsActionTypes.CLEAR_ACTION);

export const selectItemsActions = {
    addIdsBookingAction,
    removeIdsBookingAction,
    toggleIdsBookingAction,
    addIdsDraftAction,
    removeIdsDraftAction,
    toggleIdsDraftAction,
    addIdsTransferAction,
    removeIdsTransferAction,
    toggleIdsTransferAction,
    addIdsTaskAction,
    removeIdsTaskAction,
    toggleIdsTaskAction,
    clearAction,
};
