import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaskPointState } from '../reducers/task-point.reducer';
import { TASK_POINT_KEY } from '../keys';

const selectState = createFeatureSelector<TaskPointState>(TASK_POINT_KEY);
export const selectTaskPoint = createSelector(selectState, (state) => state.taskPoint);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);
export const selectSaving = createSelector(selectState, (state) => state.saving);
export const selectSaved = createSelector(selectState, (state) => state.saved);
export const selectDeleting = createSelector(selectState, (state) => state.deleting);
export const selectDeleted = createSelector(selectState, (state) => state.deleted);
export const selectError = createSelector(selectState, (state) => state.error);
