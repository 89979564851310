import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as routesSelectors from '../selectors/routes-notifications.selectors';
import { routesNotificationActions } from '../actions/routes-notification.actions';
import { take, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PlanningRoutesNotificationsFacade {
    loadingAny$ = this.store.select(routesSelectors.selectLoading);
    loading$ = (id: string) => this.store.select(routesSelectors.selectLoadingById(id));

    constructor(private store: Store) {}

    get loadingAny() {
        let loading = false;
        this.store
            .select(routesSelectors.selectLoading)
            .pipe(
                tap((data) => (loading = data)),
                take(1),
            )
            .subscribe();
        return loading;
    }

    loading = (id: string) => {
        let loading = false;
        this.store
            .select(routesSelectors.selectLoadingById(id))
            .pipe(
                tap((data) => (loading = data)),
                take(1),
            )
            .subscribe();
        return loading;
    };

    activate(id: string) {
        this.store.dispatch(routesNotificationActions.activate({ ids: [id] }));
    }

    activateAll() {
        this.store.dispatch(routesNotificationActions.activateAll());
    }

    deactivate(id: string) {
        this.store.dispatch(routesNotificationActions.deactivate({ ids: [id] }));
    }

    deactivateAll() {
        this.store.dispatch(routesNotificationActions.deactivateAll());
    }

    clear(): void {}
}
