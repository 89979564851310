import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs';
import { draftPointActions } from '../actions/draft-point.actions';
import { DraftPointModel, DraftPointValue } from '../models';
import {
    selectDraftPoint,
    selectLoaded,
    selectLoading,
    selectSaved,
    selectSaving,
    selectDeleted,
    selectDeleting,
    selectError,
} from '../selectors/draft-point.selector';

@Injectable({
    providedIn: 'root',
})
export class DraftPointFacade {
    draftPoint$ = this.store.select(selectDraftPoint).pipe(filter((data): data is DraftPointModel => data !== null));
    loaded$ = this.store.select(selectLoaded).pipe(filter((data): data is boolean => data !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((data): data is boolean => data !== null));
    saved$ = this.store.select(selectSaved).pipe(filter((data): data is boolean => data !== null));
    saving$ = this.store.select(selectSaving).pipe(filter((data): data is boolean => data !== null));
    deleted$ = this.store.select(selectDeleted).pipe(filter((data): data is boolean => data !== null));
    deleting$ = this.store.select(selectDeleting).pipe(filter((data): data is boolean => data !== null));
    error$ = this.store.select(selectError);

    constructor(private store: Store) {}

    get saving() {
        let saving = false;
        this.store
            .select(selectSaving)
            .pipe(
                take(1),
                tap((value) => (saving = value === true)),
            )
            .subscribe();
        return saving;
    }

    getDraftPoint(id: string): void {
        this.store.dispatch(draftPointActions.getDraftPoint({ id }));
    }

    addDraftPoint(value: DraftPointValue): void {
        this.store.dispatch(draftPointActions.addDraftPoint({ value }));
    }

    updateDraftPoint(id: string, value: DraftPointValue): void {
        this.store.dispatch(draftPointActions.updateDraftPoint({ id, value }));
    }

    deleteDraftPoint(id: string): void {
        this.store.dispatch(draftPointActions.deleteDraftPoint({ id }));
    }

    clear(): void {
        this.store.dispatch(draftPointActions.clear());
    }
}
