import { PLANNING_TYPE } from '../../enums';
import { GeoAddressModel } from '../../models';
import { RecurringDaysAndTimeDto, TransferPointDto } from './transfer-point.dto';

export class TransferPointModel {
    id: string;
    name: string;
    planningType: PLANNING_TYPE;
    date: string;
    address: GeoAddressModel;
    active: boolean;
    predefined: boolean;
    endingPointTag: null | string;
    startingPointTag: null | string;
    passthroughPointTag: null | string;
    hasAutoTag: boolean;
    recurringDaysAndTime: RecurringDaysAndTimeModel | null;
    constructor(data: TransferPointDto) {
        this.id = data.id;
        this.name = data.name;
        this.planningType = data.planningType;
        this.date = data.date;
        this.address = new GeoAddressModel(data.address);
        this.active = data.active;
        this.predefined = data.predefined;
        this.endingPointTag = data.endingPointTag;
        this.startingPointTag = data.startingPointTag;
        this.passthroughPointTag = data.passthroughPointTag;
        this.hasAutoTag = data.passthroughPointTag !== null || data.startingPointTag !== null || data.endingPointTag !== null;
        this.recurringDaysAndTime = data.recurringDaysAndTime !== null ? new RecurringDaysAndTimeModel(data.recurringDaysAndTime) : null;
    }
}

export class RecurringDaysAndTimeModel {
    mon: number;
    tue: number;
    wed: number;
    thu: number;
    fri: number;
    sat: number;
    sun: number;

    constructor(data: RecurringDaysAndTimeDto) {
        this.mon = 0;
        this.tue = 0;
        this.wed = 0;
        this.thu = 0;
        this.fri = 0;
        this.sat = 0;
        this.sun = 0;

        if (data.mon === true && data.monTime !== null) {
            const [hour, min] = data.monTime.split(':');
            this.mon = parseInt(hour, 10) * 3600 + parseInt(min, 10) * 60;
            if (data.monShiftDay === true) {
                this.mon = this.mon + 86400;
            }
        }

        if (data.tue === true && data.tueTime !== null) {
            const [hour, min] = data.tueTime.split(':');
            this.tue = parseInt(hour, 10) * 3600 + parseInt(min, 10) * 60;
            if (data.tueShiftDay === true) {
                this.tue = this.tue + 86400;
            }
        }

        if (data.wed === true && data.wedTime !== null) {
            const [hour, min] = data.wedTime.split(':');
            this.wed = parseInt(hour, 10) * 3600 + parseInt(min, 10) * 60;
            if (data.wedShiftDay === true) {
                this.wed = this.wed + 86400;
            }
        }

        if (data.thu === true && data.thuTime !== null) {
            const [hour, min] = data.thuTime.split(':');
            this.thu = parseInt(hour, 10) * 3600 + parseInt(min, 10) * 60;
            if (data.thuShiftDay === true) {
                this.thu = this.thu + 86400;
            }
        }

        if (data.fri === true && data.friTime !== null) {
            const [hour, min] = data.friTime.split(':');
            this.fri = parseInt(hour, 10) * 3600 + parseInt(min, 10) * 60;
            if (data.friShiftDay === true) {
                this.fri = this.fri + 86400;
            }
        }

        if (data.sat === true && data.satTime !== null) {
            const [hour, min] = data.satTime.split(':');
            this.sat = parseInt(hour, 10) * 3600 + parseInt(min, 10) * 60;
            if (data.satShiftDay === true) {
                this.sat = this.sat + 86400;
            }
        }

        if (data.sun === true && data.sunTime !== null) {
            const [hour, min] = data.sunTime.split(':');
            this.sun = parseInt(hour, 10) * 3600 + parseInt(min, 10) * 60;
            if (data.sunShiftDay === true) {
                this.sun = this.sun + 86400;
            }
        }
    }
}
