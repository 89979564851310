import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIds, selectIdsBooking, selectIdsDraft, selectIdsTransfer, selectIdsTask } from '../selectors/select-items.selector';
import { selectItemsActions } from '../actions/select-items.actions';
import { tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PlanningSelectItemsFacade {
    ids$ = this.store.select(selectIds);
    idsBooking$ = this.store.select(selectIdsBooking);
    idsDraft$ = this.store.select(selectIdsDraft);
    idsTransfer$ = this.store.select(selectIdsTransfer);
    idsTask$ = this.store.select(selectIdsTask);

    constructor(private store: Store) {}

    get idsBooking() {
        let ids: string[] = [];
        this.store
            .select(selectIdsBooking)
            .pipe(tap((value) => (ids = value)))
            .subscribe();
        return ids;
    }

    get idsDraft() {
        let ids: string[] = [];
        this.store
            .select(selectIdsDraft)
            .pipe(tap((value) => (ids = value)))
            .subscribe();
        return ids;
    }

    get idsTransfer() {
        let ids: string[] = [];
        this.store
            .select(selectIdsTransfer)
            .pipe(tap((value) => (ids = value)))
            .subscribe();
        return ids;
    }

    get idsTask() {
        let ids: string[] = [];
        this.store
            .select(selectIdsTask)
            .pipe(tap((value) => (ids = value)))
            .subscribe();
        return ids;
    }

    toggleBookingIds(id: string | string[]): void {
        const ids = Array.isArray(id) ? id : [id];
        this.store.dispatch(selectItemsActions.toggleIdsBookingAction({ ids }));
    }

    addBookingIds(id: string | string[]): void {
        const ids = Array.isArray(id) ? id : [id];
        this.store.dispatch(selectItemsActions.addIdsBookingAction({ ids }));
    }

    removeBookingIds(id: string | string[]): void {
        const ids = Array.isArray(id) ? id : [id];
        this.store.dispatch(selectItemsActions.removeIdsBookingAction({ ids }));
    }

    addDraftIds(id: string | string[]): void {
        const ids = Array.isArray(id) ? id : [id];
        this.store.dispatch(selectItemsActions.addIdsDraftAction({ ids }));
    }

    removeDraftIds(id: string | string[]): void {
        const ids = Array.isArray(id) ? id : [id];
        this.store.dispatch(selectItemsActions.removeIdsDraftAction({ ids }));
    }

    addTransferIds(id: string | string[]): void {
        const ids = Array.isArray(id) ? id : [id];
        this.store.dispatch(selectItemsActions.addIdsTransferAction({ ids }));
    }

    removeTransferIds(id: string | string[]): void {
        const ids = Array.isArray(id) ? id : [id];
        this.store.dispatch(selectItemsActions.removeIdsTransferAction({ ids }));
    }

    addTaskIds(id: string | string[]): void {
        const ids = Array.isArray(id) ? id : [id];
        this.store.dispatch(selectItemsActions.addIdsTaskAction({ ids }));
    }

    removeTaskIds(id: string | string[]): void {
        const ids = Array.isArray(id) ? id : [id];
        this.store.dispatch(selectItemsActions.removeIdsTaskAction({ ids }));
    }

    clear() {
        this.store.dispatch(selectItemsActions.clearAction());
    }
}
