import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { ParcelModel, PassengerModel, PassengerDto, ParcelDto } from '../models';
import { PlanningQuery } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getBookings(query: PlanningQuery): Observable<{ parcels: ParcelModel[]; passengers: PassengerModel[] }> {
        const params = {
            planningType: query.type,
            date: query.date,
        };
        return this.httpClient
            .get<ApiResponse<{ bookingParcels: ParcelDto[]; bookingPersons: PassengerDto[] }>>(`bookings/planning-flat`, {
                params: new HttpParams({ fromObject: params }),
            })
            .pipe(
                map((response) => ({
                    parcels: response.data.bookingParcels.map((parcel) => new ParcelModel(parcel)),
                    passengers: response.data.bookingPersons.map((booking) => new PassengerModel(booking)),
                })),
            );
    }
}
