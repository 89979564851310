export enum ROUTE_ERROR_TYPES {
    // Drivers
    NO_DRIVER,
    DRIVER_MULTIPLE_ROUTES_SAME_TAG,
    DRIVER_MULTIPLE_ROUTES_NOT_SAME_TAG,
    // Vehicles
    NO_VEHICLE,
    VEHICLE_MULTIPLE_ROUTES_SAME_TAG,
    VEHICLE_MULTIPLE_ROUTES_NOT_SAME_TAG,
    // Waypoints
    BOOKING_STILL_IN,
    BOOKING_NOT_OUT,
    WAYPOINT_TIME,
    WAYPOINT_TIME_EMPTY,
    MULTIPLE_STARTS,
    MULTIPLE_STOPS,
    // Trace
    TRACE_START,
    TRACE_STOP,
    TRACE_IN,
    TRACE_OUT,
    TRACE_LEFT_BEHIND,
}
