import { environment } from '@env/environment';
import { BOOKING_TYPES, WAYPOINT_TYPES } from '../../enums';
import { WaypointModel, WaypointNewModel, WaypointTransferModel } from '../../models';
import { RouteCalculationModel } from '../../route-calculation/models';
import { RouteDraft, Route } from '../models';
import { PassingWaypoints } from './passing-transfers.util';
import { PendingTransfersIn, PendingTransfersOut, PendingWaypoints } from './pending-transfers.util';

export class RoutesUtils {
    private routeId!: string | null;
    private _routes: (Route | RouteDraft)[];

    constructor(routes: (Route | RouteDraft)[]) {
        this._routes = [...routes];
    }

    /**
     * Get Current edited Route
     */
    get draft(): RouteDraft | Route {
        const routeIndex = this._routes.findIndex((current) => current.id === this.routeId);
        return this._routes[routeIndex];
    }

    /**
     * Get Routes
     */
    get routes() {
        return this._routes;
    }

    /**
     * Get Routes
     */
    get routesWithoutDraft() {
        return this._routes.filter((route): route is Route => route.id !== null);
    }

    /**
     * Get Waypoints
     */
    get waypoints() {
        return this.draft.waypoints;
    }

    setDraftRoute(route: RouteDraft | Route) {
        const routeIndex = this._routes.findIndex((current) => current.id === route?.id);
        if (routeIndex === -1) {
            this._routes.push({ ...route, waypoints: [...route.waypoints] });
        } else {
            this._routes.splice(routeIndex, 1, { ...route, waypoints: [...route.waypoints] });
        }
        this.routeId = route.id;
    }

    /**
     * Set Draft Waypoint Time
     */
    setWaypointTime(pointId: string, kind: WAYPOINT_TYPES, time: Date | null) {
        const index = this.waypointIndex(pointId, kind);
        const waypoint = { ...this.draft.waypoints[index], time };
        this.draft.waypoints.splice(index, 1, waypoint);
    }

    /**
     * Set Draft Waypoint Time Relative
     */
    setWaypointTimeRelative(pointId: string, kind: WAYPOINT_TYPES, direction: 'up' | 'down', calculation: RouteCalculationModel) {
        const durations = calculation.sections.map((section) => section.summary.baseDuration);
        const index = this.waypointIndex(pointId, kind);
        const waypoint = this.waypoints[index];
        if (waypoint.time !== null) {
            if (direction === 'up') {
                for (let i = index; i >= 0; i--) {
                    const diff = durations.slice(i, index).reduce((prev, next) => {
                        const diff = next === 0 ? prev : prev + next + environment.planning.transferTime;
                        return diff;
                        // return Math.ceil((Math.ceil(diff / 60) * 60) / 300) * 300;
                    }, 0);
                    const roundTo5Mins = Math.round(diff / 300) * 300;
                    const date = new Date(waypoint.time.getTime() - roundTo5Mins * 1000);
                    this.waypoints.splice(i, 1, { ...this.waypoints[i], time: date });
                }
            } else if (direction === 'down') {
                for (let i = index; i < this.waypoints.length; i++) {
                    const diff = durations.slice(index, i).reduce((prev, next) => {
                        const diff = next === 0 ? prev : prev + next + environment.planning.transferTime;
                        return diff;
                        // return Math.ceil((Math.ceil(diff / 60) * 60) / 300) * 300;
                    }, 0);
                    const roundTo5Mins = Math.round(diff / 300) * 300;
                    const date = new Date(waypoint.time.getTime() + roundTo5Mins * 1000);
                    this.waypoints.splice(i, 1, { ...this.waypoints[i], time: date });
                }
            }
        }
    }

    /**
     * Set Draft Waypoint Time Relative
     */
    setWaypointsLength(calculation: RouteCalculationModel) {
        const lengths = [0, ...calculation.sections.map((section) => section.summary.length)];
        this.waypoints.forEach((waypoint, index) => {
            this.waypoints.splice(index, 1, { ...waypoint, length: lengths[index] });
        });
    }

    /**
     * Add Waypoint
     */
    add(waypoint: WaypointNewModel) {
        // if draft/start, set it on beginning of the route
        if (this.draft.waypoints.length > 0 && (waypoint.type === WAYPOINT_TYPES.DRAFT || waypoint.type === WAYPOINT_TYPES.START)) {
            // replace or add new
            if (this.draft.waypoints[0].type === WAYPOINT_TYPES.DRAFT || this.draft.waypoints[0].type === WAYPOINT_TYPES.START) {
                this.draft.waypoints.splice(0, 1, waypoint);
            } else {
                this.draft.waypoints.unshift(waypoint);
            }
        } else {
            // otherwise add new waypoint at the end
            this.draft.waypoints.push(waypoint);
            this.setWaypointTransfer(waypoint);
        }
        this.setDraftSequences();
    }

    /**
     * Remove Waypoint
     */
    remove(index: number) {
        const waypoint = this.draft.waypoints.splice(index, 1)[0];
        this.removeWaypointTransferCleanup(waypoint);
        this.setDraftSequences();
    }

    /**
     * Move Waypoint
     */
    move(fromIndex: number, toIndex: number) {
        this.draft.waypoints.splice(toIndex, 0, this.draft.waypoints.splice(fromIndex, 1)[0]);
        this.setDraftSequences();
    }

    /**
     * Toggle Transfers In - All In or Noone In
     */
    toggleTransfersIn(pointId: string) {
        const waypointIndex = this.draft.waypoints.findIndex((value) => value.pointId === pointId);
        const waypoint = this.draft.waypoints[waypointIndex];
        const transfersIn = PendingTransfersIn(this.draft.waypoints, pointId);

        if (this.transfersEqual(waypoint.transfersIn || [], transfersIn)) {
            this.draft.waypoints.splice(waypointIndex, 1, {
                ...waypoint,
                transfersIn: [],
            });
        } else {
            this.draft.waypoints.splice(waypointIndex, 1, {
                ...waypoint,
                transfersIn,
            });
        }
    }

    /**
     * Toggle Transfers Out - All Out or Noone Out
     */
    toggleTransfersOut(pointId: string) {
        const waypointIndex = this.draft.waypoints.findIndex((value) => value.pointId === pointId);
        const waypoint = this.draft.waypoints[waypointIndex];
        const transfersOut = PendingTransfersOut(this.draft.waypoints, pointId);

        if (this.transfersEqual(waypoint.transfersOut || [], transfersOut)) {
            this.draft.waypoints.splice(waypointIndex, 1, {
                ...waypoint,
                transfersOut: [],
            });
        } else {
            this.draft.waypoints.splice(waypointIndex, 1, {
                ...waypoint,
                transfersOut,
            });
        }
    }

    /**
     * Set all booking sequences
     */
    setAllSequences() {
        this._routes = this._routes.map((route) => {
            let counter = 0;
            return {
                ...route,
                waypoints: route.waypoints.map((waypoint) => {
                    const sequence = waypoint.type === WAYPOINT_TYPES.PASSENGER_START || waypoint.type === WAYPOINT_TYPES.PASSENGER_STOP ? ++counter : null;
                    return {
                        ...waypoint,
                        sequence,
                    };
                }),
            };
        });
    }

    private setDraftSequences() {
        let counter = 0;
        this.draft.waypoints = this.draft.waypoints.map((waypoint) => {
            const sequence = waypoint.type === WAYPOINT_TYPES.PASSENGER_START || waypoint.type === WAYPOINT_TYPES.PASSENGER_STOP ? ++counter : null;
            return {
                ...waypoint,
                sequence,
            };
        });
    }

    private waypointIndex(waypoint: WaypointModel | WaypointNewModel): number;
    private waypointIndex(pointId: string, kind: WAYPOINT_TYPES): number;

    /**
     * Get Waypoint Index by Waypoitn
     */
    private waypointIndex(a: WaypointModel | WaypointNewModel | string, b?: WAYPOINT_TYPES) {
        if (typeof a === 'string') {
            return this.draft.waypoints.findIndex((current) => current.pointId === a && current.type === b);
        } else {
            return this.draft.waypoints.findIndex((current) => current.pointId === a.pointId && current.type === a.type);
        }
    }

    /**
     * Check if transfers are equall
     */
    private transfersEqual(transfers1: WaypointTransferModel[], transfers2: WaypointTransferModel[]) {
        const a = transfers1
            .map((transfer) => transfer.bookingId)
            .sort()
            .toString();
        const b = transfers2
            .map((transfer) => transfer.bookingId)
            .sort()
            .toString();
        return a === b;
    }

    /**
     * Set Waypoint Transfers when new Waypoint are added
     */
    private setWaypointTransfer(waypoint: WaypointModel | WaypointNewModel) {
        switch (waypoint.type) {
            case WAYPOINT_TYPES.PASSENGER_STOP:
            case WAYPOINT_TYPES.PARCEL_STOP:
                this.setWaypointTransfersBookingStop(waypoint);
                break;
            case WAYPOINT_TYPES.TRANSFER:
                this.setWaypointTransfers(waypoint);
                break;
        }
    }

    /**
     * On Waypoint Booking Stop added - update preceding Transfer Point transfersIn
     */
    private setWaypointTransfersBookingStop(waypoint: WaypointModel | WaypointNewModel) {
        const index = this.waypointIndex(waypoint);
        const waypointSliceTransfers = this.draft.waypoints.slice(0, index).filter((waypoint) => waypoint.type === WAYPOINT_TYPES.TRANSFER);
        let found = -1;
        while (waypointSliceTransfers.length > 0 && found === -1) {
            const transfer = waypointSliceTransfers.splice(0, 1)[0];
            const transferPendingWaypoints = PendingWaypoints(this.routes, this.routeId, transfer.pointId);
            found = transferPendingWaypoints.findIndex((current) => current.bookingId === waypoint.pointId);
            if (found !== -1) {
                const transfersIn = [...(transfer.transfersIn || [])];
                if (transfersIn.findIndex((transferIn) => transferIn.bookingId === waypoint.pointId) === -1) {
                    switch (waypoint.type) {
                        case WAYPOINT_TYPES.PASSENGER_STOP:
                            transfersIn.push({ bookingId: waypoint.pointId, bookingType: BOOKING_TYPES.PASSENGER });
                            break;
                        case WAYPOINT_TYPES.PARCEL_STOP:
                            transfersIn.push({ bookingId: waypoint.pointId, bookingType: BOOKING_TYPES.PARCEL });
                            break;
                    }
                    const transferIndex = this.draft.waypoints.findIndex((current) => current.pointId === transfer.pointId);
                    if (transferIndex !== -1) {
                        this.draft.waypoints.splice(transferIndex, 1, {
                            ...transfer,
                            transfersIn,
                        });
                    }
                }
                this.draft.waypoints;
            }
        }
    }

    /**
     * On Waypoint Transfer Added - update transfersOut with preceding Bookings
     */
    private setWaypointTransfers(waypoint: WaypointModel | WaypointNewModel) {
        const index = this.waypointIndex(waypoint);
        const passingWaypoints = PassingWaypoints(this.draft, waypoint.pointId);
        const transfersOut = [...(waypoint.transfersOut || [])];
        passingWaypoints.forEach((passingWaypoint) => {
            if (transfersOut.findIndex((transferOut) => transferOut.bookingId === passingWaypoint.bookingId) === -1) {
                transfersOut.push({ ...passingWaypoint });
            }
            this.draft.waypoints.splice(index, 1, {
                ...waypoint,
                transfersOut,
            });
        });
    }

    /**
     * On Remove Waypoint Booking START or STOP - cleanup transferIn/Out of related Transfers
     */
    private removeWaypointTransferCleanup(waypoint: WaypointModel | WaypointNewModel) {
        switch (waypoint.type) {
            case WAYPOINT_TYPES.PASSENGER_START:
            case WAYPOINT_TYPES.PARCEL_START:
                this.draft.waypoints.forEach((current, index) => {
                    if (current.type === WAYPOINT_TYPES.TRANSFER) {
                        if (current.transfersOut?.findIndex((transfer) => transfer.bookingId === waypoint.pointId) !== -1) {
                            const foundWaypoint = this.draft.waypoints[index];
                            const transfersOut = foundWaypoint.transfersOut?.filter((transfer) => transfer.bookingId !== waypoint.pointId);
                            this.draft.waypoints.splice(index, 1, {
                                ...foundWaypoint,
                                transfersOut,
                            });
                        }
                    }
                });
                break;
            case WAYPOINT_TYPES.PASSENGER_STOP:
            case WAYPOINT_TYPES.PARCEL_STOP:
                {
                    let waypointIndex: number | null = null;

                    this.draft.waypoints.forEach((current, index) => {
                        if (current.type === WAYPOINT_TYPES.TRANSFER) {
                            if (current.transfersIn?.findIndex((transfer) => transfer.bookingId === waypoint.pointId) !== -1) {
                                waypointIndex = index;
                            }
                            if (current.transfersOut?.findIndex((transfer) => transfer.bookingId === waypoint.pointId) !== -1) {
                                waypointIndex = null;
                            }
                        }
                    });

                    if (waypointIndex !== null) {
                        const foundWaypoint = this.draft.waypoints[waypointIndex];
                        const transfersIn = foundWaypoint.transfersIn?.filter((transfer) => transfer.bookingId !== waypoint.pointId);
                        this.draft.waypoints.splice(waypointIndex, 1, {
                            ...foundWaypoint,
                            transfersIn,
                        });
                    }
                }
                break;
        }
    }
}
